import AuthRequestHelper from "./AuthRequestHelper"
import AuthenticationHelper from './AuthenticationHelper'
import ChineseCalendarHelper from './ChineseCalendarHelper'

class PolicyHelper{



    /*get profiles */
    async getPolicy(){
        try{
            const requestUrl="policy"
            return await AuthRequestHelper.requestNoToken("get",requestUrl,{})     
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    async updatePolicy(userObj){
        try{
            const requestUrl="policy/"
            let newObj = {}

            if(userObj) newObj['policy_text'] = userObj
            //if(userObj.hasOwnProperty('email')) newObj['email'] = userObj.email
            //if(userObj.hasOwnProperty('dob')) newObj['dob'] = userObj.dob

            return await AuthRequestHelper.request("patch",requestUrl, newObj)
        }
        catch(error){
            console.log(error)
            return {}
        }
    }




}

export default new PolicyHelper();
import React, { useState, useEffect } from 'react'
import Accordion from '../../assests/Accordion'

const ProfileFolder = props => {
    // console.log(props)
/*
    const accordionArr = [
        {
            key: 'test1',
            title: '家人',
            items: [
                {
                    img: require('../../images/Gender/Female.svg'),
                    main: 'Lavender',
                    support: '1987年9月19日 上午10:30'
                },
                {
                    img: require('../../images/Gender/Male.svg'),
                    main: '爸爸',
                    support: '1987年9月19日 上午10:30'
                }
            ]
        },
        {
            key: 'test2',
            title: '同事',
            items: [
                {
                    img: require('../../images/Gender/Female.svg'),
                    main: 'Lavender',
                    support: '1987年9月19日 上午10:30',
                    handleClick: async () => {
                        //let chartResult = await ChartGeneratorHelper.getChartList(parseInt(date.lYear,10), parseInt(date.lMonth,10), parseInt(date.lDay, 10), 17, 27, 0, 23, 120,gender)
                        //setChartData(chartResult.data)
                    }
                },
                {
                    img: require('../../images/Gender/Male.svg'),
                    main: '爸爸',
                    support: '1987年9月19日 上午10:30'
                }
            ]
        },
    ]
*/
    // console.log(props.data)
    

    return <React.Fragment>
    <div className='ion-margin-horizontal ion-padding-vertical profile-folder-content'>
        <Accordion accordionArr={props.data} />
    </div>
    </React.Fragment>

}

export default ProfileFolder
import AuthRequestHelper from "./AuthRequestHelper"
import config from '../config/config'
import AuthenticationHelper from './AuthenticationHelper'

class AdminServicesHelper{

    //set the trial time in seconds
    async setTrialTime(id, time){
        try{
            const requestUrl="changesetting/"+id
            const queryparams={
                "keytype":"freeTrialTime",
                "value": time
            }           
            const SettingsParam= await AuthRequestHelper.request("put",requestUrl,queryparams)    
            return SettingsParam  
        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 

    //get the trial time in seconds
    async getTrialTime(id){
        try{
            const requestUrl="changesetting/"+id
            const queryparams={
                "keytype":"freeTrialTime"
            }           
            const SettingsParam= await AuthRequestHelper.request("post",requestUrl,queryparams)    
            return SettingsParam  
        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 


    //set the trial time in seconds
    async setMemberState(id, email,mem_type){
        try{
            const requestUrl="changememstate/"+id
            const queryparams={
                "email":email,
                "mem_type": mem_type
            }           
            const memStateResult= await AuthRequestHelper.request("put",requestUrl,queryparams)    
            return memStateResult  
        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 

    async uploadStudentFile(file){
        try{
            const requestUrl="uploadStudent/"
            const queryparams={
                "file":file
            }              
            console.error("In helper"   )
            console.error(file)
            var formData = new FormData();
            formData.append('file', file); // 設定上傳的檔案
            formData.append('id', 'aa01'); // 可以一起設定其他需要傳送的資料

            let requestOptions = {
                method: 'POST',
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Authorization": "Bearer "+AuthenticationHelper.token
                },
                body : formData
            }
            let url=config.localAuth.apiServiceUrl+requestUrl;

            let response_status=0
            return fetch(url,requestOptions)
            .then(r => {
                response_status=r.status
                return r.json()
            })
            .then(response => {
                if(response_status!==200)//error
            {
                if (response_status===401){
                    throw new Error ("Invalid Credentials")
                }

                }
                else
                {
                    return response
                }
        })

        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 

}


export default new AdminServicesHelper()

/*
import Textbook from '../json/Textbook.json'
import MainStar from '../json/MainStars.json'
import GH from '../helpers/GeneralHelper'
import { star } from 'ionicons/icons'
*/

import AuthRequestHelper from "./AuthRequestHelper"
import GH from '../helpers/GeneralHelper'
import MainStar from '../json/MainStars.json'
import SupStar from '../json/SupStars.json'


//SHOULD REPLACE WITH A REDUX DATASOURCE IF USING IT LATER ON 
class TextbookSet{
    static textBookJson=[] //an array with textbook objects
    static yearTextbookJson=[]

    static findtextBook(palaceStr, typeOfHouse, northStarStr, southStarStr,chartID) {
        // console.log(`Trying to get ${palaceStr},${typeOfHouse},${northStarStr},${southStarStr}`)
        // console.log(currentBook)
        let currentBook = TextbookSet.textBookJson.find(e => 
            (e.palaceStr === palaceStr && e.typeOfHouse === typeOfHouse  && e.northStarStr ===northStarStr && e.southStarStr=== southStarStr && e.chartID===chartID )  
        )
        if(currentBook === undefined){
            return false
        }
        else{
            // console.log("WE FOUND IN CACHE")
            // console.log(currentBook)
            return currentBook
        }
    }

    static findYearTextBook(palaceStr, typeOfHouse, northStarStr, southStarStr,chartID) {
        console.log("trying to find year textbook")
        // console.log(`Trying to get ${palaceStr},${typeOfHouse},${northStarStr},${southStarStr}`)
        // console.log(currentBook)
        let currentBook = TextbookSet.yearTextbookJson.find(e => 
            (e.palaceStr === palaceStr && e.typeOfHouse === typeOfHouse  && e.northStarStr ===northStarStr && e.southStarStr=== southStarStr && e.chartID===chartID )  
        )
        if(currentBook === undefined){
            return false
        }
        else{
            // console.log("WE FOUND IN CACHE")
            // console.log(currentBook)
            return currentBook
        }
    }

}

class TextbookHelper{

    //get the textblock block, if it already exists in cache, do not pull from server
    async getStarsByPalace(palaceStr = 'yin', typeOfHouse = 'life', northStarStr = '', southStarStr = '', chartID=null){
        // console.log(`check the palace ${palaceStr}`)
        //convert to tw star 
        const nKey = (northStarStr !== '' && northStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(northStarStr):''
        const sKey = (southStarStr !== '' && southStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(southStarStr):''
        
        if((nKey === '' || nKey === null) && (sKey === '' || sKey === null) )
        {
            return false
        }

        if((nKey !== '' && nKey !== null)){
            northStarStr= MainStar[nKey]['tw']
        }
        else{
            northStarStr = "null";
        }

        if((sKey !== '' && sKey !== null)){
            southStarStr= MainStar[sKey]['tw']
        }
        else{
            southStarStr = "null";
        }

        // console.log(TextbookSet.textBookJson)
        //check if block exists
        let textbook=TextbookSet.findtextBook(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID) 
        if(textbook)
        {
            return textbook.data
        }
        else{
            try
            {
                // console.log(`calling palace ${palaceStr}, using type of house ${typeOfHouse} with north ${northStarStr} and south ${southStarStr}`)
                let textBookElement = await this.getTextBookFromServer(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID)
                // console.log(textBookElement)
                if(textBookElement.toString().split(":")[2]=="NoSubMem")
                {
                  return "NoSubMem"
                }
  
                let storeJson={
                    palaceStr:palaceStr,
                    typeOfHouse:typeOfHouse,
                    northStarStr:northStarStr,
                    southStarStr:southStarStr,
                    chartID:chartID,
                    data: {...textBookElement}
                }
                //store in block 
                //console.log('you should not see me')
                if(textBookElement.star.toLowerCase()!="locked") //do not stored locked ones
                {
                    TextbookSet.textBookJson.push(storeJson)
                }
                return textBookElement
            }
            catch(err)
            {
                // console.log('error happened getting textbook')
                return err
            }

        }

    }

    //get the textblock block, if it already exists in cache, do not pull from server
    async getSupStarsByPalace(palaceStr = 'yin', typeOfHouse = 'life', northStarStr = '', southStarStr = '', chartID=null){
        // console.log(`check the palace ${palaceStr}`)
        //convert to tw star 
        const nKey = (northStarStr !== '' && northStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(northStarStr):''
        const sKey = (southStarStr !== '' && southStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(southStarStr):''
        
        if((nKey === '' || nKey === null) && (sKey === '' || sKey === null) )
        {
            return false
        }

        if((nKey !== '' && nKey !== null)){
            northStarStr= SupStar[nKey]['tw']
        }
        else{
            northStarStr = "null";
        }

        if((sKey !== '' && sKey !== null)){
            southStarStr= SupStar[sKey]['tw']
        }
        else{
            southStarStr = "null";
        }

        // console.log(TextbookSet.textBookJson)
        //check if block exists
        let textbook=TextbookSet.findtextBook(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID) 
        if(textbook)
        {
            return textbook.data
        }
        else{
            try
            {
                console.log(`calling palace ${palaceStr}, using type of house ${typeOfHouse} with north ${northStarStr} and south ${southStarStr}`)
                let textBookElement = await this.getTextBookFromServer(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID)
                console.log(textBookElement)
                if(textBookElement.toString().split(":")[2]=="NoSubMem")
                {
                  return "NoSubMem"
                }
  
                let storeJson={
                    palaceStr:palaceStr,
                    typeOfHouse:typeOfHouse,
                    northStarStr:northStarStr,
                    southStarStr:southStarStr,
                    chartID:chartID,
                    data: {...textBookElement}
                }
                //store in block 
                //console.log('you should not see me')
                if(textBookElement.star.toLowerCase()!="locked") //do not stored locked ones
                {
                    TextbookSet.textBookJson.push(storeJson)
                }
                return textBookElement
            }
            catch(err)
            {
                // console.log('error happened getting textbook')
                return err
            }

        }

    }

    //get a single block from the fortune chat server
    async getTextBookFromServer(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID=null){
        try{
            const requestUrl="textbook"
            const queryparams={
                "palaceStr": palaceStr,
                "northStarStr": northStarStr,
                "southStarStr": southStarStr,
                "typeOfHouse":typeOfHouse,
                "chartID":chartID
            }           
            const TextBookDataList= await AuthRequestHelper.request("post",requestUrl,queryparams)    
            return TextBookDataList["data"]    
        }
        catch(error){
            // console.log(error)
            return error
            //throw error
        }
    }

    //get the textblock block, if it already exists in cache, do not pull from server
    async getYearStarsByPalace(palaceStr = 'yin', typeOfHouse = 'life', northStarStr = '', southStarStr = '', chartID=null){
        console.log("getting year stars")
        // console.log(`check the palace ${palaceStr}`)
        //convert to tw star 
        const nKey = (northStarStr !== '' && northStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(northStarStr):''
        const sKey = (southStarStr !== '' && southStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(southStarStr):''
        
        if((nKey === '' || nKey === null) && (sKey === '' || sKey === null) )
        {
            return false
        }

        if((nKey !== '' && nKey !== null)){
            northStarStr= MainStar[nKey]['tw']
        }
        else{
            northStarStr = "null";
        }

        if((sKey !== '' && sKey !== null)){
            southStarStr= MainStar[sKey]['tw']
        }
        else{
            southStarStr = "null";
        }

        // console.log(TextbookSet.textBookJson)
        //check if block exists
        let textbook=TextbookSet.findYearTextBook(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID) 
        if(textbook)
        {
            return textbook.data
        }
        else{
            // console.log("ccan't find cached textbook")
            try
            {
                // console.log(`calling palace ${palaceStr}, using type of house ${typeOfHouse} with north ${northStarStr} and south ${southStarStr}`)
                let textBookElement = await this.getChatTextBookFromServer(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID)
                // console.log(textBookElement)
                if(textBookElement.toString().split(":")[2]=="NoSubMem")
                {
                  return "NoSubMem"
                }
  
                let storeJson={
                    palaceStr:palaceStr,
                    typeOfHouse:typeOfHouse,
                    northStarStr:northStarStr,
                    southStarStr:southStarStr,
                    chartID:chartID,
                    data: {...textBookElement}
                }
                //store in block 
                //console.log('you should not see me')
                if(textBookElement.star.toLowerCase()!="locked") //do not stored locked ones
                {
                    TextbookSet.textBookJson.push(storeJson)
                }
                return textBookElement
            }
            catch(err)
            {
                // console.log('error happened getting textbook')
                return err
            }

        }

    }


    //get the textblock block, if it already exists in cache, do not pull from server
    async getYearSupStarsByPalace(palaceStr = 'yin', typeOfHouse = 'life', northStarStr = '', southStarStr = '', chartID=null){
        // console.log(`check the palace ${palaceStr}`)
        //convert to tw star 
        const nKey = (northStarStr !== '' && northStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(northStarStr):''
        const sKey = (southStarStr !== '' && southStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(southStarStr):''
        
        if((nKey === '' || nKey === null) && (sKey === '' || sKey === null) )
        {
            return false
        }

        if((nKey !== '' && nKey !== null)){
            northStarStr= SupStar[nKey]['tw']
        }
        else{
            northStarStr = "null";
        }

        if((sKey !== '' && sKey !== null)){
            southStarStr= SupStar[sKey]['tw']
        }
        else{
            southStarStr = "null";
        }

        // console.log(TextbookSet.textBookJson)
        //check if block exists
        let textbook=TextbookSet.findYearTextBook(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID) 
        if(textbook)
        {
            return textbook.data
        }
        else{
            try
            {
                // console.log(`calling palace ${palaceStr}, using type of house ${typeOfHouse} with north ${northStarStr} and south ${southStarStr}`)
                let textBookElement = await this.getChatTextBookFromServer(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID)
                console.log(textBookElement)
                if(textBookElement.toString().split(":")[2]=="NoSubMem")
                {
                  return "NoSubMem"
                }
  
                let storeJson={
                    palaceStr:palaceStr,
                    typeOfHouse:typeOfHouse,
                    northStarStr:northStarStr,
                    southStarStr:southStarStr,
                    chartID:chartID,
                    data: {...textBookElement}
                }
                //store in block 
                //console.log('you should not see me')
                if(textBookElement.star.toLowerCase()!="locked") //do not stored locked ones
                {
                    TextbookSet.textBookJson.push(storeJson)
                }
                return textBookElement
            }
            catch(err)
            {
                // console.log('error happened getting textbook')
                return err
            }

        }

    }


    //get a single block from the fortune chat server
    async getChatTextBookFromServer(palaceStr,typeOfHouse,northStarStr,southStarStr,chartID=null){
        try{
            const requestUrl="chattextbook"
            const queryparams={
                "palaceStr": palaceStr,
                "northStarStr": northStarStr,
                "southStarStr": southStarStr,
                "typeOfHouse":typeOfHouse,
                "chartID":chartID
            }           
            const TextBookDataList= await AuthRequestHelper.request("post",requestUrl,queryparams)    
            return TextBookDataList["data"]    
        }
        catch(error){
            // console.log(error)
            return error
            //throw error
        }
    } 
}

/*
class TextbookHelper
{
    getStarsByPalace(palaceStr = 'yin', typeOfHouse = 'life', northStarStr = '', southStarStr = '')
    {
        const starArr = Textbook[palaceStr]
        const nKey = (northStarStr !== '' && northStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(northStarStr):''
        const sKey = (southStarStr !== '' && southStarStr !== null) ? GH.lowerFirstLetterAndCombineTheSpace(southStarStr):''


        if((northStarStr !== '' && northStarStr !== null) && (southStarStr === '' || southStarStr === null)) // only single north star
        {
            const ns = MainStar[nKey]['tw']
            let nMeaning = starArr.find(e => e.North === ns)

            if(nMeaning === undefined) // check south star
            {
                nMeaning = starArr.find(e => e.South === ns)
            }
            return {
                star: nMeaning[typeOfHouse],
                element: nMeaning['YinYangElement']
            }
            // console.log(nMeaning)
        }
        if((northStarStr !== '' && northStarStr !== null) && (southStarStr !== '' && southStarStr !== null))
        {
            // console.log(`north star is displayed and south star too`)
            const ns = MainStar[nKey]['tw']
            // const ss = MainStar[sKey]['tw']
            // console.log(`find north star${ns} south star ${ss} at ${typeOfHouse} and palace ${palaceStr}`)
            let nMeaning = starArr.find(e => e.North === ns)
            //console.log(`get palace ${palaceStr} for north ${ns} and south ${southStarStr} and typeofHouse ${typeOfHouse}`)
            // console.log(nMeaning)
            return {
                star: nMeaning[typeOfHouse],
                element: nMeaning['YinYangElement']
            }
            // console.log(sMeaning)
        }
        if((northStarStr === '' || northStarStr === null) && (southStarStr !== '' && southStarStr !== null) )
        {
            // console.log(`north star is not displayed but south star yes`)
            const ss = MainStar[sKey]['tw']

            let sMeaning = starArr.find(e => e.South === ss)
            if(sMeaning === undefined)
            {
                sMeaning = starArr.find(e => e.North === ss)
            }
            // console.log(sMeaning)
            //console.log(`get palace ${palaceStr} for north ${northStarStr} and south ${ss} and typeofHouse ${typeOfHouse}`)
            return {
                star: sMeaning[typeOfHouse],
                element: sMeaning['YinYangElement']
            }
        }
        return false
    }
}*/

export default new TextbookHelper()


class ServiceWorkerStatus{
    static hasUpdate=false
}


class ServiceWorkerHelper{

    getUpdateStatus(){
        return ServiceWorkerStatus.hasUpdate
    }
    
    setUpdateStatus(updateStatus){
        ServiceWorkerStatus.hasUpdate=updateStatus
    }
}

export default new ServiceWorkerHelper()

import React, { useEffect, useState, useRef } from 'react'
import { IonAlert, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg, IonButton, IonCol, IonRow, IonGrid, IonItem } from '@ionic/react'
import QRCode from 'qrcode.react'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import lang from '../../lang/init'
import WebPayPalButton from './WebPaypal'

const MemberCard = props => {
    // console.log(props)
    const [email, updateEmail] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    const dict = lang(props.preferences.lang)['subscribe']

    const getUser = async () => {
        
        const user = await UserProfileHelper.getProfileById(AuthenticationHelper.uuid)
        if(user.hasOwnProperty('data'))
        {
            return updateEmail(user.data.email)
        }
    }

    useEffect(() => {
        getUser()
    }, [])
    // console.log(props)
    const payButton= props.isApp?
    <IonButton expand='block' color='zprimary' onClick={() => setShowAlert(true)}>{dict['becomeMember']}</IonButton>
    :
    <WebPayPalButton onApprove={props.onApprove} createSubscription={props.createSubscription}/>

    let subscriptionBegin = ChineseCalendarHelper.formatDateWithoutTime(props.subscription)
    let subscriptionEnd = props.isVIP ? (props.isTrialVIP ? ChineseCalendarHelper.formatDateWithoutTime(props.endTrialDate):ChineseCalendarHelper.plusOneYear(props.subscription)) : ChineseCalendarHelper.plusOneYear()
    // console.log(subscriptionEnd)
    return (
        <IonCard className='member-card' style={{ margin:'auto', marginTop: '2em', marginBottom: '2em', width: '80%' }}>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={dict['comingSoon']}
                message={dict['bePatient']}
                buttons={[dict['ok']]}
            ></IonAlert>
            <IonImg className='membership-img' src={require('../../images/Membership.svg')} />

            <IonCardHeader>
            <IonCardSubtitle>{`${dict['period']}${subscriptionBegin} ${dict['to']} ${subscriptionEnd}`}</IonCardSubtitle>
            {props.isVIP && props.isTrialVIP ? <IonCardTitle style={{ fontSize: '1.4em' }}>{dict['trialSubscription']}</IonCardTitle>:null}
            {props.isVIP && !props.isTrialVIP ? <IonCardTitle style={{ fontSize: '1.4em' }}>{dict['yearSubscription']}</IonCardTitle>:null}
            {!props.isVIP && !props.isTrialVIP ? <IonCardTitle style={{ fontSize: '1.4em' }}>{dict['joinTheSubscription']}</IonCardTitle>:null}
            </IonCardHeader>

            <IonCardContent>
            <IonRow style={{ display: 'flex', justifyContent:'center' }}>
            <QRCode className='qr-code' value={email} />
            
            </IonRow>
            <p style={{ textAlign: 'center', margin: 0, marginBottom: '1em' }}><small>{email}</small></p>
            <p style={{ textAlign: 'center' }}>{/*`${dict['price']}600${dict['currency']}`*/}</p>
            {/*Object.keys(props.subscription).length > 0 ? null:payButton*/}
            {props.isVIP ? null:payButton}
            </IonCardContent>
        </IonCard>
    )
}

export default MemberCard
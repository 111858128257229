import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router"
import { isPlatform } from '@ionic/react';
import withPreloader from '../withPreloader'
import MemberCard from './MemberCard'
import InAppPayLayer from './InAppPayLayer'
import './Subscribe.css'
import LoginFirst from '../MenuPages/LoginFirst'
import PaymentHelper from "../../helpers/PaymentHelper"
import lang from '../../lang/init'
import moment from 'moment'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import AdminServicesHelper from '../../helpers/AdminServicesHelper'

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonAlert,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper';
import UserProfileHelper from '../../helpers/UserProfileHelper';

/**
 * To check your paypal subscription plan
 * https://www.paypal.com/billing/plans
 */

const Subscribe = props => {
  const dict = lang(props.preferences.lang)['subscribe']
  const [paid, updatePaid] = useState(false)
  const [history, updateHistory] = useState([])
  const [latestSub, updateLatestSub] = useState(new Date())
  const [isVIP, updateIsVIP] = useState(true) //assume true first to force initate the web paypal
  const [isTrialVIP, updateIsTrialVIP] = useState(true)
  const [endTrialDate, updateEndTrialDate]=useState('')

  //example to get payment
  useEffect(() => {
    const tryGetPayment = async () => {
      /** make sure user has logged in to access this section */
      if (AuthenticationHelper.uuid) {
        try {
          // check user's membership type, if it's 1 then user is certainly VIP (paid or complimented)
          let paymentRecords = await UserProfileHelper.getMembershipState(AuthenticationHelper.uuid)
          // console.log(paymentRecords)
          // let paymentRecords= await PaymentHelper.getPaypalPaymentRecord(AuthenticationHelper.uuid)
          // console.log(paymentRecords)
          //means user is a paid member
          if (paymentRecords?.data?.member_type === 1) {
            // console.log('user have a valid member date')
            /**
             * 
              let newArr = [...paymentRecords.data]
              // sort by the date
              newArr.sort((a,b) => moment(b.created_at) - moment(a.created_at))
             */
            const memberStartDate = paymentRecords?.data?.member_update_date

            //updateIsVIP(true)
            // console.log(newArr)
            // updateHistory(newArr)
            // updateLatestSub(newArr[0])
            updateLatestSub(memberStartDate)
            updateIsVIP(true)
            updateIsTrialVIP(false)
            return
          }
          else {
            // then check if user is in trial period and should be shown the member only data
            const trial = await AdminServicesHelper.getTrialTime(AuthenticationHelper.uuid)
            // console.log(trial)
            if(trial?.data?.value && parseInt(trial?.data?.value, 10) > 0)
            {
              const userProfile = await UserProfileHelper.getProfileById(AuthenticationHelper.uuid)
              // console.log(userProfile)
              if(userProfile?.data?.created_at)
              {
                const convertedPeriod = parseInt(trial?.data?.value, 10) / (24 * 60 * 60)
                // console.log(`converted period is ${convertedPeriod}`)
                const trialPeriod = ChineseCalendarHelper.plusDays(moment(userProfile.data.created_at), convertedPeriod)
                // console.log(trialPeriod)
                const trialExpired = moment(trialPeriod).isBefore(new moment())
                // console.log(`go back to pay ${trialExpired}`)
                if(trialExpired)
                {
                  updateIsTrialVIP(false)
                  updateIsVIP(false)
                  return
                }
                updateIsTrialVIP(true)
                updateIsVIP(true)
                updateEndTrialDate(trialPeriod)
                return
                // this.setState({
                //     promoEnabled: true,
                //     promotionPeriod: convertedPeriod
                // })
              }
            }
            else
            {
              // trial period is not active, no trial VIP or VIP
              updateIsTrialVIP(false)
              updateIsVIP(false)
              return
            }
          }
        }
        catch (e) {
          updateIsVIP(false)
          return
        }
      }
    }
    tryGetPayment();

  }, [props.location]);

  const retrievePayment = async () => {
    let paymentRecords = await PaymentHelper.getPaypalPaymentRecord(AuthenticationHelper.uuid)

    let sub = {}
    if (paymentRecords.hasOwnProperty('data') && paymentRecords.data.length > 0) {
      let newArr = [...paymentRecords.data]
      // sort by the date
      await newArr.sort((a, b) => moment(b.created_at) - moment(a.created_at))
      // console.log(newArr)
      await updateHistory(newArr)
      await updateLatestSub(newArr[0])
      sub = newArr[0]
    }
    return sub
  }
  let period = ''

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      'plan_id': process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID
    });
  };

  //TODO need to check if user has paid
  const onApprove = async (data, actions) => {
    if (!AuthenticationHelper.uuid) {
      // console.log("User not logged in")  
      return
    }
     console.log("Payment Approved: ", data);

    let registerPaymentResult = await PaymentHelper.addPaypalPaymentRecord(AuthenticationHelper.uuid, data.subscriptionID, data.orderID, data.billingToken, 1, 11)
    // console.log("registerpayment status: ", registerPaymentResult);
    const start = ChineseCalendarHelper.formatDateWithoutTime()
    const end = ChineseCalendarHelper.plusOneYear()
    period = dict['description'].replace('[begin]', start).replace('[end]', end)
    await updatePaid(true)
    await retrievePayment()
  };

  let renderObject = null
  if (isPlatform('mobileweb') || isPlatform('desktop') || isPlatform('pwa'))  //if pwa or mobile web
  {
    renderObject = <MemberCard {...props} isApp={false} onApprove={onApprove} isVIP={isVIP} isTrialVIP={isTrialVIP} createSubscription={createSubscription} subscription={latestSub} endTrialDate={endTrialDate} />
  } else {  //for native android and ios 
    renderObject = <MemberCard {...props} isApp={true} subscription={latestSub} isVIP={isVIP} />
  }
  // console.log(props)
  // user has not logged in
  if (!AuthenticationHelper.uuid) {
    renderObject = <div className="container"><LoginFirst /></div>
  }
  // there is a subscription data for this user. Display the user's subscription period in UI
  // console.log(latestSub)
  if (typeof latestSub === 'object' && Object.keys(latestSub).length > 0) {
    const start = ChineseCalendarHelper.formatDateWithoutTime(latestSub.created_at)
    const end = ChineseCalendarHelper.plusOneYear(moment(latestSub.created_at))
    const expired = ChineseCalendarHelper.checkMembershipExpiry(end)
    // console.log(`membership expired ${expired}`)
    period = dict['description'].replace('[begin]', start).replace('[end]', end)
    // console.log(period)
  }
  if (typeof latestSub === 'string') {
    const start = ChineseCalendarHelper.formatDateWithoutTime(latestSub)
    const end = ChineseCalendarHelper.plusOneYear(moment(latestSub))
    const expired = ChineseCalendarHelper.checkMembershipExpiry(end)
    // console.log(`membership expired ${expired}`)
    period = dict['description'].replace('[begin]', start).replace('[end]', end)
  }
  // console.log(`user has paid ${paid}`)
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {paid ? <IonAlert
          isOpen={paid}
          onDidDismiss={() => updatePaid(false)}
          cssClass='subscription-payment'
          header={dict['paid']}
          subHeader={dict['subtitle']}
          message={period}
          buttons={[dict['ok']]}
        />
          : null}
        {/*<MemberCard {...props} />*/}
        {renderObject}
      </IonContent>
    </IonPage>

  )
}

export default withRouter(withPreloader(Subscribe))
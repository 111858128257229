import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonLabel, IonImg, IonIcon, IonBackButton, IonSearchbar, IonThumbnail } from '@ionic/react'


const Header = props => {
    return (<IonHeader className="bitmap-texture">
    <IonToolbar>
      {props.leftComponent !== null && props.leftComponent !== undefined ? props.leftComponent:null}

      <IonTitle className='ion-font-title'>{props.title}</IonTitle>
      {props.rightComponent !== null && props.rightComponent !== undefined  ? props.rightComponent:null}

    </IonToolbar>
    {props.withSearchBar ? <IonToolbar>
        <IonSearchbar value={props.searchText} onIonChange={e => props.onSearch(e.detail.value)}></IonSearchbar>
    </IonToolbar>:null}
  </IonHeader>)
}

export default Header
import React, { useEffect } from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonMenuButton, IonRow, IonCol, IonImg, IonSearchbar } from '@ionic/react'

import lang from '../../lang/init'
import init from '../../config/init'

const MemberOnly = props => {

    const dict = lang(props.preferences.lang)['chat']

    return (<React.Fragment>
        
        <IonRow style={{ margin: 'auto', marginTop: '2em' }} >
        <IonCol>
        <IonTitle className='error-font'>{dict['oops']}</IonTitle>
        </IonCol>
        </IonRow>
        
        <IonRow>
        <IonCol>
        <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
        <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['payFirst']}</p>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
        <IonButton color="zprimary" expand="block" style={{ margin: 'auto', marginTop: '2em' }} onClick={() => props.history.push(`${init.router.subscribe.url}`)}>{dict['toPayment']}</IonButton>
        </IonCol>
        </IonRow>

    </React.Fragment>)
}

export default MemberOnly
import { person, settings, cardSharp, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons'

const init = {
    defaultLang: 'zh',
    defaultLatLng: [25.1054, 121.597],
    iap:
    {
        year: 'com.lavendershe.ziweiyearsubscription'
    },
    router: {
        landing: {
            url: '/'
        },
        preloader: {
            url: '/preloader'
        },
        profile: {
            url: '/'
        },
        login: {
            title: '免費註冊/登入會員',
            url: '/login',
            icon: person
        },
        register: {
            title: '免費註冊/登入會員',
            url: '/register',
            icon: person
        },
        pay: {
            title: '成為付費會員',
            url: '/',
            icon: cardSharp
        },
        chart: {
            chart: '命盤',
            url: '/chart',
            // icon: 
        },
        textbook: {
            title: '紫微攻略',
            url: '/textbook'
        },
        forgotPass:
        {
            title: '忘記密碼',
            url: '/forgotpass'
        },
        chat:
        {
            title: '紫微攻略小助手',
            url: '/chat'
        },
        subscribe:
        {
            title: '訂閱',
            url: '/subscribe'
        },
        reserve:
        {
            title: '預約算命服務',
            url:'/reserve-reading'
        },
        backupChart:
        {
            title: '命盤備份',
            url: '/backup-chart'
        },
        logout:
        {
            title: '登出',
            url: '/logout'
        },
        setting:
        {
            title: '會員專區',
            url: '/setting'
        },
        admin:
        {
            title: '行銷管理',
            url: '/admin'
        },
        policy:
        {
            title: '隱私權政策',
            url: '/policy'
        },
        policyupdate:
        {
            title: '隱私權政策更新',
            url: '/policyupdate'
        }
    },
    categories: [{
        seq: 1,
        text: '我',
        value: 'self'
    },{
        seq: 2,
        text: '家人',
        value: 'family'
    },{
        seq: 3,
        text: '朋友',
        value: 'friend'
    },{
        seq: 4,
        text: '客戶',
        value: 'client'
    },{
        seq: 5,
        text: '名人',
        value: 'celebrity'
    },{
        seq: 6,
        text: '其他',
        value: 'others'
    }],
    storageKey: {
        chartList: 'chartList',
        preferences: 'preferences',
        loginKey: 'xtoken',
        userAccDetails:'userAccDetails'
    },
    tokenRefreshTime: 5*24*60*60 //if it is more than 5 day, need to refresh app to log in
}

export default init

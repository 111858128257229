import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'
import Header from '../../assests/Header'
import { 
    IonContent,
    IonPage,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonDatetime,
    IonThumbnail,
    IonImg,
    IonCheckbox,
    IonSpinner,
    useIonViewDidEnter
} from '@ionic/react'
import lang from '../../lang/init'
import LoginFirst from './LoginFirst'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import ChartProfileHelper from '../../helpers/ChartProfileHelper'
import GeneralHelper from '../../helpers/GeneralHelper'
import DateTimePicker from '../../assests/DateTimePicker'
import moment from 'moment'
import init from '../../config/init'

const monthColumns = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
]


const Setting = props => {
    const [nickname, updateNickname] = useState('')
    const [dob, updateDob] = useState('')
    const [dobDisp, updateDobDisp] = useState('')
    const [password, updatePassword] = useState('')
    const [gender, updateGender] = useState(0)
    const [unmountDateTime, updateUnmountDateTime] = useState(false)
    const [isSolarTime, setSolarTime] = useState(false)
    const [invisible, updateInvisible] = useState(true)
    const [updated, refreshUpdated] = useState(false)
    const [updateFeedback, setUpdateFeedback] = useState('')
    const [resetPass, enableResetPass] = useState(false)
    const [selfChartId, updateSelfChartId] = useState('')
    const [remoteChartId, updateRemoteChartId] = useState('')
    const [loading, updateLoading] = useState(false)
    const [isLunarDate, updateIsLunarDate] = useState(false)
    const [isLeapMonth, updateIsLeapMonth] = useState(false)
    const [authed, updateAuthed] = useState(true)
    const [shouldInsertProfile, updateShouldInsertProfile] = useState(false)
    
    const dict = lang(props.preferences.lang)['setting']

    // let chartListData = []

    const retrieveProfile = async () => {
        // console.log("retrieve profile called")
        if(props.isLoggedIn)
        {
            console.log(`user has logged in`)
            try
            {
                const profile = await UserProfileHelper.getProfileById(AuthenticationHelper.uuid)
                let chartResults= await ChartProfileHelper.getOnlineChartListData(AuthenticationHelper.uuid)
                console.log("profile",profile)
                // console.log(chartResults)
                // if(Object.keys(profile).length <= 0 || Object.keys(chartResults) <= 0)

                if(Object.keys(profile).length <= 0)
                {
                    updateShouldInsertProfile(true)
                //     updateAuthed(false)
                //     return
                }

                // console.log(chartResults.length)
                let selfChart = null
                if(chartResults.length > 0)
                {   // find the self chart to fill the data
                    // console.log(`find local chart`)
                    selfChart = chartResults.find(e => e.category === init.categories[0]['value'])
                    // the self chart is found locally
                    if(selfChart !== undefined && Object.keys(selfChart).length > 0)
                    {
                        preFillSelfChartData(selfChart)
                    }
                    // else
                    // {
                    //     // find the self chart remotely
                    //     const remoteCharts = await ChartProfileHelper.getOnlineChartListData(AuthenticationHelper.uuid)
                    //     selfChart = remoteCharts.find(e => e.category === init.categories[0]['value'])
                    //     selfChart(selfChart)
                    // }
                    // console.log(selfChart)
                }
                // console.log('chart results are not found')
                // console.log(selfChart)
                // console.log(profile)
                if(profile.hasOwnProperty('data'))
                {
                    console.log("update profile data")
                    const d = profile['data']
                    updateNickname(d.display_name)
                    updateDob(d.dob)
                    const dobDisplay = moment(d.dob).local().format('YYYY-MM-DDTHH:mmZ')
                    // console.log(dobDisplay)
                    // updateDobDisp(dobDisplay)
                    updateDobDisp(`${isLunarDate ? '農曆':'國曆'}${moment(d.dob).format("YYYY年MM月DD日 HH:mm")}`)
                    // console.log('disable update')
                    // refreshUpdated(false)
                }
            }
            catch(e)
            {
                updateAuthed(false)
                console.log('error happened')
                console.log(e)
            }
        }
    }
    // if self chart is retrieved from local or remote
    const preFillSelfChartData = selfChart => {
        console.log(selfChart)
        if(selfChart !== undefined && Object.keys(selfChart).length > 0)
        {
            const targetChart = {...selfChart}
            setSolarTime(!targetChart.is_solar)
            updateGender(targetChart.gender === 'Male' ? 1:0) // 1 is Male, 0 is Female
            // console.log(`self chart id from retrieved profile ${targetChart.local_id}`)
            if(targetChart.hasOwnProperty('local_id') && targetChart.local_id !== null && targetChart.local_id !== undefined && targetChart.local_id !== '')
            {
                updateSelfChartId(targetChart.local_id)
            }
            if(targetChart.hasOwnProperty('id') && targetChart.id !== null && targetChart.id !== undefined && targetChart.id !== '')
            {
                updateRemoteChartId(selfChart.id)
            }
        }
        return
    }

    const removeLocalSelf = async () => {
        const chartData = await ChartProfileHelper.getLocalChartListData()
        if(chartData.length > 0)
        {
            await GeneralHelper.asyncForEach(chartData, async (d, idx) => {
                if(d.category === init.categories[0]['value'])
                {
                    const remove = await ChartProfileHelper.removeLocalChartListData(d.local_id)
                }
            })
        }
        return true
    }

    const updateSelfChart = async () => {
        let inputDate = dob !== '' ? ChineseCalendarHelper.formatDateDisplay(dob) : ChineseCalendarHelper.formatDateDisplay()
        // console.log(inputDate)
        if(isLunarDate)
        {
            // console.log('init ', inputDate)
            const y = moment(inputDate).year()
            const m = moment(inputDate).month()+1
            const d = moment(inputDate).date()
            const h = moment(inputDate).hour()
            const mm = moment(inputDate).minute()
            const solar = ChineseCalendarHelper.lunarToSolarConvert(y,m,d, isLeapMonth)
            // console.log(`input y ${y} m ${m} d ${d}`)
            const newSolarDate = new Date(solar.cYear, solar.cMonth-1, solar.cDay, h, mm)
            // console.log(newSolarDate)
            inputDate = ChineseCalendarHelper.formatDateDisplay(newSolarDate)
        }

        const toInsertData = {
            category: init.categories[0]['value'],
            dobString: inputDate,
            gender: gender === 1 ? 'Male':'Female',
            lat: init.defaultLatLng[0],
            lng: init.defaultLatLng[1],
            nickname: nickname,
            relationship: init.categories[0]['value'],
            isSolarTime: !isSolarTime
        }
        if(selfChartId === '' && remoteChartId === '') // generate a new chart data
        {
            // console.log('no chart id specified')
            const newSelf = await ChartProfileHelper.addLocalChartList(AuthenticationHelper.uuid, toInsertData.category, toInsertData.dobString, toInsertData.gender, toInsertData.lat, toInsertData.lng, toInsertData.nickname, toInsertData.relationship, toInsertData.isSolarTime)
            // console.log(newSelf)
            const newChartId = newSelf.find(e => e.category === init.categories[0]['value'])
            updateSelfChartId(newChartId.local_id)
            // const result = await ChartProfileHelper.pushChartListData(AuthenticationHelper.uuid)
            const result = await ChartProfileHelper.addOnlineChartListData(AuthenticationHelper.uuid, toInsertData.category, toInsertData.dobString, toInsertData.gender, toInsertData.lat, toInsertData.lng, toInsertData.nickname, toInsertData.relationship, toInsertData.isSolarTime)
            // console.log(result)
            return result
        }
        try
        {
            // console.log(`remove self`)
            const removeSelf = await removeLocalSelf()
            // console.log(removeSelf)
            // const allLocalCharts = await ChartProfileHelper.getLocalChartListData()
            // console.log(allLocalCharts)
            // return removeSelf
            const newSelf = await ChartProfileHelper.addLocalChartList(AuthenticationHelper.uuid, toInsertData.category, toInsertData.dobString, toInsertData.gender, toInsertData.lat, toInsertData.lng, toInsertData.nickname, toInsertData.relationship, toInsertData.isSolarTime)
            // console.log(newSelf)
            const newChartId = newSelf.find(e => e.category === init.categories[0]['value'])
            updateSelfChartId(newChartId.local_id)
            // console.log(`the new to be removed id is ${newChartId.local_id}`)
            // const sync = await ChartProfileHelper.pushChartListData(AuthenticationHelper.uuid)
            if(remoteChartId)
            {
                const update = await ChartProfileHelper.editOnlineChartListData(remoteChartId, toInsertData)
                // console.log(update)
                return update
            }
            else
            {
                const insert = await ChartProfileHelper.addOnlineChartListData(AuthenticationHelper.uuid, toInsertData.category, toInsertData.dobString, toInsertData.gender, toInsertData.lat, toInsertData.lng, toInsertData.nickname, toInsertData.relationship, toInsertData.isSolarTime)
                // console.log(insert)
                return insert
            }
        }
        catch(e)
        {
            // console.log(e)
            return false
        }
    }

    const submitUpdate = async () => {
        console.log("debug submit")
        updateLoading(true)
        let inputDate = dob !== '' ? ChineseCalendarHelper.formatDateDisplay(dob) : ChineseCalendarHelper.formatDateDisplay()
        // console.log(inputDate)
        if(isLunarDate)
        {
            // console.log('init ', inputDate)
            const y = moment(inputDate).year()
            const m = moment(inputDate).month()+1
            const d = moment(inputDate).date()
            const h = moment(inputDate).hour()
            const mm = moment(inputDate).minute()
            const solar = ChineseCalendarHelper.lunarToSolarConvert(y,m,d, isLeapMonth)
            // console.log(`input y ${y} m ${m} d ${d}`)
            const newSolarDate = new Date(solar.cYear, solar.cMonth-1, solar.cDay, h, mm)
            // console.log(newSolarDate)
            inputDate = ChineseCalendarHelper.formatDateDisplay(newSolarDate)
        }
        let updateProfile
        const auth = await UserProfileHelper.getAuthProfileById(AuthenticationHelper.uuid)

        if(shouldInsertProfile)
        {
            // console.log("submit new profile")
            updateProfile = await UserProfileHelper.registerUserProfile(AuthenticationHelper.uuid, nickname, auth.data.username,ChineseCalendarHelper.formatDateTimeForMySQL(inputDate))
            // console.log('no profile update', updateProfile)
            updateProfile = {
                status: 'success'
            }
        }
        else
        {
            updateProfile = await UserProfileHelper.updateProfileById(AuthenticationHelper.uuid, {
                displayName: nickname,
                dob: ChineseCalendarHelper.formatDateTimeForMySQL(inputDate)
            })
            // console.log('w profile update', updateProfile)
        }

        // console.log("update profile",updateProfile)
        const updateChart = await updateSelfChart()
        // in case of reset password
        if(resetPass)
        {
            if(password === undefined || password === '' || password === null)
            {
                setUpdateFeedback(dict['passwordEmpty'])
                updateLoading(false)
                return
            }
            // const updateChart = await updateSelfChart()
            // console.log(auth)
            if(auth.status === 'success')
            {
                const resetPass = await UserProfileHelper.resetPassword(auth.data.username, password)
                if(resetPass.status === 'success')
                {
                    setUpdateFeedback(dict['updateSuccess'])
                    refreshUpdated(false)
                    setTimeout(() => setUpdateFeedback(''), 3000)
                    updateLoading(false)
                    return
                }
            }
            setUpdateFeedback(dict['updateError'])
            setTimeout(() => setUpdateFeedback(''), 3000)
            updateLoading(false)
            return 
        }
        // console.log(updateProfile)
        if(updateProfile.status === 'success')
        {
            setUpdateFeedback(dict['updateSuccess'])
            refreshUpdated(false)
            setTimeout(() => setUpdateFeedback(''), 3000)
            updateLoading(false)
            return
        }
        setUpdateFeedback(dict['updateError'])
        setTimeout(() => setUpdateFeedback(''), 3000)
        updateLoading(false)
        return        
    }
    useIonViewDidEnter(() => {
        // console.log("ion view entered")
        retrieveProfile()
        if(props.location.pathname.includes(init.router.setting.url))
        {
            // console.log('move back to setting')
            updateUnmountDateTime(false)
        }
        if(!props.location.pathname.includes(init.router.setting.url))
        {
            updateIsLunarDate(false)
            // console.log('move another location')
            updateUnmountDateTime(true)
        }
    })

    // useEffect(() => {
    //     retrieveProfile()
    //     if(props.location.pathname.includes(init.router.setting.url))
    //     {
    //         // console.log('move back to setting')
    //         updateUnmountDateTime(false)
    //     }
    //     if(!props.location.pathname.includes(init.router.setting.url))
    //     {
    //         updateIsLunarDate(false)
    //         // console.log('move another location')
    //         updateUnmountDateTime(true)
    //     }
    // }, [props.location])
    // console.log(props)
    // console.log(`authed is ${authed}`)
    // console.log(`updated is ${updated}`)
    return(
        <IonPage>
            <Header title={dict['title']} 
                withSearchBar={false} 
                leftComponent={<IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>} 
            />
            <IonContent>   
                <div className="container">
                    {!props.isLoggedIn || !authed ?
                        <LoginFirst />
                        :
                        <React.Fragment>
                            <IonRow>
                            <IonCol>
                                <p>{dict['welcome']}</p>
                            </IonCol>
                            </IonRow>

                            <IonRow>
                            <IonCol>
                            <IonItem>
                                <IonLabel position="stacked">{dict['enterGender']}</IonLabel>

                                <div style={{ display: 'flex', padding: '40px 0px 20px 0px', justifyContent: 'space-evenly', width: '100%' }}>
                                    {gender === 0 ?
                                        <IonImg onClick={() => {
                                            updateGender(0)
                                            refreshUpdated(true)
                                        }} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Female-Icon.svg')} />
                                        :
                                        <IonImg onClick={() => {
                                            updateGender(0)
                                            refreshUpdated(true)
                                        }} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Female-Icon-Unselected.svg')} />
                                    }
                                    {gender === 1 ?
                                        <IonImg onClick={() => {
                                            updateGender(1)
                                            refreshUpdated(true)
                                        }} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Male-Icon.svg')} />
                                        :
                                        <IonImg onClick={() => {
                                            updateGender(1)
                                            refreshUpdated(true)
                                        }} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Male-Icon-Unselected.svg')} />
                                    }
                                </div>

                            </IonItem>
                            </IonCol>
                            </IonRow>

                            <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="floating">{dict['nickname']}</IonLabel>
                                    <IonInput required value={nickname} onIonChange={e => {
                                        updateNickname(e.detail.value)
                                        refreshUpdated(true)
                                    }} clearInput></IonInput>
                                </IonItem>
                            </IonCol>
                            </IonRow>

                            {/*<IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel id='birthday-label' position="floating">{dict['dob']}</IonLabel>
                                    <IonDatetime id='birthday-input' displayFormat="YYYY年MMM月DD日 HH:mm" monthShortNames={monthColumns} value={dobDisp} placeholder="例：1990年1月1日 10:00" onIonChange={e => {
                                        // console.log('updated')
                                        const val = ChineseCalendarHelper.formatDateDisplay(e.detail.value)
                                        updateDob(val)
                                        updateDobDisp(e.detail.value)
                                        refreshUpdated(true)
                                    }} clearInput></IonDatetime>
                                </IonItem>
                            </IonCol>
                            </IonRow>*/}

                            <IonRow>
                            <IonCol>

                            <IonItem>
                                <IonLabel position="floating" >{`${dict['dob']}${dict['dispSolar']}`}</IonLabel>
                                {<IonInput id='datepicker-wrapper' placeholder={dict['dobPlaceholder']} type="text" value={dobDisp} clearInput readonly></IonInput>}
                                {unmountDateTime ? null:<DateTimePicker isLunarDate={isLunarDate} updateIsLunarDate={updateIsLunarDate} updateBirthday={updateDob} updateBirthdayDisp={updateDobDisp} birthday={dob} updateIsLeapMonth={updateIsLeapMonth} isLeapMonth={isLeapMonth} />}
                            </IonItem>

                            </IonCol>
                            </IonRow>


                            {/*<IonRow>
                            <IonCol>
                            <IonItem>
                            <IonLabel id='birthday-label' position="floating">{dict['dob']}{isLunarDate ? dict['dispSolar']:null}</IonLabel>
                            <IonDatetime id='birthday-input' max="2100" displayFormat={isLunarDate ? "農曆YYYY年MMM月DD日 HH:mm":"國曆YYYY年MMM月DD日 HH:mm"} monthShortNames={monthColumns} value={dobDisp} placeholder="例：1990年1月1日 10:00" 
                                pickerOptions={{
                                    buttons: [
                                    {
                                        text: dict['enterSolar'],
                                        handler: (e) => {
                                            // console.log(e)
                                            const date = new Date(e.year.value, e.month.value - 1, e.day.value, e.hour.value, e.minute.value)
                                            // console.log(date)
                                            const val = ChineseCalendarHelper.formatDateDisplay(date)
                                            // console.log(val)

                                            updateDob(val)
                                            updateDobDisp(date)
                                            updateIsLunarDate(false)
                                        }
                                    }, {
                                        text: dict['enterLounar'],
                                        handler: (e) => {
                                            // console.log(e)
                                            const date = new Date(e.year.value, e.month.value - 1, e.day.value, e.hour.value, e.minute.value)
                                            const val = ChineseCalendarHelper.formatDateDisplay(date)
                                            // console.log(val)
                                            updateDob(val)
                                            updateDobDisp(date)
                                            updateIsLunarDate(true)
                                        }
                                    }, {
                                        text: dict['cancel'],
                                        role: 'cancel'
                                    }
                                    ]
                                }}
                                onIonChange={e => {
                                // const val = moment.utc(e.detail.value).format("YYYY-MM-DD_HH-mm-ss[Z]")
                                const val = ChineseCalendarHelper.formatDateDisplay(e.detail.value)
                                updateDob(val)
                                updateDobDisp(e.detail.value)
                            }} clearInput></IonDatetime>
                            
                            </IonItem>
                            </IonCol>
                            </IonRow>*/}

                            {/*<IonRow>
                            <IonCol>
                            <IonItem>
                                <IonLabel>{dict['useSolar']}</IonLabel>
                                <IonCheckbox color="zdark" checked={isSolarTime} onIonChange={e => {
                                    setSolarTime(e.detail.checked)
                                    refreshUpdated(true)
                                }} />
                            </IonItem>
                            </IonCol>
                            </IonRow>*/}


                            {resetPass ? 
                                <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel id='resetpass-label' position="floating">{dict['resetpass']}</IonLabel>
                                        <IonInput required type={invisible ? 'password' : 'text'} value={password} onIonChange={e => {
                                            updatePassword(e.detail.value)
                                            refreshUpdated(true)
                                        }} clearInput></IonInput>
                                        <IonThumbnail className='eye-icon' slot="end" onClick={() => updateInvisible(!invisible)} >
                                            <IonImg src={invisible ? require('../../images/Icon/Close-eye.svg') : require('../../images/Icon/Open-eye.svg')} />
                                        </IonThumbnail>
                                    </IonItem>
                                </IonCol>
                                </IonRow>
                                :
                                <IonRow onClick={() => {
                                    enableResetPass(true)
                                    refreshUpdated(true)
                                }}>
                                <IonCol>
                                    <IonItem>
                                        <p>{dict['clickResetPass']}</p>
                                    </IonItem>
                                </IonCol>
                                </IonRow>
                            }



                            {/*updated ? <IonRow>
                            <IonCol>
                            <IonButton color="zprimary" expand="block" onClick={submitUpdate}>{loading ? <IonSpinner name="lines-small" />:dict['confirm']}</IonButton>
                            </IonCol>
                            </IonRow>
                            :null*/}
                            <IonRow>
                            <IonCol>
                            <IonButton color="zprimary" expand="block" onClick={submitUpdate}>{loading ? <IonSpinner name="lines-small" />:dict['confirm']}</IonButton>
                            </IonCol>
                            </IonRow>

                            <IonRow>
                            <IonCol>
                                <p>{updateFeedback}</p>
                            </IonCol>
                            </IonRow>

                        </React.Fragment>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}

export default withRouter(withPreloader(Setting))
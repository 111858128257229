import React,{useState,useEffect}  from 'react'
import withPreloader from '../withPreloader'
import { withRouter } from "react-router"
import { 
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    IonThumbnail,
    IonImg,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonSpinner,
    IonTextarea
} from '@ionic/react'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import PolicyHelper from '../../helpers/PolicyHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import init from '../../config/init'
import lang from '../../lang/init'



const Login = props => {


    const [isLoggedIn, updateLoginState] = useState(AuthenticationHelper.hasAuthenticated())
    // const [isLoggedIn, updateLoginState] = useState(true)

    const policyt = lang(props.preferences.lang)['policy']
    const [storedUsername, updateStoredUsername] = useState('')

    useEffect(()=>{
        

                
        const tryLogin = async () =>{
            let loginResults= await AuthenticationHelper.login("test2","test2")
            // console.log(loginResults)
        };
        
        const tryRegister = async()=>{
            let registerResults= await UserProfileHelper.registerUser( {
                "username": "test6",
                "password": "test6", 
                "displayName": "test6",
                "email": "test6@email.com"
            })
            // console.log(registerResults)
        }

        //try to access username if it exists
        const getAccountDetails = async ()=>{
            // console.log("Checking for account details")
            let accFromLocalStorage = await AuthenticationHelper.getAccDetails();
            if (accFromLocalStorage)
            {
                updateStoredUsername(accFromLocalStorage.username);
            }
        }

        getAccountDetails();
        //tryLogin();
        //tryRegister();
        
    },[props.location]); //NOTE: YOU NEED TO PUT IN props.location else it will not run useeffect when the router changes, now useeffect will run when props.location changes 

    if(props.isLoggedIn && props.isAdmin) 
    return(
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <div className='logo-container'>
                <img className='square-logo' src={require('../../images/Square-logo.svg')} />
            </div>


                    <InputSection {...props} updateLoginState={updateLoginState} storedUsername={storedUsername} />
 


            </IonContent>
        </IonPage>
    )
    else
    return( 
        <IonPage>
        <IonHeader>
            <IonToolbar>
            <IonButtons slot="start">
                <IonMenuButton />
            </IonButtons>
            <IonTitle></IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <p>Only admin can access this page</p>
        </IonContent>
    </IonPage>
    )
}

const InputSection = props => {
    //const [username, updateUsername] = useState(props.storedUsername) // useState('')
    const [profileData, setProfileData] = useState('')
    const [updatePolicyfeed, updatePolicyFeedback] = useState('')
    const [policy, updatePolicy] = useState('')
    const dict = lang(props.preferences.lang)['auth']
    const getPolicyData = async () => {
        //get chart from local storage
        let chartResults= await PolicyHelper.getPolicy()
        console.log( chartResults)
        console.log( chartResults["data"]["policy_text"])
        setProfileData(chartResults["data"]["policy_text"])
        updatePolicy(chartResults["data"]["policy_text"])
        
    }    
    const submitPolicy = async () => {
        try
        {
            const policyResult = await PolicyHelper.updatePolicy(policy)
            //console.log(policyResult)
            if(policyResult.hasOwnProperty('status') && policyResult.status === 'success')
            {
                
                return updatePolicyFeedback("Update success")
            }
            
            throw Error(false)
        }
        catch(e)
        {
            // console.log(e)
            return updatePolicyFeedback('Update failed')
        }
        

    }


    useEffect(()=>{
        getPolicyData()
    },[props.location]); 


    return (<IonGrid>

        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel position="floating">{dict['policy']}</IonLabel>
                <IonTextarea required placeholder="Type something here" autoGrow="true" value={policy} onIonChange={e => updatePolicy(e.detail.value)}></IonTextarea>
            </IonItem>
        </IonCol>
        </IonRow>
        
        <IonRow>
        <IonCol>
        <IonButton color="zprimary" expand="block" type="submit" onClick={submitPolicy}>
            {dict['policyupdate']}</IonButton>
        </IonCol>
        </IonRow>
        
        <IonRow>
        <IonCol>
            <p>{updatePolicyfeed}</p>
        </IonCol>
        </IonRow>



    </IonGrid>)
}

export default withRouter(withPreloader(Login))
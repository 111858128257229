import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonTitle
} from '@ionic/react'
import withPreloader from './withPreloader'
import { withRouter, browserHistory } from 'react-router'
import React, {useRef} from 'react';
import { useLocation } from 'react-router-dom'
import { person, settings, cardSharp, logOut, mailOutline, save, construct } from 'ionicons/icons'
import * as Icons from '../assests/Icons'
import './Menu.css'
import init from '../config/init'
import lang from '../lang/init'
import AuthenticationHelper from '../helpers/AuthenticationHelper';
import config from '../config/config'

const appPages = [
  {
    title: 'login',
    url: init.router.login.url,
    icon: person
  },
  {
    title: 'subscribe',
    url: init.router.subscribe.url,
    icon: cardSharp
  },
  {
    title: 'reserve',
    url: init.router.reserve.url,
    icon: mailOutline
  },
  {
    title: 'backup',
    url: init.router.backupChart.url,
    icon: save
  },
  {
    title: 'setting',
    url: init.router.setting.url,
    icon: settings
  },
  {
    title: 'admin',
    url: init.router.admin.url,
    requireAdmin: true,
    icon: construct
    // iconType: 'custom',
    // icon: <Icons.Admin size={20} className='custom-icon' />
  },
  // {
  //   title: 'Archived',
  //   url: '/page/Archived',
  //   iosIcon: archiveOutline,
  //   mdIcon: archiveSharp
  // },
  // {
  //   title: 'Trash',
  //   url: '/page/Trash',
  //   iosIcon: trashOutline,
  //   mdIcon: trashSharp
  // },
  // {
  //   title: 'Spam',
  //   url: '/page/Spam',
  //   iosIcon: warningOutline,
  //   mdIcon: warningSharp
  // }
];

const Menu = props => {
  const location = useLocation();
  const dict = lang(props.preferences.lang)['menu']
  // console.log(props)

  let menuItems = []
  appPages.map((appPage, index) => {
    if(props.isLoggedIn && appPage.url === init.router.login.url) return null
    if(appPage?.requireAdmin && !props.isAdmin) return null
    menuItems.push(
      <IonMenuToggle key={index} autoHide={false}>
        <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
          {appPage?.iconType === 'custom' ?
          <div style={{ width: '3.5em' }}>
          {appPage.icon}
          </div>
          :
          <IonIcon slot="start" icon={appPage.icon} />
          }
          <IonLabel>{dict[appPage.title]}</IonLabel>
        </IonItem>
      </IonMenuToggle>
    )

  })

  
  if(props.isLoggedIn)
  {
    menuItems.push(
      <IonMenuToggle key={`logout-menu-item`} autoHide={false} onClick={() => {
        AuthenticationHelper.resetToken()
        AuthenticationHelper.resetStoredToken()//also remove localstorage token
        window.location.replace(init.router.landing.url)
      }}>
        <IonItem className={location.pathname === 'logout' ? 'selected' : ''} routerLink={`/`} routerDirection="none" lines="none" detail={false}>
        <IonIcon slot="start" icon={logOut} />
        <IonLabel>登出</IonLabel>
        </IonItem>
    </IonMenuToggle>)
      {/*<IonMenuToggle key={`logout-menu-item`} autoHide={false}>
      <IonItem className={''}  onClick={() => {
        AuthenticationHelper.resetToken()
        AuthenticationHelper.resetStoredToken()//also remove localstorage token
        window.location.replace(init.router.landing.url)
      }}>
        <IonIcon slot="start" icon={logOut} />
        <IonLabel>登出</IonLabel>
      </IonItem>
    </IonMenuToggle>*/}
    
  }
  return (
    <IonMenu contentId="main" type="overlay" >
      <IonHeader>
        <IonToolbar color="white">
          <IonTitle>{dict['title']}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList id="inbox-list">
          {/*<IonListHeader>{dict['title']}</IonListHeader>*/}
          {/*<IonNote></IonNote>*/}
          {/*appPages.map((appPage, index) => {
            if(props.isLoggedIn && appPage.url === init.router.login.url) return null
            if(appPage?.requireAdmin && !props.isAdmin) return null
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  {appPage?.iconType === 'custom' ?
                  <div style={{ width: '3.5em' }}>
                  {appPage.icon}
                  </div>
                  :
                  <IonIcon slot="start" icon={appPage.icon} />
                  }
                  <IonLabel>{dict[appPage.title]}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })*/}
          {menuItems}

        </IonList>
        <IonItem className={''} style={{ position: 'absolute', bottom: '2.4em', right: 0, left: 0 }}>
          <IonLabel class='version' style={{ textAlign: 'center' }}>{`${dict['version']}${config.version}`}</IonLabel>
        </IonItem>
        <IonItem className={''} style= {{ position: 'absolute', bottom: '0em', right: 0, left: 0 }}  >
          <IonLabel class="ion-text-wrap contact" style={{ textAlign: 'center' }} >{`${config.contact}`}</IonLabel>
        </IonItem>           
        <IonItem className={''} routerLink={`${init.router.policy.url}`} routerDirection="none" lines="none" detail={false} style= {{ font: 'small'}} >
          <IonLabel class="ion-text-wrap contact" style={{ textAlign: 'center' }} >{`${init.router.policy.title}`}</IonLabel>
        </IonItem>    

      </IonContent>
    </IonMenu>
  );
};

export default withRouter(withPreloader(Menu))

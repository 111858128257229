import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom';

const CLIENT = {
    sandbox_id: process.env.REACT_APP_PAYPAL_SANDBOX_KEY
};

let WebPayPalButtonObj= null; 

const WebPayPalButton= (props)=>{
    window.React = React;
    window.ReactDOM = ReactDOM;

    const [showButton, updateShowButton] = useState(false)

    useEffect(()=>{
      let paypalscript = document.createElement("script");
      paypalscript.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT.sandbox_id}&vault=true`;
      paypalscript.async = true;
      paypalscript.onload = () => onScriptLoad();
      document.body.appendChild(paypalscript);
    },[]);

   const onScriptLoad = () => {
      WebPayPalButtonObj = window.paypal.Buttons.driver("react", { React, ReactDOM });
      updateShowButton(true)
    }


    return (
      <div>
      {showButton && <WebPayPalButtonObj
          createSubscription={(data, actions) => props.createSubscription(data, actions)}
          onApprove={(data, actions) => props.onApprove(data, actions)}
      />}
  </div>
  )

}

export default WebPayPalButton;

import React from 'react'
import Picker from 'pickerjs'
import moment from 'moment'
import ChineseCalendarHelper from '../helpers/ChineseCalendarHelper'

// import './Picker.js'
import './Picker.css'
import './Asset.css'

class DateTimePicker extends React.Component
{
    constructor(props)
    {
        super(props)
        this.initPicker = this.initPicker.bind(this)
        this.attachBtns = this.attachBtns.bind(this)
        this.destroyPicker = this.destroyPicker.bind(this)
    }

    async componentDidMount()
    {
        // console.log('picker component mounted')
        try
        {
            await this.initPicker()
            await this.attachBtns()
        }
        catch(e)
        {
            console.log(e)
        }
    }

    async initPicker()
    {
        const self = this
        this.destroyPicker()
        // console.log(self.props.birthday)
        // const pickers = document.getElementsByClassName('picker')
        // for(let i = 0 ; i < pickers.length ; i++)
        // {
        //     pickers[i].remove()
        // }
        // console.log(this._input)
        this._picker = new Picker(this._input, {
            controls: true,
            format: 'YYYY年MM月DD日 HH:mm',
            headers: true,
            date: self.props.birthday !== '' && self.props.birthday !== null && self.props.birthday !== undefined ? new Date(self.props.birthday) : new Date(),
            text: {
                title: '',
                cancel: '取消',
                confirm: '確定',
                year: '年',
                month: '月',
                day: '日',
                hour: '時',
                minute: '分',
            },
            translate(type, text) {
                const suffixes = {
                  year: '',
                  month: '月',
                  day: '日',
                  hour: '時',
                  minute: '分',
                };
            
                return Number(text) + suffixes[type];
            },
            show()
            {
                if(self.props.birthday !== '' && self.props.birthday !== null && self.props.birthday !== undefined)
                {
                    const d = moment(self.props.birthday)
                    const newD = new Date(d.year(), d.month(), d.date(), d.hour(), d.minute())
                    self._picker.setDate(newD)
                    return
                }
                self._picker.setDate(new Date())
                return
                // console.log('ddddd')

            },
            // hidden()
            // {
            //     self._picker.destroy()
            // },
            pick() {
                // console.log(self._picker.getDate())
                const d = moment(self._picker.getDate())
                // console.log(d)
                if(self.props.isLunarDate)
                {
                    // console.log('use lunar date')
                    // Picker.setDefaults({
                    //     format: '農曆YYYY年MM月DD日 HH:mm'
                    // })
                    // self._picker.formatDate('農曆YYYY年MM月DD日 HH:mm')
                    const date = new Date(d.year(), d.month(), d.date(), d.hour(), d.minute())
                    // console.log(date)
                    const val = ChineseCalendarHelper.formatDateDisplay(date)
                    const newDisplayDate = `${self.props.isLunarDate ? '農曆':'國曆'}${moment(date).format("YYYY年MM月DD日 HH:mm")}`

                    self.props.updateBirthday(val)
                    self.props.updateBirthdayDisp(newDisplayDate)
                    // self.props.updateIsLunarDate(true)
                    return
                }
                // Picker.setDefaults({
                //     format: '國曆YYYY年MM月DD日 HH:mm'
                // })
                // self._picker.formatDate('國曆YYYY年MM月DD日 HH:mm')
                const date = new Date(d.year(), d.month(), d.date(), d.hour(), d.minute())
                const val = ChineseCalendarHelper.formatDateDisplay(date)
                const newDisplayDate = `${self.props.isLunarDate ? '農曆':'國曆'}${moment(date).format("YYYY年MM月DD日 HH:mm")}`
                self.props.updateBirthday(val)
                self.props.updateBirthdayDisp(newDisplayDate)
                // self.props.updateIsLunarDate(false)
                return
            }
        })
    }
    async attachBtns()
    {
        const self = this
        const pickerHeader = document.getElementsByClassName('picker-header')[0]
        // console.log(pickerHeader)
        // const pickerTitle = document.getElementsByClassName('picker-title')[0]
        // pickerTitle.remove()
        let solarBtn = document.createElement('Button')
        let lunarBtn = document.createElement('Button')
        // let leapMonth = document.createElement('Div')
        let leapLabel = document.createElement('Label')
        leapLabel.innerText = '閏月'
        leapLabel.for = 'leapMonth'
        leapLabel.classList.add('leapmonth-checkbox')
        let leapBtn = document.createElement('Input')
        leapBtn.setAttribute('type', 'checkbox')
        leapBtn.setAttribute('name', 'leapMonth')
        leapBtn.classList.add('leapmonth-checkbox-input')
        let leapSpan = document.createElement('Span')
        leapSpan.classList.add('checkmark')
        leapLabel.appendChild(leapBtn)
        leapLabel.appendChild(leapSpan)
        leapBtn.addEventListener('click', e => {
            self.props.updateIsLeapMonth(leapBtn.checked)
        })
        // leapMonth.appendChild(leapLabel)
        solarBtn.textContent= '國曆'
        solarBtn.classList.add('calendar-type-btn')
        solarBtn.classList.add('selected')
        solarBtn.addEventListener('click', e => {
            // console.log('clicked solar')
            solarBtn.classList.add('selected')
            lunarBtn.classList.remove('selected')
            this.props.updateIsLunarDate(false)
        })
        lunarBtn.textContent = '農曆'
        lunarBtn.classList.add('calendar-type-btn')
        lunarBtn.classList.add('lunar')
        lunarBtn.addEventListener('click', e => {
            // console.log('clicked lunar')
            solarBtn.classList.remove('selected')
            lunarBtn.classList.add('selected')
            this.props.updateIsLunarDate(true)
        })
        pickerHeader.appendChild(solarBtn)
        pickerHeader.appendChild(lunarBtn)
        pickerHeader.appendChild(leapLabel)
        let time = 0
        this._pickerInt = setInterval(() => {
            time += 1
            if(time <= 10)
            {
                // console.log(`check the time ${time}`)
                // console.log(self.props.birthday)
                if(self.props.birthday !== '' && self.props.birthday !== undefined && self.props.birthday !== null)
                {
                    const d = moment(self.props.birthday)
                    self._picker.setDate(new Date(self.props.birthday))
                    const date = new Date(d.year(), d.month(), d.date(), d.hour(), d.minute())
                    const val = ChineseCalendarHelper.formatDateDisplay(date)
                    const newDisplayDate = `${self.props.isLunarDate ? '農曆':'國曆'}${moment(date).format("YYYY年MM月DD日 HH:mm")}`
                    self.props.updateBirthday(val)
                    self.props.updateBirthdayDisp(newDisplayDate)
                    self.props.updateIsLunarDate(false)
                    return clearInterval(this._pickerInt)
                }
                return
            }
            return clearInterval(this._pickerInt)
        }, 500)
    }

    destroyPicker()
    {
        if(this._picker)
        {
            this._picker.destroy()
        }
        // const pickers = document.getElementsByClassName('picker')
        // for(let i = 0 ; i < pickers.length ; i++)
        // {
        //     pickers[i].remove()
        // }
    }

    componentWillUnmount()
    {
        this.destroyPicker()
        // console.log('picker goes to sleep')
        // const pickers = document.getElementsByClassName('picker')
        // for(let i = 0 ; i < pickers.length ; i++)
        // {
        //     pickers[i].remove()
        // }
        // if(this._picker)
        // {
        //     this._picker.destroy()
        // }
        // console.log(this._picker)
        // this._picker.destroy()
        // this._picker = null
    }

    render()
    {
        // console.log(this._picker)
        return <React.Fragment>
        <input ref={e => this._input = e} className='datetime-input-unit' type="button" id='picker-input' placeholder="" onClick={e => this._picker.show()} />
        </React.Fragment>
    }
}

export default DateTimePicker
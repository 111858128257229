import AuthRequestHelper from "./AuthRequestHelper"
import TextbookHelper from './TextbookHelper'
import SupportStarJSON from '../json/SupStars.json'
import LocalFortuneChat from "../json/LocalFortuneChat.json"
import palaces from '../json/Palaces.json'

//define a fortune default for the language and chatset
//SHOULD REPLACE WITH A REDUX DATASOURCE IF USING IT LATER ON 
class FortuneChatSet{
    static lang="tw"
    static chat_set="1"
    static chatjson={} //a dictionary which maps the index with the chat block, used as a cache
    static localChatjson=LocalFortuneChat //local copy of the chat, in case it is used offline
}

//define a fortune chat block object type
//currently not used (Just displayed to show what parameters are avaliable), but might integrate with FortuneChatSet later when used in redux
class FortuneChatBlock extends FortuneChatSet{
    constructor(uuid,block_index,block_type,block_data,block_next,block_opt_data){
        this.uuid= uuid;
        this.block_index=block_index;
        this.block_type=block_type;
        this.block_data=block_data;
        this.block_next=block_next;
        this.block_opt_data=block_opt_data;
        this.lang= super.lang;
        this.chat_set=super.chat_set;
    }
}


class FortuneChatHelper{

    //**************** //
    // public methods //
    //**************** //
    
    //get the chat block, if it already exists in cache, do not pull from server
    async getChatBlock(blockID,queryParams){
        //check if block exists
        if(FortuneChatSet.chatjson.hasOwnProperty(blockID))
        {
            return this.convertToLavenderChatDisplayFormat(FortuneChatSet.chatjson[blockID])
        }
        else{
            //online
            
            /*
            let chatElement = await this.getChatBlockFromServer(blockID,queryParams) 
            FortuneChatSet.chatjson[blockID]=chatElement
            return this.convertToLavenderChatDisplayFormat(FortuneChatSet.chatjson[blockID])
            */

           //offline 
           return FortuneChatSet.localChatjson[blockID]

        }

    }

    //get the local chat json from LocalFortuneChat.json
    getLocalChatJson(){
        // console.log(FortuneChatSet.localChatjson)
        return FortuneChatSet.localChatjson
    }

    //************************
    //      SCRIPTS
    //************************
    getOppositeHouse(data = [], idx = 0)
    {
        if(idx + 6 < 12)
        {
          const altIdx = idx + 6
          const newHouse = data[altIdx]
            return newHouse
        }
        else
        {
          const altIdx = idx + 6 - 12
          const newHouse = data[altIdx]
          return newHouse
        }
    }

    getYearOppositeHouse(data = [], idx = 0)
    {
        if(idx + 6 < 12)
        {
          const altIdx = idx + 6
          const newHouse = data[altIdx]
            return newHouse
        }
        else
        {
          const altIdx = idx + 6 - 12
          const newHouse = data[altIdx]
          return newHouse
        }
    }

    async getSupstarData (key = 'tuoLuo', palaceStr, chartID, supportStars) {
        // console.log("checkpoint")
        const supStr = SupportStarJSON[key]['en']
        // console.log("get sup star called")
        try
        {
          let supH = await TextbookHelper.getSupStarsByPalace(supportStars[key].zodiac_sign.toLowerCase(), palaceStr.toLowerCase(), supStr, '',chartID)
  
          if(supH['element'] === "null" || supH['star'] === "null") return false
          supH['palace'] = palaceStr.toLowerCase()
          supH['zodiac'] = supportStars[key].zodiac_sign.toLowerCase()
          supH['emptyPalace'] = false
          // palacesArr[idx] = h
          return supH
        }
        catch(e)
        {
          return false
          console.log(e)
        }
    }

    getYearMainStar()
    {

    }

    concatSupStar(palacesArr, data) {
        // console.log(data)
        if(palacesArr.hasOwnProperty('emptyPalace') && !palacesArr['emptyPalace'])
        {
        //   console.log("here")
          palacesArr['element'] = `${palacesArr['element']}，${data['element']}`
          palacesArr['star'] = `${palacesArr['star']}\\n${data['element']}\\n${data['star']}`
          return palacesArr
        }
        // console.log("not here")
        palacesArr = data
        // console.log(palacesArr)
        palacesArr['star'] = `${data['element']}\\n${data['star']}\\n`
        return palacesArr
    }

    async compileText(blockData, supportStars, palacePos, chartID)
    {
        let targetIdx = 0
        let pData = {}
        blockData.map((d, idx) => {
            if(d.palace_str.toLowerCase() === palaces[palacePos]['id'].toLowerCase())
            {
                pData = d
                targetIdx = idx
            }
        })
        // let pData = blockData.find(d => d.palace_str.toLowerCase() === palaces[palacePos]['id'].toLowerCase())

        if(pData.north_star_str === null && pData.south_star_str === null)
        {
            let idx = targetIdx, gotSupportStar=false, sText = {}
            try
            {
                console.log("should get the star at position ", targetIdx, supportStars)
                if(supportStars.hasOwnProperty('wenChang') && supportStars['wenChang']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getSupstarData('wenChang', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
                if(supportStars.hasOwnProperty('wenQu') && supportStars['wenQu']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getSupstarData('wenQu', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
              if(supportStars.hasOwnProperty('luCun') && supportStars['luCun']['position'] === idx)
              {
                gotSupportStar=true
                const tlData = await this.getSupstarData('luCun', pData.palace_str, chartID, supportStars)
                if(tlData) sText = this.concatSupStar(sText, tlData)
                // console.log(tlData)
                // if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
              }
              if(supportStars.hasOwnProperty('qingYang') && supportStars['qingYang']['position'] === idx)
              {
                gotSupportStar=true
                const tlData = await this.getSupstarData('qingYang', pData.palace_str, chartID, supportStars)
                if(tlData) sText = this.concatSupStar(sText, tlData)
              }
              if(supportStars.hasOwnProperty('tuoLuo') && supportStars['tuoLuo']['position'] === idx)
              {
                //   console.log("get tuoluo")
                gotSupportStar=true
                const tlData = await this.getSupstarData('tuoLuo', pData.palace_str, chartID, supportStars)
                // console.log(tlData)
                if(tlData) sText = this.concatSupStar(sText, tlData)
                // console.log(sText)
                // if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
              }
              if(supportStars.hasOwnProperty('huoXing') && supportStars['huoXing']['position'] === idx)
              {
                gotSupportStar=true
                const tlData = await this.getSupstarData('huoXing', pData.palace_str, chartID, supportStars)
                if(tlData) sText = this.concatSupStar(sText, tlData)
              }
              if(supportStars.hasOwnProperty('lingXing') && supportStars['lingXing']['position'] === idx)
              {
                gotSupportStar=true
                const tlData = await this.getSupstarData('lingXing', pData.palace_str, chartID, supportStars)
                if(tlData) sText = this.concatSupStar(sText, tlData)
              }
            }
            catch(e)
            {
              console.log(e)
            }
            if(gotSupportStar)
            {
              return sText
            }

            // borrow the opposite house
            pData = await this.getOppositeHouse(blockData, palacePos+1)
            let pText =  await TextbookHelper.getStarsByPalace(pData.zodiac_str.toLowerCase(), pData.palace_str.toLowerCase(), pData.north_star_str, pData.south_star_str, chartID)
            pText['empty'] = true
            console.log(pText)
            return pText
        }
        let pText =  await TextbookHelper.getStarsByPalace(pData.zodiac_str.toLowerCase(), pData.palace_str.toLowerCase(), pData.north_star_str, pData.south_star_str, chartID)
        return pText
    }

    async compileYearText(blockData, supportStars, palacePos, chartID)
    {
        console.log("compile year text")
        let targetIdx = 0
        let pData = {}
        blockData.map((d, idx) => {
            if(d.palace_str.toLowerCase() === palaces[palacePos]['id'].toLowerCase())
            {
                pData = d
                targetIdx = idx
            }
        })
        // let pData = blockData.find(d => d.palace_str.toLowerCase() === palaces[palacePos]['id'].toLowerCase())

        if(pData.north_star_str === null && pData.south_star_str === null)
        {
            console.log("check the sup stars")
            let idx = targetIdx, gotSupportStar=false, sText = {}
            try
            {
                // console.log("should get the star at position ", targetIdx, supportStars)
                if(supportStars.hasOwnProperty('wenChang') && supportStars['wenChang']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('wenChang', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
                if(supportStars.hasOwnProperty('wenQu') && supportStars['wenQu']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('wenQu', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
                if(supportStars.hasOwnProperty('luCun') && supportStars['luCun']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('luCun', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                    // console.log(tlData)
                    // if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                }
                if(supportStars.hasOwnProperty('qingYang') && supportStars['qingYang']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('qingYang', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
                if(supportStars.hasOwnProperty('tuoLuo') && supportStars['tuoLuo']['position'] === idx)
                {
                    //   console.log("get tuoluo")
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('tuoLuo', pData.palace_str, chartID, supportStars)
                    // console.log(tlData)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                    // console.log(sText)
                    // if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                }
                if(supportStars.hasOwnProperty('huoXing') && supportStars['huoXing']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('huoXing', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
                if(supportStars.hasOwnProperty('lingXing') && supportStars['lingXing']['position'] === idx)
                {
                    gotSupportStar=true
                    const tlData = await this.getYearSupStarsByPalace('lingXing', pData.palace_str, chartID, supportStars)
                    if(tlData) sText = this.concatSupStar(sText, tlData)
                }
            }
            catch(e)
            {
              console.log(e)
            }
            if(gotSupportStar)
            {
              return sText
            }

            // console.log("I am gonna check later")

            // borrow the opposite house
            pData = await this.getYearOppositeHouse(blockData, palacePos+1)
            // console.log("year p data", pData)
            let pText =  await TextbookHelper.getYearStarsByPalace(pData.zodiac_str.toLowerCase(), pData.palace_str.toLowerCase(), pData.north_star_str, pData.south_star_str, chartID)
            // console.log("year p text",pText)
            pText['empty'] = true
            console.log(pText)
            return pText
        }
        console.log("Left")
        let pText =  await TextbookHelper.getYearStarsByPalace(pData.zodiac_str.toLowerCase(), pData.palace_str.toLowerCase(), pData.north_star_str, pData.south_star_str, chartID)
        return pText
    }

    async executeScripts(req = '', chartData = {}, chartID = '')
    {
        if(req === '' || chartID === '' || Object.keys(chartData).length <= 0) return false
        // console.log(blockData)
        let blockData = chartData['blockData']
        let supData = chartData['supportStarData']
        // get the textbook data using user data
        // let h =  await TextbookHelper.getStarsByPalace(house.zodiac_str.toLowerCase(), house.palace_str.toLowerCase(), house.north_star_str, house.south_star_str, chartID)
        switch(req)
        {
            case 'NATAL_LIFE':
                let lifeText =  await this.compileText(blockData, supData, 0, chartID)
                return lifeText
            case 'NATAL_COUPLE':
                // let coupleData = blockData.find(d => d.palace_str.toLowerCase() === palaces[10]['id'].toLowerCase())
                let coupleText =  await this.compileText(blockData, supData, 10, chartID)
                return coupleText
            case 'NATAL_CAREER':
                // let careerData = blockData.find(d => d.palace_str.toLowerCase() === palaces[4]['id'].toLowerCase())
                let careerText =  await this.compileText(blockData, supData, 4, chartID)
                return careerText
            case 'NATAL_WEALTH':
                // let wealthData = blockData.find(d => d.palace_str.toLowerCase() === palaces[8]['id'].toLowerCase())
                let wealthText =  await this.compileText(blockData, supData, 8, chartID)
                return wealthText
            case 'NATAL_TRAVEL':
                // let travelData = blockData.find(d => d.palace_str.toLowerCase() === palaces[6]['id'].toLowerCase())
                let travelText =  await await this.compileText(blockData, supData, 6, chartID)
                return travelText
            case 'NATAL_PARENT':
                let parentText =  await this.compileText(blockData, supData, 1, chartID)
                return parentText
            case 'NATAL_MENTAL':
                let mentalText =  await this.compileText(blockData, supData, 2, chartID)
                return mentalText
            case 'NATAL_PROPERTY':
                let propertyText =  await this.compileText(blockData, supData, 3, chartID)
                return propertyText
            case 'NATAL_FRIENDS':
                let friendsText =  await this.compileText(blockData, supData, 5, chartID)
                return friendsText
            case 'NATAL_HEALTH':
                let healthText =  await this.compileText(blockData, supData, 7, chartID)
                return healthText
            case 'NATAL_CHILDREN':
                let childrenText =  await this.compileText(blockData, supData, 9, chartID)
                return childrenText
            case 'NATAL_SIBLINGS':
                let siblingsText =  await this.compileText(blockData, supData, 11, chartID)
                return siblingsText
            case 'YEAR_LIFE':
                // console.log("getting the year life")
                let yearLifeText =  await this.compileYearText(blockData, supData, 0, chartID)
                return yearLifeText
            case 'YEAR_CAREER':
                // console.log("getting the year life")
                let yearCareerText =  await this.compileYearText(blockData, supData, 4, chartID)
                return yearCareerText
            case 'YEAR_WEALTH':
                // console.log("getting the year life")
                let yearWealthHouse =  await this.compileYearText(blockData, supData, 8, chartID)
                return yearWealthHouse
            case 'YEAR_COUPLE':
                // console.log("getting the year life")
                let yearCoupleHouseText =  await this.compileYearText(blockData, supData, 10, chartID)
                return yearCoupleHouseText
            default: 
                // let defaultText = 
                return ''
        }
    }

    //**************** //
    // private methods //
    //**************** //

    //get a single block from the fortune chat server
    async getChatBlockFromServer(blockID,queryParams){
        try{
            const requestUrl="chat/"+blockID
            const queryparams={
                "chat_set": FortuneChatSet.chat_set,
                "lang":FortuneChatSet.lang,
                "query_params":queryParams
            }           
            const ChartListDataList= await AuthRequestHelper.request("post",requestUrl,queryparams)    
            return ChartListDataList["data"]    
        }
        catch(error){
            // console.log(error)
            return {}
        }
    } 
    //For some reason, Lavender's front-end display format uses the following format
    
    /* FOR NORMAL DISPLAY BLOCK
    {
        tw: '歡迎來到自動解盤機器人，我是大耕二號，有問必答。',
        next: '2'
    },
    */

    /* FOR CHOICE DISPLAY BLOCK
   {
        tw: '請問您想了解什麼呢？',
        options: [
        {
            tw: '我的今年運勢',
            next: '3'
        },
        {
            tw: '我的本命盤',
            next: '4'
        },
        ]
    }*/

    //Convert data to display format for frontend
    convertToLavenderChatDisplayFormat(inputJSONBlock){

        let returnJSON={}
        switch(inputJSONBlock.block_type.toLowerCase()) {
            case "statement":
              returnJSON["tw"]=inputJSONBlock.block_data
              returnJSON["next"]=inputJSONBlock.block_next
              break;
            case "branch":
              returnJSON["tw"]=inputJSONBlock.block_data
              //create the options 
              let optionsBlockList=[]
              inputJSONBlock.block_opt_data.forEach(choiceBlock => {
                let optionsBlock={}
                optionsBlock["tw"]=choiceBlock["block_data"]
                optionsBlock["next"]=choiceBlock["block_next"]
                optionsBlockList.push(optionsBlock)
              });
              returnJSON["options"]=optionsBlockList
              break;
            default: //default as statement
                returnJSON["tw"]=inputJSONBlock.block_data
                returnJSON["next"]=inputJSONBlock.block_next           
          } 
        return returnJSON

    }

}

export default new FortuneChatHelper();
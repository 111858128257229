import React,{useState,useEffect}  from 'react'
import withPreloader from '../withPreloader'
import { withRouter } from "react-router"
import { 
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    IonThumbnail,
    IonImg,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonSpinner
} from '@ionic/react'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import PolicyHelper from '../../helpers/PolicyHelper'
import init from '../../config/init'
import lang from '../../lang/init'



const Policy = props => {
    const [profileData, setProfileData] = useState('')
    const getPolicyData = async () => {
        //get chart from local storage
        let chartResults= await PolicyHelper.getPolicy()
        console.log( chartResults)
        console.log( chartResults["data"]["policy_text"])
        setProfileData(chartResults["data"]["policy_text"])

    }
    useEffect(()=>{
        getPolicyData()
    },[props.location]); 

    
    return(
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <div className='logo-container'>
                <div dangerouslySetInnerHTML={{__html: profileData}}></div>
            </div>



            </IonContent>
        </IonPage>
    )
}


export default withRouter(withPreloader(Policy))


import { getPlatforms } from '@ionic/react'
// console.log(getPlatforms())
class ScreenHelper
{
    detectOrientation()
    {
        try
        {
            if(getPlatforms().includes('desktop'))
            {
                if(window.screen.availHeight > window.screen.availWidth){
                    // alert("Please use Landscape!");
                    return 'portrait'
                }
                return 'landscape'
            }
            else if(getPlatforms().includes('mobile'))
            {
                if(window.screen.availHeight > window.screen.availWidth){
                    // alert("Please use Landscape!");
                    return 'portrait'
                }
                return 'landscape'
            }
        }
        catch(e)
        {
            return 'unknown'
        }
    }
    
    isPortrait()
    {
        return this.detectOrientation() === 'portrait' || this.detectOrientation() === 'unknown'
    }
    
    isLandscape()
    {
        return this.detectOrientation() === 'landscape' || this.detectOrientation() === 'unknown'
    }

    detectScreenRotate(cb)
    {
        try
        {
            if(getPlatforms().includes('desktop'))
            {
                // Detect whether device supports orientationchange event, otherwise fall back to
                // the resize event.
                var supportsOrientationChange = "onorientationchange" in window,
                orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";

                return window.addEventListener(orientationEvent, cb, false);
            }
            else if(getPlatforms().includes('mobile'))
            {
                // Detect whether device supports orientationchange event, otherwise fall back to
                // the resize event.
                var supportsOrientationChange = "onorientationchange" in window,
                orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";

                return window.addEventListener(orientationEvent, cb, false);
            }
        }
        catch(e)
        {
            return false
        }

    }

    blockRotatePlatforms()
    {
        if(getPlatforms().includes('mobile') || getPlatforms().includes('mobileweb')) return true
        return false
    }
}

export default new ScreenHelper()
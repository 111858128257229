const en = {
    index: {
        doneUpdated: 'Update completed. Restarting your app',
        updateInstalled: 'New version is installed',
        ok: 'Confirm',
        installingUpdate: 'A New Update is found and is being installed ',
        restartApp: 'A new Update has been installed, Please Restart The App'
    }
}

export default en
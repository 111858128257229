import AuthRequestHelper from "./AuthRequestHelper"
import SuiJianStars from '../json/SuiJianTwelve.json'
import JiangXingStars from '../json/JiangXingTwelve.json'

class ChartGeneratorHelper{
    /*Get the chart*/
    async getChartList(year,month,day,hour,min,sec,lat,lng,gender,isSolarTime=false, monthDZ){
        try{
            const requestUrl="fortune"
            const chartParams={
                "dob_year": year,
                "dob_month": month,
                "dob_day": day,
                "dob_hour": hour,
                "dob_min": min,
                "dob_sec":sec,
                "lat": lat,
                "lng": lng,
                "gender": gender,
                "is_solar_time":isSolarTime,
                "month_dz": monthDZ
            }
            return await AuthRequestHelper.request("post",requestUrl,  chartParams)
        }
        catch(error){
            console.log(error)
            return ""
        }
    }
    /*Get the chart*/
    async getChartListFromTimeZone(year,month,day,hour,min,sec,timezone_str,gender,isSolarTime=false, monthDZ){
        try{
            const requestUrl="fortune"
            const chartParams={
                "dob_year": year,
                "dob_month": month,
                "dob_day": day,
                "dob_hour": hour,
                "dob_min": min,
                "dob_sec":sec,
                "timezone_str": timezone_str,
                "gender":gender,
                "is_solar_time":isSolarTime,
                "month_dz": monthDZ
            }
            // console.log(chartParams)
            return await AuthRequestHelper.request("post",requestUrl,  chartParams)
        }
        catch(error){
            console.log(error)
            return ""
        }

    }

    getLifeHousePosition(blockData = [])
    {
        let pos = 0
        blockData.forEach((d, idx) => {
            if(d.palace_str === 'Life')
            {
                pos = idx
            }
        })
        return pos
    }

    getSuiJianArray(lifeHousePos)
    {
        let max = SuiJianStars.length
        let newArr = []
        for(let i = 0; i < max; i++)
        {
            let newPos = i + lifeHousePos < max ? i+lifeHousePos : i+lifeHousePos-max
            newArr[newPos] = SuiJianStars[i]
        }
        return newArr
    }

    getJiangXingArr(lifeHousePos)
    {
        let max = JiangXingStars.length
        let newOffset = JiangXingStars[0]["position"][lifeHousePos]
        // console.log(newPos)
        let newArr = []
        for(let i = 0; i < max; i++)
        {
            let newPos = i + newOffset < max ? i+newOffset : i+newOffset-max
            newArr[newPos] = JiangXingStars[i]
        }
        return newArr
    }
}

export default new ChartGeneratorHelper();
import React, { Component } from 'react'
/**
 * usage:
    import FetchWrapper from '../utilities/FetchWrapper'
    const Fetch = new FetchWrapper()
 */
export default class FetchWrapper {
    constructor(hostname)
    {
        hostname = (typeof hostname !== 'undefined') ?  hostname : ""
        this.host = hostname
    }
    get(url, params = [], onResults)
    {
        let query = ''
        params.forEach(param => {
            query += `/${param}`
        })
        const completeUrl = this.host+url+query
        let response_status=200
        return fetch(completeUrl, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json"
            }
        })
        .then(r => {
            response_status=r.status
            return r.json()
        })
        .then(response => {
            if(response_status!==200)//error
            {
                return onResults(response.code, null)
            }
            else 
            {
                return onResults(null, response)
            }
        })
        .catch(err => {
            return onResults(err, null)
        })
    }
    post(url, body, onResults)
    {
        const completeUrl = this.host+url
        let response_status=200
        return fetch(completeUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then(r => {
            response_status=r.status
            return r.json()
        })
        .then(response => {
            if(response_status!==200) //error
            {
                return onResults(response.code, null)
            }
            else 
            {
                return onResults(null, response)
            }
        })
        .catch(err => {
            return onResults(err, null)
        })
    }
    delete(url, params = [], onResults)
    {
        let query = ''
        params.forEach(param => {
            query += `/${param}`
        })
        const completeUrl = this.host+url+query
        let response_status=200
        return fetch(completeUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json"
            }
        })
        .then(r => {
            response_status=r.status
            return r.json()
        })
        .then(response => {
            if(response_status!==200)//error
            {
                return onResults(response.code, null)
            }
            else
            {
                return onResults(null, response)
            }
        })
        .catch(err => {
            return onResults(err, null)
        });
    }
    postNoCors(url, body, onResults) {
        const completeUrl = this.host+url
        let response_status=200
        return fetch(completeUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then(r => {
            response_status=r.status
            return r.json()
        })
        .then(response => {
            if(response_status!==200)//error
            {
                return onResults(response.code, null)
            }
            else
            {
                return onResults(null, response)
            }
        })
        .catch(err => {
            return onResults(err, null)
        })
    }
}

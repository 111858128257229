import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'
import Iframe from 'react-iframe'
import Header from '../../assests/Header'
import { 
    IonContent,
    IonPage,
    IonTitle,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react'
import lang from '../../lang/init'
const { innerWidth, innerHeight } = window


const ReserveReading = props => {
    const dict = lang(props.preferences.lang)['reserve']

    return (<IonPage>
        <Header title={dict['title']} 
            withSearchBar={false} 
            leftComponent={<IonButtons slot="start">
                <IonMenuButton />
            </IonButtons>} 
        />
        <IonContent>   
            <div className="container">
                <Iframe url={`https://docs.google.com/forms/d/e/1FAIpQLSdfgMQl699XObMstXl6IAy9ufTl1pevAwaahHe6aU25CngbnA/viewform`}
                    width={innerWidth}
                    height={innerHeight - 60 - 30}
                    id="reserve-fortune-telling"
                    className="iframe-fortune-telling"
                    display="initial"
                    position="relative"
                    overflow="auto"
                />
            </div>
        </IonContent>
    </IonPage>)
}

export default withPreloader(withRouter(ReserveReading))
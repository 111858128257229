import React, { useEffect, useState, useRef } from 'react'
import { IonCheckbox, IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton, IonList, IonListHeader, IonImg, IonDatetime, IonPicker, IonSelect, IonSelectOption } from '@ionic/react'
import DropDown from '../../assests/DropDown'
import { pickerController } from '@ionic/core'
import DateTimePicker from '../../assests/DateTimePicker'
import CPH from '../../helpers/ChartProfileHelper'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import moment from 'moment'
import init from '../../config/init'
import '../../theme/picker.css'

const timezone = require('../../json/TimeZone.json')

// console.log(timezone)

const monthColumns = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
]
// console.log(Picker)
// const dayColumns = [
//     "1日","2日","3日","4日","5日","6日","7日","8日","9日","10日","11日","12日","13日","14日","15日","16日","17日","18日","19日","20日","21日","22日","23日","24日","25日","26日","27日","28日","29日","30日","31日",
// ]

let timezoneKeysArr = Object.keys(timezone)

let defaultTimezoneOptions = updatePicker(0)

let timezoneKeys = [timezoneKeysArr, defaultTimezoneOptions]
// console.log(timezoneKeys)

function updatePicker(countryIdx) {
    let timezoneOptions = []
    Object.keys(timezone[timezoneKeysArr[countryIdx]]).map(k => {
        const timezoneDisplay = `${timezone[timezoneKeysArr[countryIdx]][k]['tw']} ${timezone[timezoneKeysArr[countryIdx]][k]['gmt']}`
        const timezoneValue = `${timezone[timezoneKeysArr[countryIdx]][k]['tz']}`
        timezoneOptions.push({
            text: timezoneDisplay,
            value: timezoneValue
        })
    })
    return timezoneOptions
}

async function openPicker(columnOptions = timezoneKeys) {
    const columns = []
    columnOptions.forEach((column, idx) => {
        const options = getColumns(idx, column.length, column)
        columns.push(options)
    })
    // console.log(columns)
    const picker = await pickerController.create({
        // columns: this.getColumns(numColumns, numOptions, columnOptions),
        columns: columns,
        buttons: [
            {
                text: 'Cancel',
                role: 'cancel'
            },
            {
                text: 'Confirm',
                handler: (value) => {
                    // console.log(value);
                }
            }
        ]
    })

    await picker.addEventListener('ionPickerColChange', async (event) => {
        // console.log(picker)
        const data = event.detail
        // console.log(pickerController.getTop())
        pickerController.getTop().then(r => true)
        if(data.name === 'col-0')
        {
            const colSelectedIndex = data.selectedIndex
            let updateColumns = []
            let updateTimezoneOptions = await updatePicker(colSelectedIndex)

            let newTimezone = [timezoneKeysArr, updateTimezoneOptions]
            newTimezone.forEach((column, idx) => {
                const options = getColumns(idx, column.length, column)
                updateColumns.push(options)
            })
            updateColumns[0]['selectedIndex'] = colSelectedIndex
            updateColumns[1]['selectedIndex'] = 0
            // console.log(updateColumns)
            picker.columns = updateColumns
            // console.log(picker.columns)
        }
      })
    await picker.present()
    // return picker
}

function getColumns(columnIdx, numOptions, columnOptions) {
    // console.log(columnOptions)
    // let columns = [];
    // for (let i = 0; i < numColumns; i++) {
    //     columns.push({
    //         name: `col-${columnIdx}`,
    //         options: getColumnOptions(i, numOptions, columnOptions)
    //     })
    // }
    // console.log(columns)
    const column = {
        name: `col-${columnIdx}`,
        options: getColumnOptions(numOptions, columnOptions)
    }
    // console.log(column)
    return column
}

function getColumnOptions(numOptions, columnOptions, selected = 0) {
    // console.log(columnOptions)
    
    let options = [];
    for (let i = 0; i < numOptions; i++) {
        if(typeof columnOptions[i] === 'string')
        {
            options.push({
                text: columnOptions[i],
                value: i,
                duration: 150,
                // selected: selected === i,
                // transform: "translate3d(0px,-9999px,90px) "
            })
        }
        else
        {
            options.push({
                text: columnOptions[i]['text'],
                value: columnOptions[i]['value'],
                duration: 150,
                // selected: selected === i,
                // transform: "translate3d(0px,-9999px,90px) "
            })
        }
    }

    return options
}

let tzOptions = []
timezoneKeys.forEach(k => {
    tzOptions.push({
        text: k,
        value: k
    })
})
// const timezoneFirstColumn = {
//     name: 'Time Zone',
//     options: tzOptions
// } as PickerColumn;
// // console.log([timezoneFirstColumn])



const GenerateChart = props => {
    console.log('generate chart is called')
    // console.log(props)
    // const initProp = {...props}
    const [gender, updateGender] = useState(1)
    const [nickname, updateNickname] = useState('')
    const [birthday, updateBirthday] = useState('')
    const [birthdayDisp, updateBirthdayDisp] = useState('')
    const [isSolarTime, setSolarTime] = useState(false)
    const [isLunarDate, updateIsLunarDate] = useState(false)
    const [isLeapMonth, updateIsLeapMonth] = useState(false)
    // const [birthtime, updateBirthtime] = useState('')
    const [openTzPicker, updateOpenTzPicker] = useState(false)
    const [category, updateCategory] = useState('')
    const [timezone, updateTimezone] = useState('')
    
    const dict = props.dict
    // console.log(dict)

    useEffect(() => {
        if(props.hasOwnProperty('gender') && props.gender !== null && props.gender !== undefined) updateGender(props.gender)
        if(props.hasOwnProperty('nickname') && props.nickname !== null && props.nickname !== undefined) updateNickname(props.nickname)
        if(props.hasOwnProperty('birthday') && props.birthday !== null && props.birthday !== undefined) 
        {
            updateBirthday(ChineseCalendarHelper.formatDateDisplay(props.birthday))
            // updateBirthdayDisp(moment(props.birthday).local().format('YYYY-MM-DDTHH:mmZ'))
            // console.log("update birthday display")
            updateBirthdayDisp(`${isLunarDate ? '農曆':'國曆'}${moment(props.birthday).format("YYYY年MM月DD日 HH:mm")}`)
        }
        if(props.hasOwnProperty('category') && props.category !== null && props.category !== undefined) updateCategory(props.category)
        if(props.hasOwnProperty('solarTime') && props.solarTime !== null && props.solarTime !== undefined) setSolarTime(!props.solarTime)
        // if(props.hasOwnProperty('nickname') && props.nickname !== null && props.nickname !== undefined) updateNickname(props.nickname)
    }, [])
    // console.log(`lunar ${isLunarDate}`)
    // console.log(birthday)
    // console.log(birthdayDisp)
    // console.log(`current gender ${openTzPicker}`)

    return (<React.Fragment>

        <IonList lines="full" className="ion-no-margin">
            <IonListHeader lines="none">
                <IonLabel className=''>
                    新增命盤
            </IonLabel>
            </IonListHeader>

            <IonItem>
                <IonLabel position="stacked">{dict['enterGender']}</IonLabel>

                <div style={{ display: 'flex', padding: '40px 0px 20px 0px', justifyContent: 'space-evenly', width: '100%' }}>
                    {gender === 0 ?
                        <IonImg onClick={() => updateGender(0)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Female-Icon.svg')} />
                        :
                        <IonImg onClick={() => updateGender(0)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Female-Icon-Unselected.svg')} />
                    }
                    {gender === 1 ?
                        <IonImg onClick={() => updateGender(1)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Male-Icon.svg')} />
                        :
                        <IonImg onClick={() => updateGender(1)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Male-Icon-Unselected.svg')} />
                    }
                </div>

            </IonItem>

            <IonItem>
                <IonLabel position="floating">{dict['nickname']}</IonLabel>
                <IonInput placeholder={dict['nickeNamePlaceholder']} type="text" value={nickname} onIonChange={e => updateNickname(e.detail.value)} clearInput></IonInput>
            </IonItem>

            <IonItem>
                <IonLabel position="floating" >{`${dict['dob']}${dict['dispSolar']}`}</IonLabel>
                {<IonInput id='datepicker-wrapper' placeholder={dict['dobPlaceholder']} type="text" value={birthdayDisp} clearInput></IonInput>}
                <DateTimePicker isLunarDate={isLunarDate} updateIsLunarDate={updateIsLunarDate} updateBirthday={updateBirthday} updateBirthdayDisp={updateBirthdayDisp} birthday={birthday || props.birthday} isLeapMonth={isLeapMonth} updateIsLeapMonth={updateIsLeapMonth} />
            </IonItem>

            {/*<IonItem>
                <IonLabel position="floating">生辰{isLunarDate ? '（主頁面上農曆日期將轉換為國曆顯示）':null}</IonLabel>
                <IonDatetime max="2100" displayFormat={isLunarDate ? "農曆YYYY年MMM月DD日 HH:mm":"國曆YYYY年MMM月DD日 HH:mm"} monthShortNames={monthColumns} value={birthdayDisp} placeholder="例：1990年1月1日 10:00" 
                    pickerOptions={{
                        buttons: [
                        {
                            text: '輸入國曆',
                            handler: (e) => {
                                // console.log(e)
                                const date = new Date(e.year.value, e.month.value-1, e.day.value, e.hour.value, e.minute.value)
                                const val = ChineseCalendarHelper.formatDateDisplay(date)
                                updateBirthday(val)
                                updateBirthdayDisp(date)
                                updateIsLunarDate(false)
                            }
                        }, {
                            text: '輸入農曆',
                            handler: (e) => {
                                // console.log(e)
                                const date = new Date(e.year.value, e.month.value-1, e.day.value, e.hour.value, e.minute.value)
                                // console.log(date)
                                const val = ChineseCalendarHelper.formatDateDisplay(date)
                                updateBirthday(val)
                                updateBirthdayDisp(date)
                                updateIsLunarDate(true)
                            }
                        }, {
                            text: '取消',
                            role: 'cancel'
                        }
                        ]
                    }}
                    onIonChange={e => {
                    // const val = moment.utc(e.detail.value).format("YYYY-MM-DD_HH-mm-ss[Z]")
                    const val = ChineseCalendarHelper.formatDateDisplay(e.detail.value)
                    updateBirthday(val)
                    updateBirthdayDisp(e.detail.value)
                }} clearInput></IonDatetime>
                
            </IonItem>*/}
            


            {/*<IonItem>
                <IonLabel position="floating">時辰</IonLabel>
                <IonDatetime displayFormat="HH:mm A" monthShortNames={monthColumns} value={birthtime} placeholder="請輸入命主出生時辰" onIonChange={e => updateBirthtime(e.detail.value)} clearInput></IonDatetime>
            </IonItem>*/}

            {/*<IonItem>
                <IonLabel position="floating">時區</IonLabel>
                <IonInput onIonFocus={() =>  openPicker(timezoneKeys)} placeholder="輸入出生地經緯度" value={timezone}></IonInput>
            </IonItem>*/}

            <IonItem>
                <IonLabel position="stacked">{dict['category']}</IonLabel>
                <DropDown options={[{
                    text: dict['categories']['family'],
                    value: 'family'
                },{
                    text: dict['categories']['friend'],
                    value: 'friend'
                },{
                    text: dict['categories']['client'],
                    value: 'client'
                },{
                    text: dict['categories']['celebrity'],
                    value: 'celebrity'
                },{
                    text: dict['categories']['others'],
                    value: 'others'
                }]} value={category} handleSelect={e => updateCategory(e.target.value)} placeholder={dict['categoryPlaceholder']} />
                {/*<IonInput placeholder="未分類、家人、朋友、客戶" value={category} onIonChange={e => updateCategory(e.detail.value)} clearInput></IonInput>*/}
            </IonItem>

            {/*<IonItem>
                <IonLabel>{dict['useSolar']}</IonLabel>
                <IonCheckbox color="zdark" checked={isSolarTime} onIonChange={e => setSolarTime(e.detail.checked)} />
            </IonItem>*/}

            <IonRow>
                <IonCol>
                    <IonButton color="zprimary" expand="block" onClick={async () => {
                        let toConverTime=!isSolarTime
                        let inputDate = birthday !== '' ? ChineseCalendarHelper.formatDateDisplay(birthday) : ChineseCalendarHelper.formatDateDisplay()
                        // console.log(inputDate)
                        if(isLunarDate)
                        {
                            // console.log('init ', inputDate)
                            const y = moment(inputDate).year()
                            const m = moment(inputDate).month()+1
                            const d = moment(inputDate).date()
                            const h = moment(inputDate).hour()
                            const mm = moment(inputDate).minute()
                            const solar = ChineseCalendarHelper.lunarToSolarConvert(y,m,d, isLeapMonth)
                            // console.log(`input y ${y} m ${m} d ${d}`)
                            const newSolarDate = new Date(solar.cYear, solar.cMonth-1, solar.cDay, h, mm)
                            // console.log(newSolarDate)
                            inputDate = ChineseCalendarHelper.formatDateDisplay(newSolarDate)
                        }
                        if(props.edit)
                        {

                            // console.log(`the entered input date is ${inputDate}`)
                            const charts = await CPH.addLocalChartList('', category !== '' ? category:'others', inputDate, gender === 0 ? 'Female':'Male', init.defaultLatLng[0], init.defaultLatLng[1], nickname !== '' ? nickname : dict['defaultNickname'], category, toConverTime)
                            
                            await props.handleAddChart()
                            await props.handleEditChart(props.uuid)
                            return
                        }
                        // let inputDate = birthday !== '' ? ChineseCalendarHelper.formatDateDisplay(birthday) : ChineseCalendarHelper.formatDateDisplay()
                        // if(isLunarDate)
                        // {
                        //     const y = moment(inputDate).year()
                        //     const m = moment(inputDate).month()
                        //     const d = moment(inputDate).date()
                        //     const h = moment(inputDate).hour()
                        //     const mm = moment(inputDate).minute()
                        //     const solar = ChineseCalendarHelper.lunarToSolarConvert(y,m,d)
                        //     // console.log(`input y ${y} m ${m} d ${d}`)
                        //     const newSolarDate = new Date(solar.cYear, solar.cMonth, solar.cDay, h, mm)
                        //     inputDate = ChineseCalendarHelper.formatDateDisplay(newSolarDate)
                        // }

                        // const charts = await CPH.addLocalChartList('', category !== '' ? category:'others', birthday !== '' ? ChineseCalendarHelper.formatDateTimeForMySQL(birthday) : ChineseCalendarHelper.formatDateTimeForMySQL(), gender === 0 ? 'Female':'Male', 25, 121, nickname !== '' ? nickname:'隨機起盤', category, toConverTime)
                        const charts = await CPH.addLocalChartList('', category !== '' ? category:'others', inputDate, gender === 0 ? 'Female':'Male', init.defaultLatLng[0], init.defaultLatLng[1], nickname !== '' ? nickname:dict['defaultNickname'], category, toConverTime)
                        
                        // console.log(charts)
                        return props.handleAddChart()

                        // const loginResult = await AuthenticationHelper.login(username, password)
                        // console.log(loginResult)
                    }}>{props.edit ? dict['confirmEdit']:dict['confirmAdd']}</IonButton>
                </IonCol>

            </IonRow>
            <IonRow style={{ cursor: 'pointer' }} onClick={() => props.handleClose()}>
                <IonCol style={{ display:'flex', justifyContent: 'center' }}>
                    <p>取消</p>
                </IonCol>
            </IonRow>
            {/*<IonItem style={{ borderBottom: 'transparent' }}>
                <p>取消</p>
            </IonItem>*/}

        </IonList>


    </React.Fragment>)
}



export default GenerateChart
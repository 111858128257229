import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router"
import moment from 'moment'
import withPreloader from '../withPreloader'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonMenuButton, IonRow, IonCol, IonImg, IonSearchbar, withIonLifeCycle } from '@ionic/react'
// import Loader from 'react-loader-spinner'
import Loader from 'react-loaders'

import FortuneChatHelper from '../../helpers/FortuneChatHelper'
import ChartGeneratorHelper from '../../helpers/ChartGeneratorHelper'
import ChartProfileHelper from '../../helpers/ChartProfileHelper'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'

import MemberOnly from './MemberOnly'
import TianGan from '../../json/TianGan.json' // Celetial Stem
import lang from '../../lang/init'
import init from '../../config/init'

import './Chat.css';
import 'loaders.css'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

//in case you would like to use local chat, first use this function to get the local chat json
//and then when adding to chat, replace ::let nextObj = await FortuneChatHelper.getChatBlock(initKey,""):: with :: let nextObj = chatJSON[initKey] ::
//const chatJSON = FortuneChatHelper.getLocalChatJson()


const restartBlock={
  tw: '希望您喜歡我的回答',
  options: [
    {
      tw: '重新開始',
      next: 'RESTART'
    }
  ]
}

const defaultChart = {
  nickname: '隨機起盤',
  date_of_birth: new Date()
}
const conversationBuffer = 500
let chartHourOffset = 0
const getNewZodiacOffset= (offsetHours)=>{
  let newHours = offsetHours
    if(offsetHours>=24)
    { 
      newHours= offsetHours-24
    }
    else if(offsetHours<0)
    {
      newHours= 24 + offsetHours
    }
    return newHours
}
class ChatClass extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      chatLog: [],
      initKey: '1',
      searchText: '',
      chartData: [],
      loading: true,
      failedToLoad: false,
      displayMemberOnly: true,
      options: []
    }
    this.getProfile = this.getProfile.bind(this)
    this.getChart = this.getChart.bind(this)
    this.addToChat = this.addToChat.bind(this)
    this.addToAnswer = this.addToAnswer.bind(this)
    this.addToOptions = this.addToOptions.bind(this)
    this.redirectToSetting = this.redirectToSetting.bind(this)
  }

  async ionViewWillEnter()
  {
    // console.log("ion view entere")
    this.setState({
      chatLog: [],
      initKey: '1',
      options: []
    })
    try
    {
        const memberState = await UserProfileHelper.getMembershipState(AuthenticationHelper.uuid)
        // console.log('got member state')
        // console.log(memberState)
        if(memberState.hasOwnProperty('data') && memberState.data.hasOwnProperty('member_type') && memberState.data.member_type === 1)
        {
            this.setState({
              displayMemberOnly: false
            })
            // const profile = await this.getProfile()
            // console.log(profile)
            const chartData = await this.getChart()
            if(!chartData)
            {
              // console.log('should redirect to the setting screen')
              return this.addToChat("5", false)
            }

            // console.log(this.state)

            return this.addToChat(this.state.initKey, false)
        }
    }
    catch(e)
    {
        // console.log(e)
        return this.setState({
          displayMemberOnly: true
        })
    }
  }

  // async componentDidMount()
  // {
  //   console.log("component did mount")
  // }

  async getProfile()
  {
    if(this.props.isLoggedIn)
    {
        // console.log(`user has logged in`)
        try
        {
            // const profile = await UserProfileHelper.getProfileById(AuthenticationHelper.uuid)
            let chartResults= await ChartProfileHelper.getOnlineChartListData(AuthenticationHelper.uuid)
            // console.log(profile)
            // console.log(chartResults)
            if(Object.keys(chartResults) <= 0)
            {
                return false
            }
            // console.log(chartResults.length)
            let selfChart = null
            if(chartResults.length > 0)
            {   // find the self chart to fill the data
                // console.log(`find local chart`)
                selfChart = chartResults.find(e => e.category === init.categories[0]['value'])
                return selfChart
            }
            return false
        }
        catch(e)
        {
            return false
            // console.log('error happened')
            // console.log(e)
        }
    }
    return false
  }

  async getChart()
  {
    try
    {
      // console.log("get chart called")
      let chartResults= await ChartProfileHelper.getLocalChartListData()
      /** Get uer's own chart. If user has not set the chart, then redirect to the setting page */
      // const chartObj = chartResults.find(r => r.category === init.categories[0]['value']) || defaultChart
      let chartObj = chartResults.find(r => r.category === init.categories[0]['value'])
      // console.log(chartObj)
      // return null
      if(chartObj === undefined)
      {
        // console.log("user has not setup the chart")
        // user has not set up the chart, instruct them
        return null
      }
      // console.log("user has set up the chart ")
      // const chartObj = this.props.location.hasOwnProperty('state') && this.props.location.state !== undefined ? this.props.location.state['chartData'] : defaultChart
      // console.log(chartObj)
      // const chartObj = props.location.state['chartData']
      // console.log(chartData) 
      // console.log(new Date(chartData.date_of_birth))
      // console.log(`input date ${chartObj.date_of_birth}`)
      const formatDate = ChineseCalendarHelper.formatDateForChart(chartObj.date_of_birth)
      // console.log(`format data ${formatDate}`)
      const today = chartObj.date_of_birth !== null && chartObj.date_of_birth !== undefined ? ChineseCalendarHelper.formatDateDisplay(chartObj.date_of_birth) : ChineseCalendarHelper.formatDateDisplay()
      // console.log(today)
      const gender = chartObj.gender!==null && chartObj.gender!==undefined? chartObj.gender: "Female"
      // console.log(today)
      const date = ChineseCalendarHelper.getLunarCalendar(moment(today).year(), moment(today).month() + 1, moment(today).date())
      // const date = ChineseCalendarHelper.getLunarCalendar(today.getFullYear(),today.getMonth() + 1, today.getDate())
      if(Object.keys(date).length <= 0 || !date.hasOwnProperty('lYear') || !date.hasOwnProperty('lMonth') || !date.hasOwnProperty('lDay'))
      {
        return null
      }
      // console.log('check point 1')
      /*year, month, day, hour, mins, sec, lat, lng */
      // console.log(date)
      // const d = ChineseCalendarHelper.getLunarCalendar(1978,4,5)
      // console.log()
      const getTianGan = parseInt(date.lYear,10)%10
      const TG = TianGan[getTianGan].mapStart
      const arr1 = TianGan.slice(TG, TianGan.length)
      const arr2 = TianGan.slice(0, TianGan.length - arr1.length + 2)
      const arr3 = arr1.concat(arr2)

      const hh = getNewZodiacOffset(moment(today).hour()+chartHourOffset) //add the chart offset hours
      const mm = moment(today).minute()
      // console.log(`the hour ${hh} and minutes ${mm}`)

      const monthDZ = ChineseCalendarHelper.convertDiZhiToId(date['gzMonth'][1])
      // console.log(`month dizhi is ${monthDZ}`)
      //let chartResult = await ChartGeneratorHelper.getChartList(parseInt(date.lYear,10), parseInt(date.lMonth,10), parseInt(date.lDay, 10), 17, 27, 0, 23, 120,gender,false)
      
      //in the end, add a flag to see if it is solar time
      const is_solar_time = chartObj.hasOwnProperty('is_solar') && chartObj['is_solar'] !== null && chartObj['is_solar'] !== undefined ? chartObj['is_solar'] : false
      let chartResult = await ChartGeneratorHelper.getChartListFromTimeZone(parseInt(date.lYear,10), parseInt(date.lMonth,10), parseInt(date.lDay, 10), hh, mm, 0,"Asia/Taipei", gender, is_solar_time, monthDZ) 
      console.log(chartResult)
      if(!chartResult.hasOwnProperty('data'))
      {
        throw Error(false)
      }
      /*
      let chartData = [], lifeHouseElement = ''

      chartResult.data.blockData.forEach((d, idx) => {
        chartData[idx] = {...d}
        chartData[idx]['zodiac_id'] = DiZhi[idx].id
        chartData[idx]['celetial_id'] = arr3[idx].id // TianGan
        chartData[idx]['elements'] = `${arr3[idx].id}${DiZhi[idx].id}`
        if(d.palace_str === 'Life')
        {
          lifeHouseElement = `${arr3[idx].id}${DiZhi[idx].id}`
        }
      })
      console.log(chartData)
      */

      //console.log(`life house element is ${lifeHouseElement}`)
      //console.log(NaYinFiveElements[lifeHouseElement])
      
      // await setChartData(chartResult.data)
      // await updateLoading(false)
      this.setState({
        chartData: chartResult.data,
        supportData: chartResult.data.supportStarData,
        profile: chartObj
      })
      return true
      // console.log(chartData)
    }
    catch(e)
    {
      // console.log(e)
      return null
      // await updateLoading(false)
      // await updateFailedToLoad(true)
    }
  }

  async addToChat(nextKey, user = false) {
    // console.log(`next key ${nextKey}`)
    if(nextKey === 'RESTART')
    {
      // await this.getChart()
      this.setState({
        chatLog: []
      })
      const self = this
      let nextObj = await FortuneChatHelper.getChatBlock(self.state.initKey,"")

      setTimeout(() => {
        
        if(user)
        {
          nextObj['user'] = user
        }
        let newChatLog = []
        newChatLog.push(nextObj)
        self.setState({
          chatLog: newChatLog
        })
      }, 1000)

      return
    }
    if(nextKey === 'SKIP')
    {
      return
    }
    // if(nextKey === 'END_REFRESH')
    // {
    //   // get the new chart
    //   this.setState({
    //     chatLog: []
    //   })
    //   const self = this
    //   let nextObj = await FortuneChatHelper.getChatBlock(self.state.initKey,"")

    //   setTimeout(() => {
        
    //     if(user)
    //     {
    //       nextObj['user'] = user
    //     }
    //     let newChatLog = []
    //     newChatLog.push(nextObj)
    //     self.setState({
    //       chatLog: newChatLog
    //     })
    //   }, 1000)

    //   return
    // }

    let newChatLog = [...this.state.chatLog]
    // console.log(newChatLog)
    //uncomment for local chat//
    //let nextObj = chatJSON[nextKey]

    //uncomment for online chat 
    let nextObj={}
    if(nextKey=="end")
    {
      nextObj = restartBlock
    }
    else{
      nextObj = await FortuneChatHelper.getChatBlock(nextKey,"")
    }
    //end uncomment for online chat 

    if(user)
    {
      nextObj['user'] = user
    }
    newChatLog.push(nextObj)
    this.setState({
      chatLog: newChatLog
    }, () => {
      setTimeout(() => {
        if(nextObj.hasOwnProperty('options'))
        {
          this.addToOptions(nextObj.options)
        }
      }, conversationBuffer)

    })
    return
    // return updateChatLog(newChatLog)
  }

  async addToAnswer(answerText = '請繼續', nextKey = 'END', user = true)
  {
    let newChatLog = [...this.state.chatLog]
    let newChatObj = {
      "tw": answerText,
      "next": nextKey,
      "user": user
    }
    newChatLog.push(newChatObj)
    this.setState({
      chatLog: newChatLog,
      options: []
    })
  }

  async addToOptions(optionsArr = [])
  {
    this.setState({
      options: optionsArr
    })
  }

  redirectToSetting()
  {
    return this.props.history.push(init.router.setting.url)
  }

  render()
  {
    // console.log('test')
    let chatBubbles = []
    // this.addToChat()
    this.state.chatLog.forEach((chat,idx) => {
      chatBubbles.push(<Bubble key={`chat-bubble-${idx}`} idx={idx} {...chat} addToChat={this.addToChat} />)
    })
    if(!this.props.isLoggedIn || this.state.displayMemberOnly)
    {
      return <IonPage>
        <IonHeader>
          <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
            <IonTitle  className='ion-font-title'>紫微攻略小助手</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent scrollY={"true"} >
          <MemberOnly {...this.props} />
        </IonContent>
      </IonPage>
    }
    return <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
          <IonTitle  className='ion-font-title'>紫微攻略小助手</IonTitle>
        </IonToolbar>
        {/*<IonToolbar>

        <IonSearchbar value={this.state.searchText} onIonChange={e => this.setState({ searchText: e.detail.value })} showCancelButton="always"></IonSearchbar>
        </IonToolbar>*/}
      </IonHeader>
      
      <IonContent scrollY={"true"} >
        {/*<IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">解盤互動機器人</IonTitle>
            </IonToolbar>
        </IonHeader>*/}
        {/*<ExploreContainer name={'紫微攻略小助手'} />*/}

        {/*<IonRow style={{ margin: 'auto', marginTop: '2em' }} >
        <IonCol>
        <IonTitle className='error-font'>{dict['oops']}</IonTitle>
        </IonCol>
        </IonRow>
        
        <IonRow>
        <IonCol>
          <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
          <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['payFirst']}</p>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
          <IonButton color="zprimary" expand="block" style={{ margin: 'auto', marginTop: '2em' }} onClick={() => props.history.push(`${init.router.subscribe.url}`)}>{dict['toPayment']}</IonButton>
        </IonCol>
        </IonRow>*/}
        {chatBubbles}
        <OptionBubbles profile={this.state.profile} chartData={this.state.chartData} options={this.state.options} addToAnswer={this.addToAnswer} addToChat={this.addToChat} redirectToSetting={this.redirectToSetting} />
      </IonContent>
    </IonPage>
  }
}

const Bubble = props => {

  const [loading, updateLoading] = useState(true)

  const thisProp = props
  
  useEffect(() => {
    // console.log(thisProp)
    setTimeout(() => {
      updateLoading(false)
      if(thisProp.hasOwnProperty('next') && !thisProp.hasOwnProperty('options'))
      {
        //console.log('props has next')
        thisProp.addToChat(props.next, false)
      }
    }, conversationBuffer)
  }, [])

  if(loading && !props.user)
  {
    return (<div className={props.user ? `chat-row user-row`:`chat-row`} onClick={props.hasOwnProperty('next') ? e => props.addToChat(props.next, false): null}>
      <div className={props.user ? `chat-bubble user-bubble`:`chat-bubble system-bubble`}>
        <Loader
          type="ball-beat"
          color="#92949c"
          active
        />
        {/*<p>{props.tw}</p>*/}
      </div>
    </div>)
  }
  return (
    <div className={props.user ? `chat-row user-row`:`chat-row`} onClick={props.hasOwnProperty('next') ? e => props.addToChat(props.next, false): null}>
      <div className={props.user ? `chat-bubble user-bubble`:`chat-bubble system-bubble`}>
        {/*<p>{props.tw}</p>*/}
        <BubbleText text={props.tw} />
        {/*optionsArr*/}
      </div>
    </div>

  )
}

const BubbleText = props => {
  const content = props.text.split(/\\n/gm)
  let contentArr = []
  content.forEach((text, idx) => {
    contentArr.push(<React.Fragment key={`bubbleText-wrapper-${props.id}-${idx}`}><span key={`bubbleText-${props.id}-${idx}`}>{text}</span><br/></React.Fragment>)
  })
  return <p>{contentArr}</p>
}

const OptionBubbles = props => {
  const thisProp = props
  let optionsArr = []
  //console.log(props)
  if(props.hasOwnProperty('options'))
  {
    props.options.forEach((option, idx) => {
      if(option.hasOwnProperty('next'))
      {
        return optionsArr.push(<IonButton key={`bubble-option-${props.idx}-${idx}`} className='chat-bubble-option-bubble' onClick={async () => {
          if(option?.script && option?.script !== 'REDIRECT_TO_ACCOUNT')
          {
            // console.log('gotchat')
            // console.log(option.script)
            // console.log(props.chartData)
            if(props.chartData.hasOwnProperty('blockData') && props.chartData.blockData.length > 0)
            {
              // console.log('fortune found')
              const c = await FortuneChatHelper.executeScripts(option.script, props.chartData, props.profile.id)
              // console.log(c)
              if(c)
              {
                // console.log(`option script is ${option.script.includes('YEAR')}`)
                props.addToAnswer(option.tw, 'SKIP')
                if(c.hasOwnProperty('empty') && c.empty  && !option.script.includes('YEAR'))
                {
                  props.addToAnswer(`您的${option.tw}為空宮，因此參考對宮星曜為${c.element}`, 'SKIP', false)
                }
                else if(!option.script.includes('YEAR'))
                {
                  props.addToAnswer(`您的${option.tw}星曜為${c.element}`, 'SKIP', false)
                }
                setTimeout(() => {
                  props.addToAnswer(c.star, option.next, false)
                }, conversationBuffer)
                return
              }
              return props.addToAnswer(option.tw, 'PENDING')
            }
            // FortuneChatHelper.executeScripts(option.script, )
          }
          if(option?.script && option?.script === 'REDIRECT_TO_ACCOUNT')
          {
            props.addToAnswer(option.tw, 'END')
            return props.redirectToSetting()
          }
          return props.addToAnswer(option.tw, option.next)
          // props.addToChat(option.next, true)
        }} size='large' >{option.tw}</IonButton>)
      }
      return optionsArr.push(<IonButton className='chat-bubble-option-bubble' size='large' >{option.tw}</IonButton>)
    })
  }

  return <IonRow>
    <IonCol>{optionsArr}</IonCol>
  </IonRow>

}

// const Chat = (props) => {
//   const [chatLog, updateChatLog] = useState([])
//   const [initKey, updateInitKey] = useState('1')
//   const [searchText, setSearchText] = useState('')
//   const [chartData,setChartData] = useState([])
//   const [loading, updateLoading] = useState(true)
//   const [failedToLoad, updateFailedToLoad] = useState(false)

//   const dict = lang(props.preferences.lang)['chat']

//   /************************ */
//   /* TEST LOADING FROM SERVER*/
//   /************************* */
//   const getChat = async () =>{
//     try
//     {      
//       // console.log(await FortuneChatHelper.getChatBlock("1",""))
//       // console.log(await FortuneChatHelper.getChatBlock("2",""))
//       // console.log(await FortuneChatHelper.getChatBlock("1",""))
//     }
//     catch(e)
//     {

//     }
//   };
//   /*******************************/
//   /* END TEST LOADING FROM SERVER*/
//   /************************* */
//   //to adjust the zodiac charts
//   const getNewZodiacOffset= (offsetHours)=>{
//     let newHours = offsetHours
//       if(offsetHours>=24)
//       { 
//         newHours= offsetHours-24
//       }
//       else if(offsetHours<0)
//       {
//         newHours= 24 + offsetHours
//       }
//       return newHours
//   }

//   const getSelfProfile = async () => {

//   }

//   const getChart = async () =>{
//     try
//     {
//       // console.log('get chart')
//       const chartObj = props.location.hasOwnProperty('state') && props.location.state !== undefined ? props.location.state['chartData'] : defaultChart
//       // console.log(chartObj)
//       // const chartObj = props.location.state['chartData']
//       // console.log(chartData) 
//       // console.log(new Date(chartData.date_of_birth))
//       // console.log(`input date ${chartObj.date_of_birth}`)
//       const formatDate = ChineseCalendarHelper.formatDateForChart(chartObj.date_of_birth)
//       // console.log(`format data ${formatDate}`)
//       const today = chartObj.date_of_birth !== null && chartObj.date_of_birth !== undefined ? ChineseCalendarHelper.formatDateDisplay(chartObj.date_of_birth) : ChineseCalendarHelper.formatDateDisplay()
//       // console.log(today)
//       const gender = chartObj.gender!==null && chartObj.gender!==undefined? chartObj.gender: "Female"
//       // console.log(today)
//       const date = ChineseCalendarHelper.getLunarCalendar(moment(today).year(), moment(today).month() + 1, moment(today).date())
//       // const date = ChineseCalendarHelper.getLunarCalendar(today.getFullYear(),today.getMonth() + 1, today.getDate())
//       if(Object.keys(date).length <= 0 || !date.hasOwnProperty('lYear') || !date.hasOwnProperty('lMonth') || !date.hasOwnProperty('lDay'))
//       {
//         return null
//       }
//       // console.log('check point 1')
//       /*year, month, day, hour, mins, sec, lat, lng */
//       // console.log(date)
//       // const d = ChineseCalendarHelper.getLunarCalendar(1978,4,5)
//       // console.log()
//       const getTianGan = parseInt(date.lYear,10)%10
//       const TG = TianGan[getTianGan].mapStart
//       const arr1 = TianGan.slice(TG, TianGan.length)
//       const arr2 = TianGan.slice(0, TianGan.length - arr1.length + 2)
//       const arr3 = arr1.concat(arr2)

//       const hh = getNewZodiacOffset(moment(today).hour()+chartHourOffset) //add the chart offset hours
//       const mm = moment(today).minute()
//       // console.log(`the hour ${hh} and minutes ${mm}`)

//       const monthDZ = ChineseCalendarHelper.convertDiZhiToId(date['gzMonth'][1])
//       // console.log(`month dizhi is ${monthDZ}`)
//       //let chartResult = await ChartGeneratorHelper.getChartList(parseInt(date.lYear,10), parseInt(date.lMonth,10), parseInt(date.lDay, 10), 17, 27, 0, 23, 120,gender,false)
      
//       //in the end, add a flag to see if it is solar time
//       const is_solar_time = chartObj.hasOwnProperty('is_solar') && chartObj['is_solar'] !== null && chartObj['is_solar'] !== undefined ? chartObj['is_solar'] : false
//       let chartResult = await ChartGeneratorHelper.getChartListFromTimeZone(parseInt(date.lYear,10), parseInt(date.lMonth,10), parseInt(date.lDay, 10), hh, mm, 0,"Asia/Taipei", gender, is_solar_time, monthDZ) 
//       // console.log(chartResult)
//       if(!chartResult.hasOwnProperty('data'))
//       {
//         throw Error(false)
//       }
//       /*
//       let chartData = [], lifeHouseElement = ''

//       chartResult.data.blockData.forEach((d, idx) => {
//         chartData[idx] = {...d}
//         chartData[idx]['zodiac_id'] = DiZhi[idx].id
//         chartData[idx]['celetial_id'] = arr3[idx].id // TianGan
//         chartData[idx]['elements'] = `${arr3[idx].id}${DiZhi[idx].id}`
//         if(d.palace_str === 'Life')
//         {
//           lifeHouseElement = `${arr3[idx].id}${DiZhi[idx].id}`
//         }
//       })
//       console.log(chartData)
//       */

//       //console.log(`life house element is ${lifeHouseElement}`)
//       //console.log(NaYinFiveElements[lifeHouseElement])
      
//       await setChartData(chartResult.data)
//       await updateLoading(false)
//       console.log(chartData)
//     }
//     catch(e)
//     {
//       // console.log(e)
//       await updateLoading(false)
//       await updateFailedToLoad(true)
//     }
//   }

//   const getYearChart = async () => 
//   {

//   }


//   const addToChat = async (nextKey, user = true) => {
//     //console.log(`next key ${nextKey}`)
//     if(nextKey === 'RESTART')
//     {
//       /*let newChatLog = []
//       updateChatLog(newChatLog)
//       console.log('chat log cleared')*/

//        //setTimeout(() => {
        
//         //uncomment for local chat//
//         //let nextObj = chatJSON[initKey]

//         //uncomment for online chat 
//         let nextObj = await FortuneChatHelper.getChatBlock(initKey,"")
        
//         if(user)
//         {
//           nextObj['user'] = user
//         }
//         let newChatLog = []
//         newChatLog.push(nextObj)
//         updateChatLog(newChatLog)
//        //}, 400)

//       return
//     }
//     let newChatLog = [...chatLog]

//     //uncomment for local chat//
//     //let nextObj = chatJSON[nextKey]

//     //uncomment for online chat 
//     let nextObj={}
//     if(nextKey=="end")
//     {
//       nextObj = restartBlock
//     }
//     else{
//       nextObj = await FortuneChatHelper.getChatBlock(nextKey,"")
//     }
//     //end uncomment for online chat 

//     if(user)
//     {
//       nextObj['user'] = user
//     }
//     newChatLog.push(nextObj)
//     return updateChatLog(newChatLog)
//   }
//   let chatBubbles = []

//   useEffect(() => {
//     getChat()
//     addToChat(initKey, false)
//     getChart()
//   }, [])

//   // addToChat()
//   chatLog.forEach((chat,idx) => {
//     chatBubbles.push(<Bubble key={`chat-bubble-${idx}`} idx={idx} {...chat} addToChat={addToChat} />)
//   })
//   // console.log(chatLog)
//   //console.log(chatBubbles)
//   return (
//     <IonPage>

//       <IonHeader>
//         <IonToolbar>
//           <IonTitle  className='ion-font-title'>紫微攻略小助手</IonTitle>
//         </IonToolbar>
//         <IonToolbar>
//         <IonButtons slot="start">
//           <IonMenuButton />
//         </IonButtons>
//         {/*<IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value)} showCancelButton="always"></IonSearchbar>*/}
//         </IonToolbar>
//       </IonHeader>
      
//       <IonContent scrollY={"true"} >
//         {/*<IonHeader collapse="condense">
//           <IonToolbar>
//             <IonTitle size="large">解盤互動機器人</IonTitle>
//             </IonToolbar>
//         </IonHeader>*/}
//         {/*<ExploreContainer name={'紫微攻略小助手'} />*/}

//         {/*<IonRow style={{ margin: 'auto', marginTop: '2em' }} >
//         <IonCol>
//         <IonTitle className='error-font'>{dict['oops']}</IonTitle>
//         </IonCol>
//         </IonRow>
        
//         <IonRow>
//         <IonCol>
//           <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
//           <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['payFirst']}</p>
//         </IonCol>
//         </IonRow>

//         <IonRow>
//         <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
//           <IonButton color="zprimary" expand="block" style={{ margin: 'auto', marginTop: '2em' }} onClick={() => props.history.push(`${init.router.subscribe.url}`)}>{dict['toPayment']}</IonButton>
//         </IonCol>
//         </IonRow>*/}
//         {/*<MemberOnly {...props} />*/}
//         {chatBubbles}
//       </IonContent>
//     </IonPage>
//   )

// }


export default withRouter(withPreloader(withIonLifeCycle(ChatClass)))
import AuthRequestHelper from "./AuthRequestHelper"
import GeneralHelper from "./GeneralHelper"
import ChineseCalendarHelper from './ChineseCalendarHelper'
import { Plugins } from '@capacitor/core'
import init from '../config/init'

const { Storage } = Plugins;
const storageKeyChart= init.storageKey.chartList
//note: structure of local storage is as follows
// storage ["chartList"] = JSON array of charts of current user_id, with two keys ["data"] and ["lastUpdate"]
// The array will be composed of a list of the following data elements
// {chart_uuid, user_uuid , category , relationship , nickname, dob, gender, lat lng, last_edited} (user_uuid should be the current user)

class ChartProfileHelper{

        /*init the local storage if it does not exist */
        async initlocalChartData(){
            // console.log("Init chart")
            let currentDateTime = new Date().toLocaleString();
            await Storage.set({
                key: storageKeyChart,
                value: JSON.stringify({
                    data: [],
                    lastUpdate: currentDateTime
                })
            });      
        }

        //get the chart data from the local system
        async getLocalChartListData(){
            const localUserChartData = await Storage.get({ key: storageKeyChart });
            if(!localUserChartData.value || localUserChartData.value ===undefined ) //if it doesn't exist, init it
            {
                await this.initlocalChartData(); 
                let updatedData = await Storage.get({ key: storageKeyChart }); //get the initated value again
                let JSONObj=JSON.parse(updatedData.value)
                return JSONObj["data"]           
            }
            else{
                let JSONObj=JSON.parse(localUserChartData.value) 
                return JSONObj["data"]
            }

        }

        //remove a chart data based on the local uuid
        //returns a newChartList for when you want to map it to a react state
        async removeLocalChartListData(localUUID){
            let currentChartListData= await this.getLocalChartListData();
            const currentDateTime = new Date().toLocaleString();

            if((Object.keys(currentChartListData).length === 0 && currentChartListData.constructor === Object))
            {
               return [];
            }

            let newChartListData= currentChartListData.filter(chart => chart.local_id !== localUUID)
            await Storage.set({
                key: storageKeyChart,
                value: JSON.stringify({
                    data: newChartListData,
                    lastUpdate: currentDateTime
                })
            });
            return newChartListData   
        }

        //get the chart data from the server
        async getOnlineChartListData(user_id){
            try{
                const requestUrl="userchartlist/"+user_id
                const ChartListDataList= await AuthRequestHelper.request("get",requestUrl,{})
                return ChartListDataList["data"]    
            }
            catch(error){
                console.log(error)
                return {}
            }
        } 
        
        /*remove the chart from the online server */ 
        async removeOnlineChartListData(chart_id){
            try{
                const requestUrl="userchartlist/"+chart_id
                return await AuthRequestHelper.request("delete",requestUrl, {})
            }
            catch(error){
                console.log(error)
                return ""
            }
        }


        /*Add Chart to the online server */
        //NOTE, isLunarMonth and dobLunarString has just been added after running the data live
        async addOnlineChartListData(user_id,category,dobString,gender,lat,lng,nickname,relationship,isSolarTime){
                try{
                    const requestUrl="userchartlist/"+user_id
                    const chartParams={
                        "category": category,
                        "date_of_birth": ChineseCalendarHelper.formatDateTimeForMySQL(dobString),
                        "gender": gender,
                        "lat": lat,
                        "lng": lng,
                        "nickname": nickname,
                        "relationship": relationship,
                        "is_solar": isSolarTime
                    }
                    return await AuthRequestHelper.request("put",requestUrl,  chartParams)
                }
                catch(error){
                    console.log(error)
                    return ""
                }
        }

        /*edit online chart */
        //example of edit params {"category":"family", "gender":"Male"}
        //posible keys are category,dobString,gender,lat,lng,nickname,relationship,isSolarTime
        async editOnlineChartListData(chart_id,editparams){
            try{
                let inputParams={}
                if ("category" in editparams){
                    inputParams["category"] = editparams["category"]
                }
                if ("dobString" in editparams){
                    inputParams["date_of_birth"] = ChineseCalendarHelper.formatDateTimeForMySQL(editparams["dobString"])
                }             
                if ("gender" in editparams){
                    inputParams["gender"] =  editparams["gender"]
                }                           
                if ("lat" in editparams){
                    inputParams["lat"] =  editparams["lat"]
                }      
                if ("lng" in editparams){
                    inputParams["lng"] =  editparams["lng"]
                }                          
                if ("nickname" in editparams){
                    inputParams["nickname"] =  editparams["nickname"]
                }           
                if ("lng" in editparams){
                    inputParams["lng"] =  editparams["lng"]
                }                          
                if ("relationship" in editparams){
                    inputParams["relationship"] =  editparams["nickname"]
                }
                if ("isSolarTime" in editparams){
                    inputParams["is_solar"] =  editparams["isSolarTime"]
                }                                                        
                const requestUrl="userchartlist/"+chart_id
                const chartParams={ ... inputParams}
                return await AuthRequestHelper.request("patch",requestUrl,  chartParams)
            }
            catch(error){
                console.log(error)
                return ""
            }
    }



        /*add a local chart*/
        async addLocalChartList(user_id=null,category,dobString,gender,lat,lng,nickname,relationship,isSolarTime=false){
                try{
                    //create the object,
                    const newObject={
                        "id": null,
                        "local_id": GeneralHelper.uuidv4(), //used as key 
                        "user_id": user_id, 
                        "category": category,
                        "relationship":relationship, 
                        "nickname":nickname,
                        "date_of_birth": ChineseCalendarHelper.formatDateTimeForMySQL(dobString), 
                        "gender":gender, 
                        "lat":lat,
                        "lng":lng,
                        "last_edited": new Date().toLocaleString(),
                        "is_solar": isSolarTime                      
                    }                    
                    let currentChartListData= await this.getLocalChartListData();

                    if((Object.keys(currentChartListData).length === 0 && currentChartListData.constructor === Object))
                    {
                        currentChartListData=[]
                    }
                    
                    currentChartListData.push(newObject)
                    await Storage.set({
                        key: storageKeyChart,
                        value: JSON.stringify({
                        data: currentChartListData,
                        lastUpdate: new Date().toLocaleString()
                        })
                    }); 
                    return currentChartListData
                }
                catch(error){
                    console.log(error)
                    return {}
                }                
        }


        /*overwrites the current storage with the chart info from the server */
        async pullChartListData(userID){
            const onlineChartData = await this.getOnlineChartListData(userID)
            // console.log(onlineChartData)
            let newChartData = []
            onlineChartData.forEach((chart,idx) => {
                let newChart = {...chart}
                newChart["local_id"] = GeneralHelper.uuidv4() //used as key 
                newChartData[idx] = newChart
            })
            const currentDateTime = new Date().toLocaleString();
            // console.log(newChartData)
            //overwrite local chart
            await Storage.set({
                key: storageKeyChart,
                value: JSON.stringify({
                  data: newChartData,
                  lastUpdate: currentDateTime
                })
              });     

            return onlineChartData
        }

        /*overwrites the server storage */        
        async pushChartListData (userID){
            let onlineChartData = await this.getChartList(userID,false);
            let localChartData = await this.getChartList(userID,true);
            // console.log(localChartData)
            //check to see what to push
            let pushServerList=[]

            if((Object.keys(onlineChartData).length === 0 && onlineChartData.constructor === Object))
            {
                onlineChartData=[]
            }

            if(!(Object.keys(localChartData).length === 0 && localChartData.constructor === Object))
            {
                localChartData.forEach( async(eachChart) =>{
                    let matchIndex = onlineChartData.findIndex((eachOnlineChart) => eachChart.local_id === eachOnlineChart.local_id)
                    // let matchIndex= onlineChartData.findIndex((eachOnlineChart) =>  eachChart.relationship === eachOnlineChart.relationship &&  eachChart.nickname === eachOnlineChart.nickname &&  eachChart.dob === eachOnlineChart.dob )
                    if (matchIndex == -1) //if it does not match, push it to the server
                    {
                        pushServerList.push(eachChart)
                        await this.addOnlineChartListData(userID,eachChart.category,eachChart.date_of_birth,eachChart.gender,eachChart.lat,eachChart.lng,eachChart.nickname,eachChart.relationship,eachChart.is_solar);
                    }
                })
            }

            // console.log(onlineChartData)
            onlineChartData.forEach( async(eachChart) =>{
                let matchIndex = localChartData.findIndex((eachOnlineChart) => eachChart.local_id === eachOnlineChart.local_id)
                // let matchIndex= localChartData.findIndex((eachOnlineChart) =>  eachChart.relationship === eachOnlineChart.relationship &&  eachChart.nickname === eachOnlineChart.nickname &&  eachChart.dob === eachOnlineChart.dob )
                if (matchIndex == -1) //if it does not match, delete the one on the server
                {
                    await this.removeOnlineChartListData(eachChart.id);
                    console.log("Removed:"+eachChart.id)
                }
            })
            return pushServerList //return a list of elements that were pushed to the server
        }


        /*******************************************/
        /* DECPRECIATED                           **/
        /***************************************** */
        /*get the profile list either from the user*/
        async getChartList(user_id, fromLocal=false){
            if(!fromLocal)
            {
                try{
                    const requestUrl="userchartlist/"+user_id
                    const ChartListDataList= await AuthRequestHelper.request("get",requestUrl,{})    
                    return ChartListDataList["data"]    
                }
                catch(error){
                    console.log(error)
                    return {}
                }
            }
            else{ //local solution
                try{
                    return await this.getLocalChartListData();
                }
                catch(error){
                    console.log(error)
                    return {}
                }
                
            }
        }


        /*******************************************/
        /* DECPRECIATED                           **/
        /***************************************** */
        /*put the profile list*/
        /*put the date of birth string in the following format: 1980-04-10 07:50:00 */
        async addChartList(user_id,category,dobString,gender,lat,lng,nickname,relationship, fromLocal=false){
            if(!fromLocal)
            { //get from the server
                try{
                    const requestUrl="userchartlist/"+user_id
                    const chartParams={
                        "category": category,
                        "date_of_birth": ChineseCalendarHelper.formatDateTimeForMySQL(dobString),
                        "gender": gender,
                        "lat": lat,
                        "lng": lng,
                        "nickname": nickname,
                        "relationship": relationship
                    }
                    return await AuthRequestHelper.request("put",requestUrl,  chartParams)
                }
                catch(error){
                    console.log(error)
                    return ""
                }
            }
            else{ //local solution
                try{
                    //create the object 
                    const newObject={
                        "id": null,
                        "user_id": user_id, 
                        "category": category,
                        "relationship":relationship, 
                        "nickname":nickname,
                        "date_of_birth": ChineseCalendarHelper.formatDateTimeForMySQL(dobString), 
                        "gender":gender, 
                        "lat":lat,
                        "lng":lng,
                        "last_edited": new Date().toLocaleString()
                    }                    
                    const currentChartListData= await this.getLocalChartListData();

                    if(!this.checkChartExists(newObject, currentChartListData))
                    {
                        currentChartListData.push(newObject)
                        await Storage.set({
                            key: storageKeyChart,
                            value: JSON.stringify({
                            data: currentChartListData,
                            lastUpdate: new Date().toLocaleString()
                            })
                        }); 
                    }             
                    else{
                        console.log("Repeated Chart entry entered")
                    }
                    return currentChartListData
                }
                catch(error){
                    console.log(error)
                    return {}
                }                
            }
        
        }

}

export default new ChartProfileHelper();
import React, { useState, useEffect } from 'react'
import { IonList, IonListHeader, IonItem, IonLabel, IonAvatar, IonIcon, IonToggle, IonThumbnail, IonImg, IonButton } from '@ionic/react'
import './Asset.css'
/**
 * Accordion component
 * @param {*} props 
    usage:
    const accordionArr = [
        {
            key: '',
            title: '',
            items: [
                {
                    img: '',
                    main: '',
                    support: '',
                    handleClick: () => {}
                }
            ]
        },
    ]
 */
const Accordion = props => {

    if(props.accordionArr.length <= 0) return null

    let accordions = []
    props.accordionArr.forEach((a, idx) => {
        accordions.push(<AccordionUnit key={`accordion-list-${a.key}-${idx}`} {...a} itemKey={a.key} idx={idx} />)
    })

    return <IonList>
        {accordions}
    </IonList>
}

const AccordionUnit = props => {
    const [active, updateActive] = useState(false)
    useEffect(() => {
        if(props.idx <= 0) updateActive(true)
    },[])
    return (<React.Fragment>
        <IonListHeader 
            key={`accordion-list-header-${props.itemKey}`} 
            onClick={() => updateActive(!active)}
            style={props.idx > 0 ? { marginTop: '10px' }:null}
            className={active ? `ziwei-accordion-header ion-padding-vertical active` : `ziwei-accordion-header ion-padding-vertical`}
        >
            <IonLabel className='accordion-label-begin ion-font-title accordion-header-title'>
                {props.title}
            </IonLabel>
            <IonLabel className='accordion-label-end ion-justify-content-end ion-margin-horizontal'>
            <IonThumbnail className='accordion-icon' slot="end">
                <IonImg src={active ? require('./images/Up-arrow.svg') : require('./images/Down-arrow.svg')} />
            </IonThumbnail>
            {/*<IonIcon src={active ? require('./images/Up-arrow.svg') : require('./images/Down-arrow.svg')} slot="end"></IonIcon>*/}
            </IonLabel>
        </IonListHeader>
        {active ? <div className='arrow-down'></div> : null}
        <AccordionItem items={props.items} active={active} />
    </React.Fragment>
    )

}

// <AccordionItem items={arr} key={str} />
const AccordionItem = props => {

    if(props.items.length <= 0) return null

    let itemArr = []
    props.items.forEach((item, idx) => {
        itemArr.push(<IonItem key={`accordion-${props.key}-item-${idx}`} className='ion-accordion-item' >
            <IonAvatar slot="start" onClick={item.handleClick !== null ? item.handleClick:null}>
                <img src={item.img} />
            </IonAvatar>
            <IonLabel onClick={item.handleClick !== null ? item.handleClick:null}>
                <h2>{item.main}</h2>
                <p>{item.support}</p>
            </IonLabel>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <button className='accordion-edit-btn' onClick={item.handleEdit}>編輯</button>
                {item.handleDelete !== null ? <button className='accordion-remove-btn' onClick={item.handleDelete}>刪除</button>:null}
            </div>

        </IonItem>)
    })

    return <div className={props.active ? 'accordion-item active':'accordion-item no-active'}>
        {itemArr}
    </div>
}

export default Accordion
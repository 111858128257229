import zh from './zh'
import en from './en'
import init from '../config/init'

const dict = (initCountry = init.defaultLang) => {
    switch(initCountry)
    {
        case 'en':
            return en
        case 'zh':
        default: return zh
    }
}

export default dict
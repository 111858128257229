import React,{useEffect,useState, useRef} from 'react';
import { isPlatform } from '@ionic/react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonAlert
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Index from './components/Index'
import Profiles from './components/Profiles/Index'
import Chart from './components/Profiles/Chart'
import Chat from './components/Bot/Chat'
import Login from './components/Auth/Login.js'
import Register from './components/Auth/Register.js'
import ForgotPass from './components/Auth/ForgotPass'
import Subscribe from './components/Payment/Subscribe.js'
import Textbook from './components/Textbook/Index'
import TabBar from './components/TabBar'
import Menu from './components/Menu'
import Setting from './components/MenuPages/Setting'
import ReserveReading from './components/MenuPages/ReserveReading'
import BackupData from './components/MenuPages/BackupData'
import AdminPage from './components/MenuPages/AdminPage'
import withPreloader from './components/withPreloader'
import Policy from './components/Policy/Policy.js'
import ListeningLocalStorage from './components/ListeningLocalStorage/Login.js'
import config from './config/config'


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/shared-style.css';
import init from './config/init';

//to deal with token on startup
import AuthenticationHelper from './helpers/AuthenticationHelper'
import ServiceWorkerHelper from './helpers/ServiceWorkerHelper'
import PolicyUpdate from './components/PolicyUpdate/PolicyUpdate';

interface Props {
  dict: any
}

if(config.env === 'production')
{
  console.log = () => null
}

const App: React.FC<Props> = ({dict}) => {

  const [loadingToken, setLoadingToken] = useState(true)
  const [showUpdateDialog, setShowUpdateDialog] = useState(false)
  // const _menu = useRef(null)

  // const dict = props.dict['index']
  // console.log(dict)
  const lang = dict['index']

  const forceIOSUpdate= async()=>{
        //delete cache
        let getCacheKeys = await caches.keys()
        for(let eachCache of getCacheKeys){
          caches.delete(eachCache);
        }

        //unregister all detected service workers
        navigator.serviceWorker.getRegistrations().then(function (registrations) 
          {
            for (let registration of registrations) {
              registration.unregister()
            }
          alert(lang['doneUpdated']);
          setTimeout(() =>  {
            window.location.reload()
          },4000); //reload after 4 seconds to force re-register
        })
  }


  //try to sync login profile
  useEffect(()=>{
    // console.log(props)
    const checkXToken = async () =>{
        // console.log("Checking local token")
        if(!AuthenticationHelper.token){
          let tokenFromLocalStorage= await AuthenticationHelper.getStoredToken();
          if(tokenFromLocalStorage)
          { 
            //check the date
            let tokenRecordDate= tokenFromLocalStorage.setDate
            tokenRecordDate= new Date(tokenRecordDate)
            let currentDate = new Date()
            let timePassed = (currentDate.getTime() - tokenRecordDate.getTime())/1000
            // console.log(timePassed)

            if (timePassed < init.tokenRefreshTime )
            {
              // console.log("Found token in local storage, copying to session storage")
              AuthenticationHelper.setToken(tokenFromLocalStorage.xtoken) 
            }
            else
            {
              // console.log("Timed out, deleting token")
              await AuthenticationHelper.resetStoredToken(); // reset the stored token
            }
          }
        }
        setLoadingToken(false); //on loading, do nothing
    };

    const checkUpdateAvaliability = () =>{
      if(ServiceWorkerHelper.getUpdateStatus() )
      {
        ServiceWorkerHelper.setUpdateStatus(false)
        if(isPlatform('pwa'))
        {
          setShowUpdateDialog(true)
          if(isPlatform('ios'))
          {
            forceIOSUpdate(); // clear the cache and unregister the service workers and reload page
          }
        }
      }
    }

    checkXToken();
    setTimeout(checkUpdateAvaliability, 4000); //wait 4 seconds for installation of service worker to finish cecking


  },[]);



  return(
  loadingToken ? null: 
  <IonApp>
    <IonReactRouter>
    <TabBar >
      <IonRouterOutlet>

      <IonSplitPane contentId="main">
      {/*<TabBar>*/}
        <Menu />
        <IonRouterOutlet id='main'>
          <Route path={init.router.profile.url} component={Profiles} exact={true} />

          <Route path={init.router.login.url} component={Login} exact={true} />
          <Route path={init.router.register.url} component={Register} exact={true} />
          <Route path={init.router.forgotPass.url} component={ForgotPass} exact={true} />
          <Route path={init.router.profile.url} component={Profiles} exact={true} />
          <Route path={init.router.chat.url} component={Chat} exact={true} />
          <Route path={init.router.subscribe.url} component={Subscribe} exact={true} />
          <Route path={init.router.textbook.url} component={Textbook} exact={true} />
          <Route path={init.router.setting.url} component={Setting} exact={true} />
          <Route path={init.router.reserve.url} component={ReserveReading} exact={true} />
          <Route path={init.router.admin.url} component={AdminPage} exact={true} />
          <Route path={init.router.policy.url} component={Policy} exact={true} />
          <Route path={init.router.policyupdate.url} component={PolicyUpdate} exact={true} />
          <Route path={"/listeninglogin"} component={ListeningLocalStorage} />
          <Route path={init.router.backupChart.url} component={BackupData} exact={true} />
        </IonRouterOutlet>
      {/*</TabBar>*/}
      </IonSplitPane>
    </IonRouterOutlet>
    </TabBar>
    <Route path={init.router.chart.url} component={Chart} exact={true} />

    </IonReactRouter>
    <IonAlert
      isOpen={showUpdateDialog}
      onDidDismiss={() => setShowUpdateDialog(false)}
      header={lang['updateInstalled']}
      message={ isPlatform('ios')? lang['installingUpdate'] : lang['restartApp']}
      buttons={[lang['ok']]}
    />
  </IonApp>)
}

export default withPreloader(App);

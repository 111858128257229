import React, { useState, useEffect } from 'react'
import { IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonLabel, IonImg, IonIcon, IonBackButton, IonSearchbar, IonThumbnail } from '@ionic/react'
import withPreloader from '../withPreloader'
import { withRouter } from "react-router"
// import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
// import DiZhi from '../../json/DiZhi.json' // Zodiac sign
// import TianGan from '../../json/TianGan.json' // Celetial Stem
import ProfileFolder from './ProfileFolder'
import init from '../../config/init'
import lang from '../../lang/init'
import './Profile.css';
// import ChartGeneratorHelper from '../../helpers/ChartGeneratorHelper'
import ChartProfileHelper from '../../helpers/ChartProfileHelper'
import Modal from '../../assests/Modal'
import GenerateChart from './GenerateChart'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import moment from 'moment'

//test admin services 
import AdminServicesHelper from '../../helpers/AdminServicesHelper'

const Profiles = (props) => {
  const [searchText,setSearchText] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [toDeleteChartId, setToDeleteChartId] = useState('')
  const [chartData,setChartData] = useState([])
  const [modalActive, updateModalActive] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [editProfile, setEditProfile] = useState({})
  /* try loading the charts from the server */
  const dict = lang(props.preferences.lang)['profile']
  const parseChartResults = input => {
    //console.log(input)
    //if local chart is null object, set to empty array
    if((Object.keys(input).length === 0 && input.constructor === Object))
    {
      input=[]
    }
    const categories = [], profileDataArr = []
    input.forEach((item, idx) => {
      const localId = item.local_id
      // console.log(item.category)
      /** for users who do not log in, just hide the "self" chart */
      if(!props.isLoggedIn && item.category === init.categories[0]['value']) return
      if(!categories.includes(item.category))
      {
        const seq = init.categories.find(e => e.value === item.category).seq
        // console.log(seq)
        categories.push(item.category)
        const i = categories.indexOf(item.category)
        const key = item.category
        const title = dict.categories[key]
        profileDataArr[i] = {
          key: key,
          title: title,
          seq: seq,
          items: [{
            img: item.gender === 'Male' ? require('../../images/Gender/Male.svg'):require('../../images/Gender/Female.svg'),
            main: item.nickname,
            support: ChineseCalendarHelper.formatDateForChart(item.date_of_birth),
            handleClick: async () => {
              // console.log(item)
              props.history.push(`${init.router.chart.url}`, { chartData: item })
            },
            handleEdit: item.category === init.categories[0]['value'] ? () => props.history.push(`${init.router.setting.url}`) : async () => {
              await setEditProfile({
                uuid: item.local_id,
                edit: true,
                gender: item.gender === 'Male' ? 1:0,
                nickname: item.nickname,
                birthday: item.date_of_birth,
                category: item.category,
                solarTime: item.hasOwnProperty('is_solar') ? item.is_solar : false
              })
              updateModalActive(true)
            },
            handleDelete: () => { // allow user to delete "me" chart
                // console.log('handle delete called')
                // ChartProfileHelper.removeLocalChartListData(localId)
                // removeLocalProfileChart(localId)
                setShowAlert(true)
                setToDeleteChartId(localId)
              }
            // handleDelete: item.category === init.categories[0]['value'] ? null:() => {
            //   // console.log('handle delete called')
            //   // ChartProfileHelper.removeLocalChartListData(localId)
            //   // removeLocalProfileChart(localId)
            //   setShowAlert(true)
            //   setToDeleteChartId(localId)
            // }
          }]
        }
      }
      else
      {
        const i = categories.indexOf(item.category)
        profileDataArr[i]['items'].push({
          img: item.gender === 'Male' ? require('../../images/Gender/Male.svg'):require('../../images/Gender/Female.svg'),
          main: item.nickname,
          support: ChineseCalendarHelper.formatDateForChart(item.date_of_birth),
          handleClick: async () => {
            // console.log(item)
            props.history.push(`${init.router.chart.url}`, { chartData: item })
          },
          handleEdit: item.category === init.categories[0]['value'] ? () => props.history.push(`${init.router.setting.url}`) : async () => {
            await setEditProfile({
              uuid: item.local_id,
              edit: true,
              gender: item.gender === 'Male' ? 1:0,
              nickname: item.nickname,
              birthday: item.date_of_birth,
              category: item.category,
              solarTime: item.hasOwnProperty('is_solar') ? item.is_solar : false
            })
            updateModalActive(true)
          },
          handleDelete: item.category === init.categories[0]['value'] ? null:() => {
            // console.log('handle delete called')
            // ChartProfileHelper.removeLocalChartListData(localId)
            // removeLocalProfileChart(localId)
            setShowAlert(true)
            setToDeleteChartId(localId)
          }
        })
      }
    })
    profileDataArr.sort((a, b) => a.seq - b.seq)
    // console.log(profileDataArr)
    return profileDataArr
  }

  const getLocalProfileData = async () => {
    //get chart from local storage
    let chartResults= await ChartProfileHelper.getLocalChartListData()
    // console.log(chartResults)
    const profileInput = parseChartResults(chartResults)
    setProfileData(profileInput)
  }

  const removeLocalProfileChart = async (localId) => {
    await ChartProfileHelper.removeLocalChartListData(localId)
    let chartResults= await ChartProfileHelper.getLocalChartListData()
    const profileInput = parseChartResults(chartResults)
    setProfileData(profileInput)
  }

  const testGetChartList = async () =>{
     // pull the data from online to local
    //await ChartProfileHelper.pullChartListData('65f6c37d-8e8b-41de-942c-72e463709b6e')
    // await ChartProfileHelper.getLocalChartListData()
    // console.log("getting chart list")

     //get chart from local storage
     //let chartResults= await ChartProfileHelper.getLocalChartListData()
     //console.log(chartResults)
     //const profileInput = parseChartResults(chartResults)
     //setProfileData(profileInput)
    
  }

  const testPutChartList = async ()=>{

    //test putting a new data in the local
    //console.log("putting chart list")
    //put null if user did not sign in, else put in user_id
    //let putChartResults= await ChartProfileHelper.addLocalChartList(null,"family2","1980-04-10 07:50:00","Female","13.7367","100.5231","test","parent");
    //console.log(putChartResults)

    //test pushing to server
    //console.log("pushing chart list to server")
    //await ChartProfileHelper.pushChartListData('65f6c37d-8e8b-41de-942c-72e463709b6e')
    //console.log(await ChartProfileHelper.removeLocalChartListData("12a8a517-47e9-4b2c-8c52-ea60d9c8889c"))
  }

  const handleAddChart = async () => {
    let chartResults= await ChartProfileHelper.getLocalChartListData()
    // console.log(chartResults)
    const profileInput = parseChartResults(chartResults)
    setProfileData(profileInput)
    updateModalActive(false)
  }

  const handleEditChart = async (uuid) => {
    await setEditProfile({})
    await removeLocalProfileChart(uuid)
    // await handleAddChart()
  }

  useEffect(()=>{
      getLocalProfileData()

      const testAdmin=async()=>{
        //note, the userid is an id with admin previledges, you also need to sign in first
        let response= await AdminServicesHelper.setTrialTime("a874252b-7798-4557-8c30-3e490ac0d3fb","50000") 
        console.log("Testing admin changing trial time")
        console.log(response)
        let responseMemstate=await AdminServicesHelper.setMemberState("a874252b-7798-4557-8c30-3e490ac0d3fb","test","0")
        console.log("Testing admin changing mem state")
        console.log(responseMemstate)
      }

      //TO LAVENDER: UNCOMMENT OUT TO TEST ADMIN FUNCTIONS
      //testAdmin()

      // getChart()
      

      //test the get chart 
      //testGetChartList();

      //test the put chart
      //testPutChartList();
  },[props.location]); //NOTE: YOU NEED TO PUT IN props.location else it will not run useeffect when the router changes, now useeffect will run when props.location changes 
  
  // console.log(profileData)

  let filteredData=[]
  
  // process the searchText
  if (profileData === null || profileData.length === 0 || !searchText )
  {
    filteredData=[...profileData]
  }
  else
  {
    profileData.forEach((category)=>{
      if("items" in category){
        let tempItemArray=[]
        category.items.forEach((profile)=>{
          if (profile.main.toLowerCase().startsWith(searchText) || profile.main.toLowerCase().startsWith(searchText.toLowerCase())){
              tempItemArray.push(profile)
          }
        })
        if(tempItemArray != null && tempItemArray.length > 0) 
        {
          let tempCategoryObj={...category}
          tempCategoryObj.items=tempItemArray
          filteredData.push(tempCategoryObj)
        }
      }
    })
    //console.log(profileData)
    //console.log(filteredData)
  }

  


  return (<IonPage>
      <IonHeader className="bitmap-texture">
        <IonToolbar>
          {/*<IonButtons slot="start">
            <IonBackButton />
          </IonButtons>*/}
          <IonTitle className='ion-font-title'>{dict.title}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value)}></IonSearchbar>
          <IonButtons slot="end">
            <IonThumbnail slot="start" onClick={() => updateModalActive(true)} style={{ width: '25px', height: '25px' }}>
              <IonImg src={require('../../images/Header/Edit.svg')} />
            </IonThumbnail>
            {/*<IonIcon src={require('../../images/Header/Edit.svg')} slot="start" onClick={() => updateModalActive(true)} />*/}
          </IonButtons>  
        </IonToolbar>
      </IonHeader>

      <IonContent className='profile-folder-container'>
        <ProfileFolder data={filteredData} {...props} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={dict['attention']}
          subHeader={''}
          message={dict['confirm']}
          buttons={[
            {
              text: dict['cancel'],
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: dict['ok'],
              handler: () => {
                if(toDeleteChartId !== '')
                {
                  removeLocalProfileChart(toDeleteChartId)
                }
                return
              }
            }
          ]}
        />
        {/*<div className='profile-container-background-color'></div>*/}
        <div className='profile-background-img' style={{ backgroundImage: `url(${require('../../images/Logo.svg')})` }}></div>
        <div className='profile-background-notice' onClick={() => updateModalActive(true)} ><p>按右上角Icon <IonIcon src={require('../../images/Header/Edit.svg')} /> 可以新增命盤</p></div>
        {/*<IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">命盤用戶</IonTitle>
          </IonToolbar>
        </IonHeader>*/}
        {/*<ChartContainer chartData={chartData} />*/}
      </IonContent>
      <Modal open={modalActive} handleClose={() => updateModalActive(false)}>
        <GenerateChart handleAddChart={handleAddChart} handleEditChart={handleEditChart} handleClose={() => updateModalActive(false)} {...editProfile} dict={dict} />
      </Modal>
    </IonPage>)

}

export default withRouter(withPreloader(Profiles))

import React,{useState,useEffect}  from 'react'
import withPreloader from '../withPreloader'
import { withRouter } from "react-router"
import { useLocation, useHistory  } from "react-router-dom";
import AuthContainer from './AuthContainer'
import { 
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    IonThumbnail,
    IonImg,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonSpinner
} from '@ionic/react'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import init from '../../config/init'
import lang from '../../lang/init'

import './Login.css';
import { URLSearchParams } from 'url';





const Login = props => {
    const location = useLocation();
    const history = useHistory();
    //console.log("Para" ,location);

    const searhParams = location.search;
    const userID = searhParams.slice(1,searhParams.length);
    //console.log("Para HA" ,userID)


    const [isLoggedIn, updateLoginState] = useState(AuthenticationHelper.hasAuthenticated())
     useEffect(()=>{
	const tryLogin = async() =>{
		console.log( "Params" , userID);
	    const result = AuthenticationHelper.login_byToken(userID);

	    AuthenticationHelper.putAccDetails("sam77089099")
		const loggedIn = AuthenticationHelper.checkTokenExpiry()
	
		console.log("Para3-1 is login ?",loggedIn,result)
		console.log("Para3 ",AuthenticationHelper.uuid)
	        const getRole = UserProfileHelper.getRoleById(AuthenticationHelper.uuid)
		console.log("Para4 ",getRole)
		
		const loggedInn = AuthenticationHelper.checkTokenExpiry()
		console.log("Para3-2 is login ?",loggedInn)
		console.log("Para55 is login ?",isLoggedIn)
		//history.push("/chart")
		props.history.push('/')
	}
	
	UserProfileHelper.autoLogout()
	if (!isLoggedIn) {
        	//console.log("Para Try Login by token")
		tryLogin()
	} else {
        	AuthenticationHelper.resetToken()
	        AuthenticationHelper.resetStoredToken()//also remove localstorage token		
		tryLogin()
	}

	//console.log("Para5 is login ?",isLoggedIn)
    	//console.log("Para5 is login ?",props.location)
	//return <Navigate replace to="/login" />;
	//useNavigate("/");
	//history.push("/login")
    },[]); //NOTE: YOU NEED TO PUT IN props.location else it will not run useeffect when the router changes, now useeffect will run when props.location changes 

    return(
        <IonPage>
                        <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <p>HI</p>;
	            <p>{userID}</p>;
            </IonContent>            

        </IonPage>
    )
}


export default withRouter(Login)

import React from 'react'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'
import { IonTitle, IonButton, IonRow, IonCol, IonImg } from '@ionic/react'
import lang from '../../lang/init'
import init from '../../config/init'

const LoginFirst = props => 
{
    // console.log(props)
    const dict = lang(props.preferences.lang)['menu']
    return (
        <React.Fragment>
        <IonRow style={{ margin: 'auto', marginTop: '2em' }} >
        <IonCol>
        <IonTitle className='error-font'>{dict['memberOnly']}</IonTitle>
        </IonCol>
        </IonRow>
        
        <IonRow>
        <IonCol>
          <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
          <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['loginFirst']}</p>
          {/*<IonButton color="zprimary" expand="block" onClick={() => props.history.push(init.router.login.url)}>{dict['yesLogin']}</IonButton>*/}
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
            <IonButton color="zprimary" expand="block" style={{ margin: 'auto', marginTop: '2em' }} onClick={() => props.history.push(init.router.login.url)}>{dict['yesLogin']}</IonButton>
        </IonCol>
        </IonRow>
            {/*<IonRow>
                <IonCol>
                    <IonImg src={require('../../images/Square-logo.svg')} style={{ minWidth: '120px', maxWidth: '300px', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
                </IonCol>
            </IonRow>*/}
            {/*<IonRow>
                <IonCol>
                    <p>{dict['loginFirst']}</p>
                    <IonButton color="zprimary" expand="block" onClick={() => props.history.push(init.router.login.url)}>{dict['yesLogin']}</IonButton>
                </IonCol>
            </IonRow>*/}
        </React.Fragment>
    )
}

export default withRouter(withPreloader(LoginFirst))
import React,{useState,useEffect}  from 'react'
import withPreloader from '../withPreloader'
import { withRouter } from "react-router"
import AuthContainer from './AuthContainer'
import { 
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    IonThumbnail,
    IonImg,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonSpinner
} from '@ionic/react'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import init from '../../config/init'
import lang from '../../lang/init'

import './Login.css';

const Login = props => {

    const [isLoggedIn, updateLoginState] = useState(AuthenticationHelper.hasAuthenticated())
    // const [isLoggedIn, updateLoginState] = useState(true)
    // const [loading, updateLoading] = useState(false)
    const dict = lang(props.preferences.lang)['auth']['loginPage']
    const [storedUsername, updateStoredUsername] = useState('')

    useEffect(()=>{
        const tryLogin = async () =>{
            let loginResults= await AuthenticationHelper.login("test2","test2")
            // console.log(loginResults)
        };
        
        const tryRegister = async()=>{
            let registerResults= await UserProfileHelper.registerUser( {
                "username": "test6",
                "password": "test6", 
                "displayName": "test6",
                "email": "test6@email.com"
            })
            // console.log(registerResults)
        }

        //try to access username if it exists
        const getAccountDetails = async ()=>{
            // console.log("Checking for account details")
            let accFromLocalStorage = await AuthenticationHelper.getAccDetails();
            if (accFromLocalStorage)
            {
                updateStoredUsername(accFromLocalStorage.username);
            }
        }

        getAccountDetails();
        //tryLogin();
        //tryRegister();
        
    },[props.location]); //NOTE: YOU NEED TO PUT IN props.location else it will not run useeffect when the router changes, now useeffect will run when props.location changes 

    return(
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <div className='logo-container'>
                <img className='square-logo' src={require('../../images/Square-logo.svg')} />
            </div>

            {isLoggedIn?  <div style={{textAlign: "center", width:"100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <p>{dict['loggedIn']}</p>
                <IonSpinner name='lines' />
            </div>:
                <AuthContainer type="login" >
                    <InputSection {...props} updateLoginState={updateLoginState} storedUsername={storedUsername} />
                    <IonRow>
                    <IonCol className='ion-text-end'>
                        <p onClick={() => props.history.push(init.router.register.url)}>{dict['notYetMember']}<span className='primary-text cursor-pointer'>{dict['clickRegister']}</span></p>
                    </IonCol>
                    </IonRow>
                </AuthContainer>
            }

            </IonContent>
        </IonPage>
    )
}

const InputSection = props => {
    const [username, updateUsername] = useState(props.storedUsername) // useState('')
    const [password, updatePassword] = useState('')
    const [invisible, updateInvisible] = useState(true)
    const [loading, updateLoading] = useState(false)
    const [loginFeedback, updateLoginFeedback] = useState('')

    const dict = lang(props.preferences.lang)['auth']['loginPage']

    const submitLogin = async () => {
        updateLoading(true)
        updateLoginFeedback('')
        const loginResult = await AuthenticationHelper.login(username.trim(), password.trim())
        if(!loginResult)
        {
            
            updateLoading(false)
            // display feedback
            updateLoginFeedback(dict['loginFailed'])
            return
        }
        // console.log(loginResult)
        updateLoading(false)
        props.updateLoginState(loginResult)
        //store login credentials in local storage
        await AuthenticationHelper.putAccDetails(username)
        setTimeout(() => {
            window.location.replace(init.router.profile.url)
        }, 3000)
    }

    return (<IonGrid>

        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel position="floating">{dict['email']}</IonLabel>
                <IonInput required value={username} onIonChange={e => updateUsername(e.detail.value)} clearInput autocomplete="username"></IonInput>
            </IonItem>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel position="floating">{dict['password']}</IonLabel>
                <IonInput required type={invisible ? 'password' : 'text'} value={password} onIonChange={e => updatePassword(e.detail.value)} clearInput autocomplete="current-password"></IonInput>
                <IonThumbnail className='eye-icon' slot="end" onClick={() => updateInvisible(!invisible)} >
                    <IonImg src={invisible ? require('../../images/Icon/Close-eye.svg') : require('../../images/Icon/Open-eye.svg')} />
                </IonThumbnail>
                {/*<IonIcon ></IonIcon>*/}
            </IonItem>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol className='ion-text-end cursor-pointer'>
            <p onClick={() => {
                // console.log(`forgot passs ${init.router.forgotPass.url}`)
                props.history.push(init.router.forgotPass.url)
            }}>{dict['forgotPass']}</p>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
        <IonButton color="zprimary" expand="block" type="submit" onClick={submitLogin}>{loading ? <IonSpinner /> : dict['login']}</IonButton>
        </IonCol>
        </IonRow>
        
        <IonRow>
        <IonCol>
            <p>{loginFeedback}</p>
        </IonCol>
        </IonRow>
        <IonLoading
          backdropDismiss
          // cssClass='my-custom-class'
          isOpen={loading}
          message={dict['loading']}
        />

    </IonGrid>)
}

export default withRouter(withPreloader(Login))
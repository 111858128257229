import React, { useState, useEffect } from 'react'
import withPreloader from '../withPreloader'
import { withRouter } from "react-router"
import { IonTitle, IonContent, IonLoading, IonRow, IonPage, IonCol, IonToolbar, IonButtons, IonMenuButton, IonButton, IonIcon, IonBackButton, IonSearchbar, IonImg, getPlatforms } from '@ionic/react'
import ExploreContainer from '../../components/ExploreContainer'
import ScreenHelper from '../../helpers/ScreenHelper'
import ChartContainer from './ChartContainer'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import Header from '../../assests/Header'
import NaYinFiveElements from '../../json/NaYinFiveElements.json'
import DiZhi from '../../json/DiZhi.json' // Zodiac sign
import TianGan from '../../json/TianGan.json' // Celetial Stem
import init from '../../config/init'
import moment from 'moment'
import lang from '../../lang/init'

import './Profile.css';
import ChartGeneratorHelper from '../../helpers/ChartGeneratorHelper'
import ChartProfileHelper from '../../helpers/ChartProfileHelper'

const defaultChart = {
  nickname: '隨機起盤',
  date_of_birth: new Date()
}
let chartHourOffset = 0


/*
Test the chinese calendar converting function
ChineseCalendarHelper.testl2s(1998, 5, 24, true)
const s = ChineseCalendarHelper.getSolarCalendar(1998, 5,24,true)
*/

// const s = ChineseCalendarHelper.test(1987, 9, 19)
// const s = ChineseCalendarHelper.test(2028, 7,22) // "己未"
// const s = ChineseCalendarHelper.test(1987, 10,15)
// console.log(s)
const Chart = props => {
  // const [searchText,setSearchText] = useState('')
  const [chartTitle, updateChartTitle] = useState('命盤')
  const [chartData, setChartData] = useState([])
  const [loading, updateLoading] = useState(true)
  const [failedToLoad, updateFailedToLoad] = useState(false)
  const [portrait, updateOrientation] = useState(true)
  // const [chartHourOffset,setChartHourOffset] = useState(0) 
  /* try loading the charts from the server */
  const dict = lang(props.preferences.lang)['chart']

  //to adjust the zodiac charts
  const getNewZodiacOffset = (offsetHours) => {
    let newHours = offsetHours
    if (offsetHours >= 24) {
      newHours = offsetHours - 24
    }
    else if (offsetHours < 0) {
      newHours = 24 + offsetHours
      console.log( "PHH" , newHours, offsetHours)
    }
    return newHours
  }

  const changeHourOffset = async (offsetHours) => {
    const newOffset = parseInt(chartHourOffset, 10) + parseInt(offsetHours, 10)
    const parsedOffset = getNewZodiacOffset(newOffset)
    chartHourOffset += offsetHours
    console.log( "PHH(changeHouroffset)-aft : " , offsetHours , chartHourOffset)
    getChart()
  }

  const getChart = async () => {
    try {
      // console.log('get chart')
      const chartObj = props.location.hasOwnProperty('state') && props.location.state !== undefined ? props.location.state['chartData'] : defaultChart
      // console.log(chartObj)
      // const chartObj = props.location.state['chartData']
      // console.log(chartData) 
      // console.log(new Date(chartData.date_of_birth))
      // console.log(`input date ${chartObj.date_of_birth}`)
      const formatDate = ChineseCalendarHelper.formatDateForChart(chartObj.date_of_birth)
      // console.log(`format data ${formatDate}`)
      let today = chartObj.date_of_birth !== null && chartObj.date_of_birth !== undefined ? ChineseCalendarHelper.formatDateDisplay(chartObj.date_of_birth) : ChineseCalendarHelper.formatDateDisplay()
      // console.log(today)
      //let hh = getNewZodiacOffset(moment(today).hour() + chartHourOffset) //add the chart offset hours
      let hh = moment(today).hour() + chartHourOffset //add the chart offset hours
      let mm = moment(today).minute()
      // console.log(`the hour ${hh} and minutes ${mm}`)
      // if the hour and minutes are after 23, then move one more day
	    //
	    //
        console.log(`PHH issue: old today HH: ${hh} MM: ${mm} ChartOffset:${chartHourOffset}`)
	console.log(`PHH issue: old today ${moment(today)}`)
	if( hh >= 24 ) {
	        today = ChineseCalendarHelper.formatDateDisplay(moment(today).add(chartHourOffset, 'hours'))
	        hh = getNewZodiacOffset(moment(today).hour()) //add the chart offset hours
	        mm = moment(today).minute()
        	console.log(`PHH issue: the new hour ${hh} and minutes ${mm}`)
	        console.log(`PHH issue: new today ${moment(today)}`)          
	} else if ( hh < 0 ){
        	today = ChineseCalendarHelper.formatDateDisplay(moment(today).add(chartHourOffset, 'hours'))
	        hh = getNewZodiacOffset(moment(today).hour()) //add the chart offset hours
	        mm = moment(today).minute()
        	console.log(`PHH issue: the new hour ${hh} and minutes ${mm}`)
	        console.log(`PHH issue: new today ${moment(today)}`)
	} else {


	}


//      if(hh > 23 && mm >= 1)
//      {
//        console.log(`PHH issue: old today ${moment(today).add(chartHourOffset, 'hours')}`)
//        console.log(`PHH issue: old today HH: ${hh} MM: ${mm}`)
//        today = ChineseCalendarHelper.formatDateDisplay(moment(today).add(chartHourOffset+1, 'hours'))
//        console.log(`PHH issue: new today ${today}`)
//        hh = getNewZodiacOffset(moment(today).hour()) //add the chart offset hours
//        mm = moment(today).minute()
//        console.log(`PHH issue: the new hour ${hh} and minutes ${mm}`)
//      }

      const gender = chartObj.gender !== null && chartObj.gender !== undefined ? chartObj.gender : "Female"
      console.log("the today",today)
      const date = ChineseCalendarHelper.getLunarCalendar(moment(today).year(), moment(today).month() + 1, moment(today).date())
      console.log(`lunar calendar date `, date)
      // const date = ChineseCalendarHelper.getLunarCalendar(today.getFullYear(),today.getMonth() + 1, today.getDate())
      if (Object.keys(date).length <= 0 || !date.hasOwnProperty('lYear') || !date.hasOwnProperty('lMonth') || !date.hasOwnProperty('lDay')) {
        return null
      }
      // console.log('check point 1')
      /*year, month, day, hour, mins, sec, lat, lng */
      // console.log(date)
      // const d = ChineseCalendarHelper.getLunarCalendar(1978,4,5)
      // console.log()
      const getTianGan = parseInt(date.lYear, 10) % 10
      const TG = TianGan[getTianGan].mapStart
      const arr1 = TianGan.slice(TG, TianGan.length)
      const arr2 = TianGan.slice(0, TianGan.length - arr1.length + 2)
      const arr3 = arr1.concat(arr2)

      const monthDZ = ChineseCalendarHelper.convertDiZhiToId(date['gzMonth'][1])
      // console.log(`month dizhi is ${monthDZ}`)
      //let chartResult = await ChartGeneratorHelper.getChartList(parseInt(date.lYear,10), parseInt(date.lMonth,10), parseInt(date.lDay, 10), 17, 27, 0, 23, 120,gender,false)
      const lunarMonth = date.isLeap && date.lDay > 15 ? parseInt(date.lMonth, 10) + 1 : parseInt(date.lMonth, 10)
      //in the end, add a flag to see if it is solar time
      const is_solar_time = chartObj.hasOwnProperty('is_solar') && chartObj['is_solar'] !== null && chartObj['is_solar'] !== undefined ? chartObj['is_solar'] : false
      let chartResult = await ChartGeneratorHelper.getChartListFromTimeZone(parseInt(date.lYear, 10), lunarMonth, parseInt(date.lDay, 10), hh, mm, 0, "Asia/Taipei", gender, is_solar_time, monthDZ)
      // console.log(chartResult)
      if (!chartResult.hasOwnProperty('data')) {
        throw Error(false)
      }
      /*
      let chartData = [], lifeHouseElement = ''

      chartResult.data.blockData.forEach((d, idx) => {
        chartData[idx] = {...d}
        chartData[idx]['zodiac_id'] = DiZhi[idx].id
        chartData[idx]['celetial_id'] = arr3[idx].id // TianGan
        chartData[idx]['elements'] = `${arr3[idx].id}${DiZhi[idx].id}`
        if(d.palace_str === 'Life')
        {
          lifeHouseElement = `${arr3[idx].id}${DiZhi[idx].id}`
        }
      })
      console.log(chartData)
      */

      //console.log(`life house element is ${lifeHouseElement}`)
      //console.log(NaYinFiveElements[lifeHouseElement])

      setChartData(chartResult.data)
      updateLoading(false)
    }
    catch (e) {
      //  console.log(e)
      updateLoading(false)
      updateFailedToLoad(true)
    }
  };

  const setPortraitDetector = async () => {
    let isPortrait = ScreenHelper.isPortrait()
    updateOrientation(isPortrait)
    // detect if the screen is portrait and display warnings
    ScreenHelper.detectScreenRotate(() => {
      isPortrait = ScreenHelper.isPortrait()
      updateOrientation(isPortrait)
      // console.log(`the orientation now is portrait ${isPortrait}`)
    })
  }


  useEffect(() => {
    getChart()
    chartHourOffset = 0
    setPortraitDetector()

    //test the get chart 
    //testGetChartList();

    //test the put chart
    //testPutChartList();

  }, [props.location]); //NOTE: YOU NEED TO PUT IN props.location else it will not run useeffect when the router changes, now useeffect will run when props.location changes 
  //[props.location]
  // if(!props.location.hasOwnProperty('state') || props.location.state === undefined) return null
  // console.log(props.location.hasOwnProperty('state'))
  // console.log(props.location.state)
  const chartObj = props.location.hasOwnProperty('state') && props.location.state !== undefined ? props.location.state['chartData'] : defaultChart
  //console.log(chartObj)

  // getChart()
  // console.log(`failed to load ${failedToLoad}`)
  // console.log(`is portrait ${portrait}`)
  // console.log(`is blocked platform ${ScreenHelper.blockRotatePlatforms()}`)
  if (!portrait && ScreenHelper.blockRotatePlatforms()) {

    // only allow portrait view
    return (<IonPage>
      <Header
        title={chartTitle}
        leftComponent={<IonButtons slot="start" onClick={e => props.history.push(init.router.profile['url'])}>
          <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
        </IonButtons>}
      />

      {loading ?
        <IonContent>

          <IonLoading
            backdropDismiss
            // cssClass='my-custom-class'
            isOpen={loading}
            message={dict['loading']}
          />

        </IonContent>
        : <IonContent>

          <IonRow>
            <IonCol>
              {<IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />}
            </IonCol>
            <IonCol>
            <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['notPortrait']}</p>
            <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['pleaseRotate']}</p>
            </IonCol>
          </IonRow>

        </IonContent>}

    </IonPage>)


  }

  return (
    <IonPage style={{ zIndex: 10 }}>
      <Header
        title={chartTitle}
        leftComponent={<IonButtons slot="start" onClick={e => props.history.push(init.router.profile['url'])}>
          <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
        </IonButtons>}
      />

      {loading ?
        <IonContent>

          <IonLoading
            backdropDismiss
            // cssClass='my-custom-class'
            isOpen={loading}
            message={dict['loading']}
          />

        </IonContent>
        : null}
      {/*<IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">命盤用戶</IonTitle>
        </IonToolbar>
      </IonHeader>*/}
      {failedToLoad && !loading ?
        <IonContent>

          <IonRow style={{ margin: 'auto', marginTop: '2em' }} >
            <IonCol>
              <IonTitle className='error-font'>{dict['oops']}</IonTitle>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
              <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['failToLoad']}</p>
              <p className='error-font' style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['ifConnected']}</p>
            </IonCol>
          </IonRow>

          {/*<IonRow>
          <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
            <IonButton color="zprimary" expand="block" style={{ margin: 'auto', marginTop: '2em' }}>{dict['report']}</IonButton>
          </IonCol>
          </IonRow>*/}
        </IonContent>

        :
        <ChartContainer
          chartData={chartData}
          profile={chartObj || defaultChart}
          setChartHourOffset={changeHourOffset}
          currentOffset={chartHourOffset}
          {...props}
        />
      }

    </IonPage>
  )
}

export default withRouter(withPreloader(Chart))

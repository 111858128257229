import React, { useState,useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2';
import init from '../../config/init'

const SUBSCRIPTION_ID= init.iap.year

const InAppPayLayer= (props) =>{

  //TODO need to configure on python server
  InAppPurchase2.validator ="http://127.0.0.1:5000/check-purchase"

  const [productObj, updateProductObj] = useState([])
  

  const registerSubscription= ()=>{
      console.log("Before")
      InAppPurchase2.register({
        id: SUBSCRIPTION_ID,
        type: InAppPurchase2.PAID_SUBSCRIPTION
      });
      InAppPurchase2.refresh();  
      addPurchaseEvents();
  }

  const addPurchaseEvents = ()=>{
    if(isPlatform('android') || isPlatform('ios')) {
      //event when approved
        InAppPurchase2.when(SUBSCRIPTION_ID).approved(function(product) {
          product.verify();
      });

      //event when verified
      InAppPurchase2.when(SUBSCRIPTION_ID).verified(function(product) {
        product.finish();
      });

      //when project has updated
      InAppPurchase2.when(SUBSCRIPTION_ID)
      .updated((product) => {
        if (product.owned){
            //purchase successful, status would change to ownded automatically
        }
        
      });
    }
  }

  const purchaseSubscription = ()=>{
    try{
      //InAppPurchase2.order(SUBSCRIPTION_ID)
    }
    catch (error) {
      console.log(error)
    }
  }



  //SENT OBJECT 
  /* 
  {
  additionalData : null
  alias : "monthly1"
  currency : "USD"
  description : "Monthly subscription"
  id : "subscription.monthly"
  loaded : true
  price : "$12.99"
  priceMicros : 12990000
  state : "approved"
  title : "The Monthly Subscription Title"
  transaction : { // Additional fields based on store type (see "transactions" below)  }
  type : "paid subscription"
  valid : true
}
  */

  /* WHEN SUCCESSFUL
  {
    ok : true,
    data : {
        transaction : { // Additional fields based on store type (see "transactions" below) }
    }
  }
  */

   /* WHEN ERROR
    none 200 code
   */

  useEffect(()=>{
    registerSubscription();
    let newProductObj= InAppPurchase2.get(SUBSCRIPTION_ID);
    updateProductObj(newProductObj)
  },[props.location]);
  
  let productLayer=""
  if(!productObj || productObj.state === InAppPurchase2.INVALID)
  {
    productLayer=<div> product does not exist </div>
  }
  else if(productObj.state === InAppPurchase2.REGISTERED || !productObj){
    productLayer=<div> Loading </div>
  }
  else if(productObj.owned)
  {
    productLayer=<div>Already Purchased</div>
  }
  else{
    productLayer=<div>
      <p>{productObj.title}</p>
      <p>{productObj.description}</p>
    </div>
  }

  return (
      <div> {productLayer}  </div>
  )

}

export default InAppPayLayer;
import React, { useRef } from 'react';
import { IonFabList, IonFab, IonFabButton, IonThumbnail, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton } from '@ionic/react';
import { star, arrowUpCircle } from 'ionicons/icons';
import './Asset.css'
import Palaces from '../json/Palaces.json'

// <IndexedCards cardsData={data} />
const IndexedCards = props => {
    let cards = [], index = [], toAdd = ['命宮', '財帛', '遷移', '官祿', '夫妻', '福德']
    
    props.cardsData.forEach((card, idx) => {
        cards.push(<CardUnit {...card} />)
        if(toAdd.includes(card.title))
        {
            index.push(
                <IonFabButton key={`fab-${idx}`} onClick={e => {

                    const item = document.getElementById(`${card.id}-wrapper`);
			//return props.onSelect(item)
		    return item.scrollIntoView({ behavior:'smooth'});
                }} >
                    <p>{card.title[0]}</p>
                </IonFabButton>
            )
        }

    })

    return <React.Fragment>
        <IonFab horizontal="end" vertical="top"  slot="fixed" className='main-house-fab' >
            <IonFabButton className='main-house-button'>
                <IonIcon icon={star} />
            </IonFabButton>
            <IonFabList side="bottom">
                {index}
            </IonFabList>
        </IonFab>
        
        {cards}
    </React.Fragment>
}

const CardUnit = props =>
{
    const supportArr = []
    let titleStr = ''
    if(props.main !== '' && typeof props.main === 'string')
    {
        //console.log(props.support)
        const mainContent = props.main

        const mainContentArr = mainContent.split(/\\n/gm)
        
        // mainContentArr.forEach((text, idx) => {
        //     titleArr.push(<IonCardSubtitle key={`main-content-${props.id}-${idx}`}>{text}</IonCardSubtitle>)
        // })
        titleStr = mainContentArr.join(', ')
    }
    if(props.support !== '' && typeof props.support === 'string')
    {
        //console.log(props.support)
        const supportContent = props.support

        const content = supportContent.split(/\\n/gm)

        content.forEach((text, idx) => {
            supportArr.push(<p key={`support-content-${props.id}-${idx}`}>{text}</p>)
        })
    }
    else if(props.support !== undefined && props.support !== null && typeof props.support === 'object')
    {
        supportArr.push(<React.Fragment key={`support-content-${props.id}`}>{props.support}</React.Fragment>)
    }
    // console.log(supportArr)
    return (<IonCard id={`${props.id}-wrapper`} className='palace-content-wrapper'>
        <IonCardHeader>
            {props.hasOwnProperty("extra") && props.extra !== '' ? <IonCardSubtitle>{`${props.extra}`}</IonCardSubtitle>:null}
            {/*<IonCardSubtitle>{`${props.main}`}</IonCardSubtitle>*/}
            <IonCardSubtitle>{titleStr}</IonCardSubtitle>
            <IonCardTitle>{props.title}</IonCardTitle>
            {props.extraComponent}
        </IonCardHeader>

        <IonCardContent>
            {supportArr}
        </IonCardContent>
    </IonCard>)
}

export default IndexedCards

import React,{useState,useEffect}  from 'react';
import AuthContainer from './AuthContainer'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'

import { 
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonSpinner,
    IonItem,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading
} from '@ionic/react'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import lang from '../../lang/init'
import './Login.css';

const ForgotPass = props => {

    const dict = lang(props.preferences.lang)['auth']['forgotPassPage']

    useEffect(()=>{
        // const tryLogin = async () =>{
        //     let loginResults= await AuthenticationHelper.login("test2","test2")
        //     console.log(loginResults)
        // };

        // const tryRegister = async()=>{
        //     let registerResults= await UserProfileHelper.registerUser( {
        //         "username": "test6",
        //         "password": "test6", 
        //         "displayName": "test6",
        //         "email": "test6@email.com"
        //     })
        //     console.log(registerResults)
        // }

        // tryLogin();
        //tryRegister();
        
    },[]);

    return(
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <div className='logo-container'>
                <img className='square-logo' src={require('../../images/Square-logo.svg')} />
            </div>
            {/*<IonGrid className='logo-container ion-justify-content-center'>
                <IonCol></IonCol>
            </IonGrid>*/}
                <AuthContainer type="forgotPass" >
                    <InputSection {...props} dict={dict} />
                    <IonRow>
                    <IonCol className='ion-text-end  cursor-pointer'>
                        <p onClick={() => props.history.push('/login')}>{dict['backToLogin']}</p>
                    </IonCol>
                    </IonRow>
                </AuthContainer>
            </IonContent>
        </IonPage>

    )
}

const InputSection = props => {
    const [username, updateUsername] = useState('')
    const [loading, updateLoading] = useState(false)
    const [retrieveFeedback, updateRetrieveFeedback] = useState('')
    const dict = props.dict
    return (<IonGrid>
        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel position="floating">{dict['enterEmail']}</IonLabel>
                <IonInput required value={username} onIonChange={e => updateUsername(e.detail.value)} clearInput></IonInput>
            </IonItem>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
        <IonButton color="zprimary" expand="block" onClick={async () => {
            try
            {
                const sendEmail = await UserProfileHelper.sendresetPasswordEmail(username.trim())
                // console.log(sendEmail)
                if(sendEmail.hasOwnProperty('status') && sendEmail.status === 'success')
                {
                    return updateRetrieveFeedback(dict['emailSent'])
                }
                throw Error(false)
            }
            catch(e)
            {
                // console.log(e)
                return updateRetrieveFeedback(dict['failed'])
            }
            // const loginResult = await AuthenticationHelper.login(username, password)
            // console.log(loginResult)
        }}>{loading ? <IonSpinner /> : dict['getTempPass']}</IonButton>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
            <p>{retrieveFeedback}</p>
        </IonCol>
        </IonRow>
        <IonLoading
          backdropDismiss
          // cssClass='my-custom-class'
          isOpen={loading}
          message={dict['loading']}
        />
    </IonGrid>)
}

export default withRouter(withPreloader(ForgotPass))
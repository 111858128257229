import React,{useState,useEffect, useRef} from 'react';
import { IonGrid, IonThumbnail, IonImg, IonLoading, IonFooter, IonToolbar, IonContent } from '@ionic/react'
import './ChartContainer.css';
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import Palaces from '../../json/Palaces.json' // Zodiac sign
import DecadePalace from '../../json/DecadePalace.json'
import YearPalace from '../../json/YearPalace.json'
import BodyHouse from '../../json/BodyHouse.json'
import TianGan from '../../json/TianGan.json' // Celetial Stem
import DiZhi from '../../json/DiZhi.json'
import MainStars from '../../json/MainStars.json'
import SupportStar from "../../json/SupStars.json" //support stars
import ExtraStar from "../../json/ExtraStars.json" //extra stars
import TianGanSupStars from '../../json/TianGanSupStars.json'
import DecadeSupStars from '../../json/DecadeSupStars.json'
import TaiSuiSupStars from '../../json/TaiSuiSupStars.json'
import BoShiStar from "../../json/BoShiTwelve.json"
import ChangShengStar from "../../json/ChangShengTwelve.json"
import SuiJianStar from '../../json/SuiJianTwelve.json'
import JiangXingStar from '../../json/JiangXingTwelve.json'
import ZodiacSupStar from '../../json/ZodiacSupStars.json'
import Brightness from '../../json/Brightness.json'
import StarMorph from '../../json/StarMorph.json'
import GH from '../../helpers/GeneralHelper'
import ChartGeneratorHelper from '../../helpers/ChartGeneratorHelper';
import init from '../../config/init'
import lang from '../../lang/init'
import moment from 'moment'

const morphStr = ['祿','權','科','忌']
const chNo = ['一','二','三','四','五','六','七','八','九','十']
const { innerWidth, innerHeight } = window

// const test = ChineseCalendarHelper.test(2033, 11, 16)
// console.log(test)

const ChartContainer = props => {

  //to check which block array is highlighted, create a highlight array
  const _canvasRef = useRef(null)
  // const [blockHighlight, updateBlockHighlight] = useState(new Array(13).fill(0) ) //initate all 0. 0 means not highlighted, 1 is highlighted, 2 is current clicked
  const [selectedDecade, setSelectedDecade] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [targetTaiSuiYear, setTargetTaiSuiYear] = useState(null)
  const [showLoading, updateShowLoading] = useState(true)
  
  // check yin yang for clockwise and counterclockwise decade
  let yinYang = 0, 
    natalMorph = 0, 
    lifeHousePos = 0, 
    decadeHousePos = 0, 
    decadePalaces = DecadePalace, 
    taiSuiPalaces = YearPalace,
    taiSuiPosition = 0,
    today = ''
  let blockHighlight = new Array(13).fill(0)
  const dict = lang(props.preferences.lang)['chart']
  // console.log(props)
  //TODO: map the celetial stem and zodiac only once
  let blockData = [], profile = {}
  try
  {
    // blockData = props.hasOwnProperty('chartData') && props.chartData.hasOwnProperty('blockData') ? props.chartData.blockData : []
    blockData = props?.chartData?.blockData
    profile = props.profile
    //console.log("got profile data", profile)
    //console.log(props?.chartData?.blockData)
  }
  catch(e)
  {
    blockData = []
    profile = {}
  }
  // console.log(props)
  // const blockData = props.hasOwnProperty('chartData') && props.chartData.hasOwnProperty('blockData') ? props.chartData.blockData : []
  // let profile = props.profile

  //adjust hour off birth based on offset
  //NOTE, this also changes the day as well, but in the chart call to the server, we only change the hour...
  let newProfile={...profile}
  let offsetHours = props.currentOffset
  const getNewZodiacOffset= (offsetHours)=>{
    let newHours = offsetHours
    if(offsetHours>=24)
    { 
      newHours= offsetHours-24
    }
    else if(offsetHours<0)
    {
      newHours= 24 + offsetHours
    }
    return newHours
  }
  console.log(`PHH issue in container-pre: offsetHours${offsetHours}` )    
  //offsetHours = getNewZodiacOffset(offsetHours)
  // let dateObject = new Date(newProfile["date_of_birth"])
  let dateObject = ChineseCalendarHelper.formatDateDisplay(newProfile["date_of_birth"])
  // console.log('checkpoint 1')
  // console.log(dateObject)
  today = ChineseCalendarHelper.formatDateDisplay(moment(dateObject).add(offsetHours, 'hours'))
  const py = moment(today).year() // dateObject.getFullYear()
  const pm = moment(today).month() // dateObject.getMonth()
  let pd = moment(today).date() // dateObject.getDate()
  let phh = moment(today).hour() // dateObject.getHours() + offsetHours
  const pmm = moment(today).minute() // dateObject.getMinutes()

  phh = getNewZodiacOffset(phh)
  console.log(`PHH issue in container-aft: PHH${phh}, offsetHours${offsetHours}` )

  // newProfile["date_of_birth"]=new Date(dateObject.setHours(dateObject.getHours()+props.currentOffset))
  newProfile['date_of_birth'] = new Date(py, pm, pd, phh, pmm)
  today = newProfile['date_of_birth']
  profile={...newProfile}

  today = profile.date_of_birth !== null && profile.date_of_birth !== undefined ? ChineseCalendarHelper.formatDateDisplay(profile.date_of_birth) : ChineseCalendarHelper.formatDateDisplay()
  // console.log(today)
  console.log(`PHH issue in container: old today HH: ${moment(today)}`)
  //let hh = getNewZodiacOffset(moment(today).hour() + props.currentOffset) //add the chart offset hours
  let hh = getNewZodiacOffset(moment(today).hour()) //add the chart offset hours

  let mm = moment(today).minute()

  // if the hour and minutes are after 23, then move one more day
  console.log(`PHH issue in container: old today HH: ${hh} MM: ${mm} CURRENTOFFSET: ${props.currentOffset} OFFSETHOUR: ${offsetHours}` )
	console.log(`PHH issue in container: old today ${moment(today)}`)

	
  //find object for tianGan
  const findTianGanObj = (tianGanID) =>{
    let tgObj= TianGan.find((element) => element.id.toLowerCase() === tianGanID.toLowerCase());
    return tgObj["tw"]
  }

  const findPalaceObj = (PalaceID) =>{
    let palaceObj= Palaces.find((element) => element.id.toLowerCase() === PalaceID.toLowerCase());
    return palaceObj["tw"]
  }

  const findSupportStarObj = (supportStarid) =>{
    let supportStarObj= SupportStar[supportStarid]
    return supportStarObj["tw"]
  }

  const findExtraStarObj = (extraStarid) =>{
    if(!ExtraStar.hasOwnProperty(extraStarid)) return undefined
    let extraStarObj = ExtraStar[extraStarid]
    // console.log(extraStarid)
    return extraStarObj["tw"]
  }


  //create an optional data array to map the support stars and the body palace, each element is the html code to render
  let optionalData=["","","","","","","","","","","","",""]
  const bodyPalaceLocation =  props.chartData.bodyPalaceLocation
  const supportStarData = props.chartData.supportStarData
  const extraStarData =props.chartData.extraStarData

  //adjust index based on board cycle (max =13, min =0)
  const getAppendIndexNo= (newIndex)=>{
    if(newIndex>=12)
    { 
      newIndex= newIndex-12
    }
    else if(newIndex<0)
    {
      newIndex= 12 + newIndex
    }
    // console.log(newIndex)
    return newIndex

  }

  const highlightBlocks= (clickBlockIndex)=>{
    // console.log(`the check block ${clickBlockIndex}`)
    if(clickBlockIndex === null || clickBlockIndex === undefined) return

    //based on the block index, add a highlight for position +4 , +6 and -4
    let newBlockHighlight=new Array(12).fill(0)
    // console.log(`new click box index ${newBlockHighlight[clickBlockIndex]}`)
    if(blockHighlight[clickBlockIndex] === 2)
    {
      // console.log('return normal highlight')
      // updateBlockHighlight(newBlockHighlight)
      blockHighlight = newBlockHighlight
      clearCanvas()
      return
    }
    newBlockHighlight[clickBlockIndex]= 2 //put click index
    newBlockHighlight[getAppendIndexNo(clickBlockIndex+4)]=1
    newBlockHighlight[getAppendIndexNo(clickBlockIndex+6)]=1
    newBlockHighlight[getAppendIndexNo(clickBlockIndex-4)]=1

    // updateBlockHighlight(newBlockHighlight)
    blockHighlight = newBlockHighlight
    drawBlockLines(clickBlockIndex)
    // console.log('return new hightlight ')
    return 
  }

  const clearCanvas = () => {
    const c = _canvasRef.current
    var ctx = c.getContext("2d");
    const cW = c.width
    const cH = c.height
    ctx.clearRect(0, 0, cW, cH)
  }
  const drawBlockLines = (pos = 0) => {
    const c = _canvasRef.current
    var ctx = c.getContext("2d");
    const cW = c.width
    const cH = c.height
    clearCanvas()
    let x1, x2, x3, x4, x5, y1, y2, y3, y4, y5
    //generate the triangle
    const arr = ChineseCalendarHelper.triangleStrokeArray(pos)
    x1 = cW * arr[0][0]
    x2 = cW * arr[1][0]
    x3 = cW * arr[2][0]
    x4 = cW * arr[3][0]
    x5 = cW * arr[4][0]

    y1 = cH * arr[0][1]
    y2 = cH * arr[1][1]
    y3 = cH * arr[2][1]
    y4 = cH * arr[3][1]
    y5 = cH * arr[4][1]

    ctx.beginPath();
    ctx.setLineDash([3,3])
    ctx.strokeStyle = '#6A6C6E'
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.lineTo(x3, y3);
    ctx.lineTo(x4, y4);
    ctx.lineTo(x5, y5)
    ctx.stroke()
  }

  // console.log(blockHighlight)
  if(!(supportStarData  === undefined) )
  {
    for(let star in supportStarData)
    {
      let starObject= supportStarData[star]
      optionalData[starObject["position"]]=optionalData[starObject["position"]]+ findSupportStarObj(star) +" "
    }

  }

  if(!(extraStarData  === undefined) )
  {
    for(let star in extraStarData)
    {
      let starObject= extraStarData[star]
      optionalData[starObject["position"]]=optionalData[starObject["position"]]+ findExtraStarObj(star) +" "
    }

  }

  if(!(bodyPalaceLocation  === undefined) )
  {
    optionalData[bodyPalaceLocation]=optionalData[bodyPalaceLocation]+BodyHouse['tw']+" "
  }

  let beginYear = 2, decadeYearsArr = [], taiSuiYearsArr = []

  let xiaoXianArr = [], xiaoXianPos = null

  let suiJianArr = [], jiangXingArr = []

  // once data is passed, parse the data to display information
  if(blockData !== undefined && blockData.length > 0)
  {
    // console.log(blockData)
    // console.log(props.chartData)
    /**
     * Change the date_of_birth to today in order to fix the late Zhi hour problem
    const birthGZ = ChineseCalendarHelper.getYearGZ(profile.date_of_birth)
    taiSuiPosition = ChineseCalendarHelper.returnDiZhiObj(birthGZ[1])['pos']
    natalMorph = ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)
     */
    const birthGZ = ChineseCalendarHelper.getYearGZ(today)
    taiSuiPosition = ChineseCalendarHelper.returnDiZhiObj(birthGZ[1])['pos']
    natalMorph = ChineseCalendarHelper.getTianGanFromBirthYear(today)
    // console.log(natalMorph)
    // console.log(natalMorph % 2)
    yinYang = natalMorph % 2 === 0 ? 1:0 // odd is Yang, even is Yin
    // console.log(`yin yang test is ${yinYang === 1 ? 'yang':'yin'}`)

    lifeHousePos = ChartGeneratorHelper.getLifeHousePosition(blockData)
    // taiSuiPosition = lifeHousePos
    // taiSuiPosition = ChineseCalendarHelper.returnDiZhiObj()['pos']
    suiJianArr = ChartGeneratorHelper.getSuiJianArray(taiSuiPosition)
    // console.log(suiJianArr)
    jiangXingArr = ChartGeneratorHelper.getJiangXingArr(taiSuiPosition)
    // console.log(jiangXingArr)

    const gender = props.profile.gender === 'Male' ? 1:0
    console.log(`gender is ${gender === 1 ? 'Male':'Female'}`)
    let decadeMove = ChineseCalendarHelper.determineDecadeMovement(today, props.profile.gender) // even is clockwise, odd is counterclockwise
    console.log("today passed to xiaoxian is ", today)
    xiaoXianArr = ChineseCalendarHelper.getXiaoXianYearsArr(today, gender)

    beginYear = ChineseCalendarHelper.parseElements(props.chartData.lifeHouseElement.five_element)
    // console.log(`the begin year is ${beginYear}`)
    decadeYearsArr = ChineseCalendarHelper.getDecadeYearsArr(beginYear, lifeHousePos, decadeMove)
    // console.log(decadeYearsArr)
    
    taiSuiYearsArr = ChineseCalendarHelper.getOffsetDecadeYearRange(beginYear, 0)
    // console.log(taiSuiYearsArr)
    let selectedHousePos = null
    if(selectedDecade !== null)
    {
      decadePalaces = ChineseCalendarHelper.getOffsetDecadePalace(lifeHousePos, selectedDecade, decadeMove, props.chartData.blockData)
      // console.log(decadePalaces)
      taiSuiYearsArr = ChineseCalendarHelper.getOffsetDecadeYearRange(beginYear, selectedDecade)
      // console.log(taiSuiYearsArr)
      selectedHousePos = ChineseCalendarHelper.getDecadePos(lifeHousePos, selectedDecade, decadeMove)
      decadeHousePos = selectedHousePos
      taiSuiPosition = decadeHousePos
      // suiJianArr = ChartGeneratorHelper.getSuiJianArray(taiSuiPosition)
      // jiangXingArr = ChartGeneratorHelper.getJiangXingArr(taiSuiPosition)
    }

    if(selectedYear !== null && targetTaiSuiYear !== null)
    {
      const beginTaiSuiPalace = ChineseCalendarHelper.returnDiZhiObjGivenId(targetTaiSuiYear[1], 'pos')
      selectedHousePos = beginTaiSuiPalace
      
      taiSuiPalaces = ChineseCalendarHelper.getOffsetTaiSuiPalace(beginTaiSuiPalace)
      const getXiaoXianPosition = ChineseCalendarHelper.getXiaoXianPositionGivenYear(taiSuiYearsArr[selectedYear], xiaoXianArr)
      xiaoXianPos = getXiaoXianPosition !== false ? getXiaoXianPosition : null
      taiSuiPosition = xiaoXianPos
      // suiJianArr = ChartGeneratorHelper.getSuiJianArray(taiSuiPosition)
      // jiangXingArr = ChartGeneratorHelper.getJiangXingArr(taiSuiPosition)
    }
    if(selectedHousePos !== null)
    {
      // console.log('highlight again')
      highlightBlocks(selectedHousePos)
    }
  }

  // console.log(props)
  // console.log(profile.date_of_birth)
  // console.log(blockData)
  return (
    (blockData  === undefined || blockData.length == 0)?
    <IonContent>
    <IonGrid className="">
      <IonLoading
          backdropDismiss
          // cssClass='my-custom-class'
          isOpen={showLoading}
          message={dict['loading']}
      />
    </IonGrid>
    </IonContent>
    :
    <React.Fragment>
    <IonContent
      forceOverscroll={true}
      scrollX={true}
    >
    <div className="table-container">
      <table className="tg chart-table">
      <tbody>
        <tr>
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={3} // si
            dizhi={DiZhi[3].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[3]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[3]}
            suiJianStar={suiJianArr[3]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={4} // hai
            dizhi={DiZhi[4].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[4]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[4]}
            suiJianStar={suiJianArr[4]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={5} 
            dizhi={DiZhi[5].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[5]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[5]}
            suiJianStar={suiJianArr[5]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={6} 
            dizhi={DiZhi[6].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[6]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[6]}
            suiJianStar={suiJianArr[6]}
            {...props}
          />
        </tr>
        <tr>
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={2} 
            dizhi={DiZhi[2].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[2]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[2]}
            suiJianStar={suiJianArr[2]}
            {...props}
          />
          <td className="tg-0pky" colSpan="2" rowSpan="2" style={{ width: '50%' }}>
            <div className='time-switch' style={{ zIndex: 2 }}>
              <IonThumbnail style={{ width: '30%', height: '20px' }} onClick={()=>{

                console.log("PHH number-prev: ",phh)
                if(phh <= 2)
                {
                  console.log("PHH number-prev-1: ",phh-1)
                  return props.setChartHourOffset(-1)
                }
                console.log("PHH number-prev-2: ",phh-2)
                return props.setChartHourOffset(-2)
              }} >
                <IonImg style={{ width: '20px' }} src={require('../../images/Icon/Left.svg')} />
              </IonThumbnail>
              {/*<IonIcon src={require('../../images/Icon/Left.svg')} style={{ width: '20px', height: '20px' }} />*/}
              <p>{`${ChineseCalendarHelper.getZodiacHourStr(profile.date_of_birth)}時`}</p>
              {/*<IonIcon src={require('../../images/Icon/Right.svg')} style={{ width: '20px', height: '20px' }} />*/}
              <IonThumbnail style={{ width: '30%', height: '20px', display: 'flex', justifyContent: 'flex-end' }} onClick={()=>{
                console.log("PHH number-next: ",phh)
                if(phh >= 22)
                {

                  console.log("PHH number-next: ",phh+1)
                  return props.setChartHourOffset(+1)
                }
        	console.log("PHH number-next: ",phh+2)

                return props.setChartHourOffset(+2)
              } }  >
                <IonImg style={{ width: '20px' }} src={require('../../images/Icon/Right.svg')} />
              </IonThumbnail>
            </div>
            <div className='chart-profile-display-wrapper'>
            <p className='profile-name'>{profile.nickname}</p>
            <p className='chart-profile-display-text name'>
            {`${yinYang === 0 ? '陰':'陽'}${props.profile.gender === 'Male' ? '男':'女'}  `}
            {`${props.chartData.lifeHouseElement.element} (${props.chartData.lifeHouseElement.tw})`}<br/>

            {`陽曆：`}
            {`${ChineseCalendarHelper.formatDateForChart(profile.date_of_birth)}`}<br/>
            {props.profile.hasOwnProperty('is_solar') && !props.profile.is_solar ? 
              <>
              真太陽時：{typeof props.chartData.solarTime === 'string' ? ChineseCalendarHelper.formatSolarTime(profile.date_of_birth, props.chartData.solarTime):`${ChineseCalendarHelper.formatDateForChart(profile.date_of_birth)}`}<br/>
              </>
            :null}
            {`農曆：`}
            {`${ChineseCalendarHelper.formatLunarCalendarDisplay(profile.date_of_birth)}`}<br/>
            {props.chartData.hasOwnProperty('mingShenZhuStarData') && props.chartData.mingShenZhuStarData !== null ? 
            `命主:${ChineseCalendarHelper.returnStarObj(props.chartData.mingShenZhuStarData.mingZhu, 'tw')}  
            身主:${ChineseCalendarHelper.returnStarObj(props.chartData.mingShenZhuStarData.shenZhu, 'tw')}`
            :null}
            </p>

            </div>
            
            <div className='chart-explain' style={{ zIndex: 2 }} onClick={e => {
              //console.log("Textbook block")
              //console.log(props)
              console.log(blockData)
              console.log(props.profile)
              props.history.push(`${init.router.textbook.url}`, { chartData: props?.chartData?.blockData, profile: props.profile, typeOfChart: 'natal', fullStarData: props?.chartData })
            }}>本命盤解說</div>
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
            <canvas ref={_canvasRef} id="triangleConnections" width={innerWidth * 0.5} height={(innerHeight * 0.5 - 60)} style={{ mixBlendMode: 'darken', zIndex: 1 }}></canvas>
            </div>


          </td>
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={7} 
            dizhi={DiZhi[7].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[7]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[7]}
            suiJianStar={suiJianArr[7]}
            {...props}
          />
          {/*<td className="tg-0lax">{findPalaceObj(blockData[7]["palace_str"])}<br/>{DiZhi[9].tw} <br/> {findTianGanObj(blockData[7]["tianGan_str"])} <br/> {findStarObj(blockData[7]["north_star_str"])} <br/> {findStarObj(blockData[7]["south_star_str"])} <br/> {optionalData[7]} </td>*/}
        </tr>
        <tr>
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={1} 
            dizhi={DiZhi[1].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[1]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[1]}
            suiJianStar={suiJianArr[1]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={8} 
            dizhi={DiZhi[8].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[8]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[8]}
            suiJianStar={suiJianArr[8]}
            {...props}
          />
        </tr>
        <tr>
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={0} 
            dizhi={DiZhi[0].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[0]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[0]}
            suiJianStar={suiJianArr[0]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={11} 
            dizhi={DiZhi[11].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[11]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[11]}
            suiJianStar={suiJianArr[11]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={10} 
            dizhi={DiZhi[10].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[10]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[10]}
            suiJianStar={suiJianArr[10]}
            {...props}
          />
          <TableUnit 
            blockData={blockData}
            optionalData={optionalData}
            palace={9} 
            dizhi={DiZhi[9].tw}
            natalMorph={ChineseCalendarHelper.getTianGanFromBirthYear(profile.date_of_birth)}
            toggleHighlight={highlightBlocks}
            highlightType={blockHighlight[9]}
            decadeYearsArr={decadeYearsArr}
            xiaoXianArr={xiaoXianArr}
            selectedDecade={selectedDecade}
            decadePalaces={decadePalaces}
            taiSuiPalaces={taiSuiPalaces}
            targetTaiSuiYear={targetTaiSuiYear}
            decadeHousePos={decadeHousePos}
            xiaoXianPos={xiaoXianPos}
            jiangXingStar={jiangXingArr[9]}
            suiJianStar={suiJianArr[9]}
            {...props}
          />
        </tr>
      </tbody>
      </table>
    </div>
      </IonContent>

    <IonFooter className="ion-no-border ion-chart-footer">
      <div className='option-btn-wrapper'>
        <DecadeUnit profile={props.profile} decadeYearsArr={decadeYearsArr} blockData={blockData} selectedDecade={selectedDecade} onSelectDecade={setSelectedDecade} onSelectYear={setSelectedYear} setTargetTaiSuiYear={setTargetTaiSuiYear} />
        <YearUnit decadeYearsArr={decadeYearsArr} taiSuiYearsArr={taiSuiYearsArr} {...props} selectedDecade={selectedDecade} onSelectDecade={setSelectedDecade} selectedYear={selectedYear} onSelectYear={setSelectedYear} setTargetTaiSuiYear={setTargetTaiSuiYear} />
      </div>
    </IonFooter>

      </React.Fragment>
  );
}

/**
 * usage:
  <TableUnit
    blockData={obj}
    optionalData={obj}
    palace={3} 
    dizhi={?}
    palace={findPalaceObj(blockData[3]["palace_str"])}
    tiangan={findTianGanObj(blockData[3]["tianGan_str"])}
    dizhi={DiZhi[5].tw}
    northStar={findStarObj(blockData[3]["north_star_str"])}
    southStar={findStarObj(blockData[3]["south_star_str"])}
    extraStar={optionalData[3]}
  />
 */
const TableUnit = props => {
  //console.log(props)
  if(props.blockData === undefined || props.blockData.length == 0) return null
  // console.log(`morph ${props.natalMorph}`)
  const natalTianGan = TianGan[props.natalMorph]['id']
  let natalMorphArr = [], decadeMorphArr = [], taiSuiMorphArr = [], palaceMorphArr = []
  let decadeSupStars = [], taiSuiSupStars = []
  Object.keys(StarMorph[natalTianGan]).map(k => {
    natalMorphArr.push(StarMorph[natalTianGan][k])
  })
  if(props.selectedDecade !== null)
  {
    // console.log(`selected decade is ${props.selectedDecade}`)
    const beginTianGan = props.chartData.lifeHouseElement['tianGan']
    // let targetTianGan = parseInt(ChineseCalendarHelper.returnTianGanObjGivenId(beginTianGan, 'year'), 10) + parseInt(props.selectedDecade, 10)
    // // console.log(`target tiangan ${targetTianGan}`)
    // if(targetTianGan >= 10)
    // {
    //   targetTianGan = targetTianGan % 10
    // }
    // console.log(`target tian gan ${targetTianGan}`)
    // const decadeTianGan = ChineseCalendarHelper.returnTianGanObjGivenNo(targetTianGan, 'id')
    const decadeTianGan = props.decadePalaces[props.decadeHousePos]['natalData']['tianGan_str']
    // console.log(`decade tian gan ${decadeTianGan}`)
    const decadeDiZhi = props.decadePalaces[props.decadeHousePos]['natalData']['zodiac_str']
    let targetTianGan = parseInt(ChineseCalendarHelper.returnTianGanObjGivenId(decadeTianGan, 'year'), 10)
    // console.log(`target tiangan ${targetTianGan}`)
    if(targetTianGan >= 10)
    {
      targetTianGan = targetTianGan % 10
    }
    const luYanTuoObj = TianGanSupStars[targetTianGan]
    const DecadeMorph = StarMorph[decadeTianGan]

    // console.log(luYanTuoObj)
    Object.keys(DecadeMorph).map(k => {
      decadeMorphArr.push(DecadeMorph[k])
    })
    Object.keys(luYanTuoObj).map(k => {
      const s = luYanTuoObj[k]
      if(props.palace === parseInt(s, 10))
      {
        decadeSupStars.push(<div key={`decade-sup-star-${k}`} className='decade-sup-star-unit'>{DecadeSupStars[k]['tw']}</div>)
      }
    })

    const luanXi = ZodiacSupStar[decadeDiZhi]
    Object.keys(luanXi).map(k => {
      const s = luanXi[k]
      if(props.palace === parseInt(s, 10))
      {
        decadeSupStars.push(<div key={`decade-sup-star-${k}`} className='decade-sup-star-unit'>{DecadeSupStars[k]['tw']}</div>)
      }
    })
    
  }
  if(props.targetTaiSuiYear !== null)
  {
    // get the taisui morph
    const taiSuiTianGan = props.targetTaiSuiYear[0]
    const taiSuiDiZhi = props.targetTaiSuiYear[1]
    const TaiSuiMorph = StarMorph[taiSuiTianGan]
    Object.keys(TaiSuiMorph).map(k => {
      taiSuiMorphArr.push(TaiSuiMorph[k])
    })
    // get the taisui lu yang tuo
    const notg = ChineseCalendarHelper.returnTianGanObjGivenId(taiSuiTianGan, 'year')
    const luYanTuoObj = TianGanSupStars[notg]
    Object.keys(luYanTuoObj).map(k => {
      const s = luYanTuoObj[k]
      if(props.palace === parseInt(s, 10))
      {
        taiSuiSupStars.push(<div key={`taisui-sup-star-${k}`} className='taisui-sup-star-unit'>{TaiSuiSupStars[k]['tw']}</div>)
      }
    })

    const luanXi = ZodiacSupStar[taiSuiDiZhi]
    Object.keys(luanXi).map(k => {
      const s = luanXi[k]
      if(props.palace === parseInt(s, 10))
      {
        taiSuiSupStars.push(<div key={`taisui-sup-star-${k}`} className='taisui-sup-star-unit'>{TaiSuiSupStars[k]['tw']}</div>)
      }
    })

  }
  // console.log(`natal tian gan ${natalTianGan['id']}`)
  // console.log(natalMorphArr)
  const findTianGanObj = (tianGanID) =>{
    let tgObj= TianGan.find((element) => element.id.toLowerCase() === tianGanID.toLowerCase());
    return tgObj["tw"]
  }

  const findPalaceObj = (PalaceID) =>{
    let palaceObj= Palaces.find((element) => element.id.toLowerCase() === PalaceID.toLowerCase());
    return palaceObj["tw"]
  }

  const findDecadePalaceObj = (palaceID) => {
    let palaceObj= DecadePalace.find((element) => element.id.toLowerCase() === palaceID.toLowerCase());
    return palaceObj["tw"]
  }

  const findYearPalaceObj = (palaceID) => {
    let palaceObj= YearPalace.find((element) => element.id.toLowerCase() === palaceID.toLowerCase());
    return palaceObj["tw"]
  }

  const findSupportStarObj = (supportStarid) =>{
    if (!supportStarid)
    {
      return null
    }
    // console.log(`the support star id is ${supportStarid}`)
    const starId = GH.lowerFirstLetterAndCombineTheSpace(supportStarid)
    let supportStarObj= SupportStar[starId]
    let starItem = []
    // console.log(supportStarObj)
    if(supportStarObj.hasOwnProperty('brightness') && supportStarObj['brightness'] !== null && Object.keys(supportStarObj['brightness']).length > 0)
    {
      // console.log(supportStarObj['brightness'])
      const dz = DiZhi[props.palace]['id']
      // console.log(`the target dz is ${dz}`)
      // console.log(supportStarObj['brightness'])
      const bk = supportStarObj['brightness'][dz]
      if(bk && bk !== '' && bk !== null)
      {
        const tw = Brightness[bk]['acronym']
        starItem = <React.Fragment><span className='support-star-text'>{supportStarObj["tw"]}</span><span className="star-brightness">{tw}</span></React.Fragment>
        if(natalMorphArr.includes(starId) || decadeMorphArr.includes(starId) || taiSuiMorphArr.includes(starId))
        {

          const idx = natalMorphArr.indexOf(starId) !== -1 ? natalMorphArr.indexOf(starId) : 0
          const dIdx = decadeMorphArr.indexOf(starId) !== -1 ? decadeMorphArr.indexOf(starId) : 0
          const tIdx = taiSuiMorphArr.indexOf(starId) !== -1 ? taiSuiMorphArr.indexOf(starId) : 0

            starItem = <React.Fragment><span className='support-star-text'>{supportStarObj["tw"]}</span>
            <span className="star-brightness">{tw}</span>
            <span className={natalMorphArr.includes(starId) ? "natal-morph":"natal-morph-placeholder"}>{morphStr[idx]}</span>
            {props.selectedDecade !== null ? <span className={decadeMorphArr.includes(starId) ? "decade-morph":"decade-morph-placeholder"}>{morphStr[dIdx]}</span>:null}
            {props.targetTaiSuiYear !== null ? <span className={taiSuiMorphArr.includes(starId) ? "taisui-morph":"taisui-morph-placeholder"}>{morphStr[tIdx]}</span>:null}
            </React.Fragment>
          // }
          return starItem
        }
        starItem = <React.Fragment><span className='support-star-text'>{supportStarObj["tw"]}</span>
          <span className="star-brightness">{tw}</span>
          <span className="natal-morph-placeholder">{morphStr[0]}</span>
          {props.selectedDecade !== null ? <span className={decadeMorphArr.includes(starId) ? "decade-morph":"decade-morph-placeholder"}>{morphStr[0]}</span>:null}
          {props.targetTaiSuiYear !== null ? <span className={taiSuiMorphArr.includes(starId) ? "taisui-morph":"taisui-morph-placeholder"}>{morphStr[0]}</span>:null}
        </React.Fragment>
        return starItem
      }
      // console.log(`support star ${supportStarObj["tw"]} with ${bk}`)
    }
    // the brightness of the star isn't indicated
    
    starItem = <React.Fragment>{supportStarObj["tw"]}</React.Fragment>
    if(natalMorphArr.includes(starId) || decadeMorphArr.includes(starId) || taiSuiMorphArr.includes(starId))
    {
      const idx = natalMorphArr.indexOf(starId) !== -1 ? natalMorphArr.indexOf(starId) : 0
      const dIdx = decadeMorphArr.indexOf(starId) !== -1 ? decadeMorphArr.indexOf(starId) : 0
      const tIdx = taiSuiMorphArr.indexOf(starId) !== -1 ? taiSuiMorphArr.indexOf(starId) : 0

      starItem = <React.Fragment><span className='support-star-text'>{supportStarObj["tw"]}</span>
      <span className={natalMorphArr.includes(starId) ? "natal-morph":"natal-morph-placeholder"}>{morphStr[idx]}</span>
      {props.selectedDecade !== null ?<span className={decadeMorphArr.includes(starId) ? "decade-morph":"decade-morph-placeholder"}>{morphStr[dIdx]}</span>:null}
      {props.targetTaiSuiYear !== null ? <span className={taiSuiMorphArr.includes(starId) ? "taisui-morph":"taisui-morph-placeholder"}>{morphStr[tIdx]}</span>:null}
      </React.Fragment>
      return starItem
    }

    return starItem
  }

  const findExtraStarObj = (extraStarid) =>{
    if(!ExtraStar.hasOwnProperty(extraStarid)) return undefined
    // console.log(`extra star id ${extraStarid}`)
    let extraStarObj = ExtraStar[extraStarid]
    // console.log(extraStarObj)
    return extraStarObj['tw']
  }

  const findStarObj = (StarID) =>{
    if (!StarID)
    {
      return null
    }
    const starId = GH.lowerFirstLetterAndCombineTheSpace(StarID)
    let starItem = ''
    let starObj = MainStars[starId]
    // let starObj= NorthSouthStar.find((element) => element.id.toLowerCase() === StarID.toLowerCase());
    // console.log(props.palace)
    if(starObj.hasOwnProperty('brightness') && Object.keys(starObj['brightness']).length > 0)
    {
      const dz = DiZhi[props.palace]['id']
      const bk = starObj['brightness'][dz]
      const tw = Brightness[bk]['acronym']
      starItem = <React.Fragment>{starObj["tw"]}<span className="star-brightness">{tw}</span></React.Fragment>
      if(natalMorphArr.includes(starId) || decadeMorphArr.includes(starId) || taiSuiMorphArr.includes(starId))
      {
        const idx = natalMorphArr.indexOf(starId) !== -1 ? natalMorphArr.indexOf(starId) : 0
        const dIdx = decadeMorphArr.indexOf(starId) !== -1 ? decadeMorphArr.indexOf(starId) : 0
        const tIdx = taiSuiMorphArr.indexOf(starId) !== -1 ? taiSuiMorphArr.indexOf(starId) : 0
        // console.log(`the star idx ${idx} and decade idx ${dIdx}`)
        starItem = <React.Fragment><span className='main-star-text'>{starObj["tw"]}</span>
        <span className="star-brightness">{tw}</span>
        <span className={natalMorphArr.includes(starId) ? "natal-morph":"natal-morph-placeholder"}>{morphStr[idx]}</span>
        {props.selectedDecade !== null ?<span className={decadeMorphArr.includes(starId) ? "decade-morph":"decade-morph-placeholder"}>{morphStr[dIdx]}</span>:null}
        {props.targetTaiSuiYear !== null ? <span className={taiSuiMorphArr.includes(starId) ? "taisui-morph":"taisui-morph-placeholder"}>{morphStr[tIdx]}</span>:null}
        </React.Fragment>
        return starItem
      }
      return starItem
    }
    starItem = <React.Fragment>{starObj["tw"]}</React.Fragment>
    if(natalMorphArr.includes(starId) || decadeMorphArr.includes(starId) || taiSuiMorphArr.includes(starId))
    {
      const idx = natalMorphArr.indexOf(starId) !== -1 ? natalMorphArr.indexOf(starId) : 0
      const dIdx = decadeMorphArr.indexOf(starId) !== -1 ? decadeMorphArr.indexOf(starId) : 0
      const tIdx = taiSuiMorphArr.indexOf(starId) !== -1 ? taiSuiMorphArr.indexOf(starId) : 0

      starItem = <React.Fragment><span className='main-star-text'>{starObj["tw"]}</span>
      <span className={natalMorphArr.includes(starId) ? "natal-morph":"natal-morph-placeholder"}>{morphStr[idx]}</span>
      {props.selectedDecade !== null ?<span className={decadeMorphArr.includes(starId) ? "decade-morph":"decade-morph-placeholder"}>{morphStr[dIdx]}</span>:null}
      {props.targetTaiSuiYear !== null ? <span className={taiSuiMorphArr.includes(starId) ? "taisui-morph":"taisui-morph-placeholder"}>{morphStr[tIdx]}</span>:null}
      </React.Fragment>
      return starItem
    }
    // <div key={`support-star-${idx}`} className='support-star-unit' style={{ fontSize:fontSize }}>{s}</div>
    return starItem
  }

  //create an optional data array to map the support stars and the body palace, each element is the html code to render
  let optionalData=["","","","","","","","","","","","",""]
  let supportStars = ["","","","","","","","","","","","",""]
  let bodyPalaceData = ["","","","","","","","","","","","",""]
  let changShengArr= ["","","","","","","","","","","","",""]
  let boShiStarArr = ["","","","","","","","","","","","",""]
  let decadesYearArr = props.decadeYearsArr.length > 0 ? props.decadeYearsArr:["","","","","","","","","","","",""]
  const bodyPalaceLocation =  props.chartData.bodyPalaceLocation
  const supportStarData = props.chartData.supportStarData
  const extraStarData =props.chartData.extraStarData
  const changShengStarData = props.chartData.changShengStarData
  const boShiStarData = props.chartData.boshiStarData
  const decadeYear = decadesYearArr[props.palace]

  // console.log("support star data", supportStarData)
  if(!(supportStarData  === undefined) )
  {
    for(let star in supportStarData)
    {
      let starObject= supportStarData[star]
      // supportStars[starObject["position"]]=supportStars[starObject["position"]]+ findSupportStarObj(star) +" "
      supportStars[starObject["position"]]=supportStars[starObject["position"]]+ star +" "
    }

  }

  if(!(extraStarData  === undefined) )
  {
    for(let star in extraStarData)
    {
      let starObject= extraStarData[star]
      optionalData[starObject["position"]]=optionalData[starObject["position"]]+ star +" "
    }

  }

  if(!(changShengStarData === undefined) )
  {
    for(let star in changShengStarData)
    {
      let starObj = changShengStarData[star]
      // console.log(starObj)
      changShengArr[starObj['position']] = ChangShengStar[star]['tw']
      // let starObject= changShengStarData[star]
      // changSheng[starObject["position"]]=changSheng[starObject["position"]]+ findExtraStarObj(star) +" "
    }
  }

  if(!(boShiStarData === undefined))
  {
    for(let star in boShiStarData)
    {
      let starObj = boShiStarData[star]
      // console.log(starObj)
      boShiStarArr[starObj['position']] = BoShiStar[star]['tw']
      // let starObject= changShengStarData[star]
      // changSheng[starObject["position"]]=changSheng[starObject["position"]]+ findExtraStarObj(star) +" "
    }
  }

  if(!(bodyPalaceLocation  === undefined) )
  {
    bodyPalaceData[bodyPalaceLocation]=bodyPalaceData[bodyPalaceLocation]+BodyHouse['tw']+" "
  }


  let palace=findPalaceObj(props.blockData[props.palace]["palace_str"]),
  decadePalace=props.decadePalaces[props.palace],
  yearPalace=props.taiSuiPalaces[props.palace],
  tiangan=findTianGanObj(props.blockData[props.palace]["tianGan_str"]),
  dizhi=props.dizhi,
  northStar=findStarObj(props.blockData[props.palace]["north_star_str"]),
  southStar=findStarObj(props.blockData[props.palace]["south_star_str"]),
  supportStar=supportStars[props.palace].split(' '),
  extraStar=optionalData[props.palace].split(' '),
  bodyPalace=bodyPalaceData[props.palace],
  changShengStar=changShengArr[props.palace],
  boShiStar=boShiStarArr[props.palace]

  let xiaoXian = props.hasOwnProperty("xiaoXianArr") && props.xiaoXianArr.length > 0 ? props.xiaoXianArr[props.palace]:''
  // console.log(props)

  // let fontSize = '1.1em', starNo = 0
  // if(innerHeight <= 600)
  // {
  //   console.log('inner height is small')
  //   fontSize = '0.9em'
  // }

  let supStarsArr = [],extraStarArr = []
  supportStar.forEach((s, idx) => {
    let star = findSupportStarObj(s)
    supStarsArr.push(<div key={`support-star-${idx}`} className='support-star-unit'>{star}</div>)
  })
  // console.log(extraStar)
  extraStar.forEach((s, idx) => {
    // console.log(`the id ${s}`)
    if(s === '') return
    // console.log('star cool')
    let star = findExtraStarObj(s)
    if(star !== undefined)
    {
      // console.log('star is found')
      // console.log(star)
      extraStarArr.push(<div key={`extra-star-${idx}`} className='extra-star-unit'>{star}</div>)
    }
  })

  //calculate highlight type
  let highlightcolor="#ffffff"
  switch(props.highlightType) {
    case 0:
      highlightcolor="#ffffff";
      break;
    case 1:
      highlightcolor="#f5f6f9";
      break;
    case 2:
      highlightcolor='#FFE3CA';
      break;
    default:
      highlightcolor="#ffffff";
  }

  return <td 
    // className={`tg-0pky ${ChineseCalendarHelper.returnDiZhiObj(props.palace, 'id')}-table-cell`} 
    onClick= {() => {props.toggleHighlight(props.palace)}}
    style={props.highlightType>0?{backgroundColor:highlightcolor}:null} //set to null as for some reason in firefox, highlight color #fff overrights border
  >
  <div className={`tg-0pky ${ChineseCalendarHelper.returnDiZhiObj(props.palace, 'id')}-table-cell`}>
    <div className={props.selectedDecade === null && props.targetTaiSuiYear === null ? 'chart-unit-main-stars' : 'chart-unit-main-stars-extend'}>
      {northStar !== null ? <div className='north-star-unit'>{northStar}</div>:null}
      {southStar !== null ? <div className='south-star-unit'>{southStar}</div>:null}
      {supStarsArr}
      {extraStarArr}
      {/*<div className='support-star-unit' style={{ fontSize:fontSize }}>{supportStar}</div>*/}
    </div>

    {/*<div className='chart-unit-extra-stars'>
      <div className='extra-star-unit'>{extraStar}</div>
    </div>*/}

    <div className='chart-unit-changSheng'>{changShengStar}
    {props.xiaoXianPos === props.palace ? <div className='chart-unit-xiaoxian-indicator'>限</div>:null}
    {props.selectedDecade === null && props.targetTaiSuiYear === null ? <div className='chart-unit-decade-xiaoxian'>{xiaoXian}</div> : null}
    </div>
    {/*<div>{extraStar}</div>*/}
    {props.selectedDecade === null && props.targetTaiSuiYear === null ? <div className='chart-unit-body-palace'>{bodyPalace[0]}</div>:null}
    {props.selectedDecade !== null && props.targetTaiSuiYear !== null ? <div className='chart-unit-year-palace'>{yearPalace['tw']}</div>:null}
    {props.selectedDecade !== null ? <div className='chart-unit-decade-palace'>{decadePalace['tw']}</div>:null}
    <div className='chart-unit-ganzhi'>{tiangan}{dizhi}</div>
    <div className='chart-unit-natal-palace'>{palace}</div>
    <div className='chart-unit-changing-sup-stars'>
      {decadeSupStars}
      {taiSuiSupStars}
    </div>
    

    {props.selectedDecade === null && props.targetTaiSuiYear === null ? 
      decadesYearArr[props.palace].length > 5 ? 
      <div className={decadesYearArr[props.palace].length > 5 ? 'chart-unit-decade-year shrink':'chart-unit-decade-year'}>
        {`${decadesYearArr[props.palace].split('/')[0]}/`}
        
        {decadesYearArr[props.palace].split('/')[1]}
      </div>
      :
      <div className={decadesYearArr[props.palace].length > 5 ? 'chart-unit-decade-year shrink':'chart-unit-decade-year'}>{decadesYearArr[props.palace]}</div>
      :null}
    <div className='chart-unit-boshi'>{boShiStar}</div>
    <div className={props.selectedDecade === null && props.targetTaiSuiYear === null ? `chart-unit-jiangxing`:`chart-unit-jiangxing`}>{props.jiangXingStar['tw']}</div>
    <div className={props.selectedDecade === null && props.targetTaiSuiYear === null ? `chart-unit-suijian`:`chart-unit-suijian`}>{props.suiJianStar['tw']}</div>  
  </div>

  
  </td>

}

const DecadeUnit = props => {
  // console.log(props)
  if(!props.hasOwnProperty('blockData') || !props.hasOwnProperty('decadeYearsArr')) return null
  const blockData = props.blockData
  const lifeHousePos = ChartGeneratorHelper.getLifeHousePosition(blockData)
  // console.log(`the life house position is ${lifeHousePos}`)
  let decadesArr = [], decadesData = [], decadeMove = 0 // 1 is counterclockwise
  decadeMove = ChineseCalendarHelper.determineDecadeMovement(props.profile.date_of_birth, props.profile.gender)
  // console.log(`decade move is ${decadeMove === 0 ? 'clockwise': 'counterclockwise'}`)
  // console.log(props.decadeYearsArr)
  for(let i = 0 ; i < 10; i++)
  {
    let dataPos = i
    if(decadeMove === 1) // counterclockwise
    {
      dataPos = lifeHousePos - i
      if(dataPos < 0)
      {
        dataPos = 12 + dataPos
      }      
    }
    else
    {
      dataPos = i + lifeHousePos
      if(dataPos >= 12)
      {
        dataPos = dataPos - 12
      }
    }
    // console.log(`the data pos is ${dataPos}`)
    const tg = ChineseCalendarHelper.returnTianGanText(blockData[dataPos]['tianGan_str']), dz = ChineseCalendarHelper.returnDiZhiText(blockData[dataPos]['zodiac_str'])
    decadesArr.push(<div key={`decade-unit-${i}`} className={props.selectedDecade === i ? `decade-unit-btn selected`:`decade-unit-btn`} onClick={e => {
      // console.log(`the values are the same`)
      if(props.selectedDecade === i)
      {
        props.onSelectDecade(null)
        props.onSelectYear(null)
        props.setTargetTaiSuiYear(null)
        return
      }
      props.onSelectDecade(i)
      props.setTargetTaiSuiYear(null)
      return props.onSelectYear(null)
    }}>
      {/*decades[dataPos]*/}<span>{`${chNo[i]}限`}</span>
      <span>{`${tg}${dz}`}</span>
    </div>)
    decadesData.push(blockData[dataPos])
  }

  return (<div className='decade-unit-wrapper'>
    {decadesArr}
  </div>)
}

const YearUnit = props => {
  if(!props.hasOwnProperty('profile') || !props.hasOwnProperty('taiSuiYearsArr')) return null
  // console.log(props.profile.date_of_birth)
  let yearsArr = [], 
  beginYear = ChineseCalendarHelper.getBeginYear(props.profile.date_of_birth)
  // console.log(`the begin year is ${beginYear}`)
  // beginYear = moment(props.profile.date_of_birth).year() || moment(new Date()).year() // new Date(props.profile.date_of_birth).getFullYear()
  // console.log(`the begin year is ${beginYear}`)
  console.log("taisui year",props.taiSuiYearsArr)
  for(let i = 0 ; i < 10 ; i ++)
  {
    console.log(`taisui year is ${i}`)
    const offset = props.taiSuiYearsArr[i]
    const finalYear = beginYear + offset
    // console.log(`final year is ${finalYear}`)
    const age = finalYear - beginYear + 1
    // console.log(`age is ${age}`)
    const str = ChineseCalendarHelper.getLunarCalendar(finalYear, 9, 19)
    // console.log(str)
    yearsArr.push(<div key={`year-unit-${i}`} className={props.selectedYear === i ? `year-unit-btn selected` : `year-unit-btn`} onClick={() => {
      if(props.selectedYear === i)
      {
        props.setTargetTaiSuiYear(null)
        return props.onSelectYear(null)
      }
      props.onSelectYear(i)
      props.setTargetTaiSuiYear([ChineseCalendarHelper.returnTianGanId(str['gzYear'][0]), ChineseCalendarHelper.returnDiZhiObj(str['gzYear'][1], 'id')])
      if(props.selectedDecade === null)
      {
        //find the decade and select it
        const element = props.chartData.lifeHouseElement.five_element
        let beginAge = ChineseCalendarHelper.parseElements(element)
        const dIndx = Math.floor((age - beginAge)/10)
        props.onSelectDecade(dIndx)
      }
      return
    }}>
    <span className='taisui-year'>{finalYear}</span>
    <span className='taisui-age'>{age >= 100 ? `${str['gzYear']}`:`${str['gzYear']}${age-1}`}</span>
    </div>)
  }

  return (<div className='year-unit-wrapper'>
    {yearsArr}
  </div>)
}

export default ChartContainer;

import AuthRequestHelper from "./AuthRequestHelper"

class PaymentHelper{


    //if payment record doesn't exist, return a null array
    async getPaypalPaymentRecord(userId){
        try{
            const requestUrl="paymentrecordbyuserid/"+userId
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            // console.log(error)
            return false
        }
    }

    async addPaypalPaymentRecord(id,paymentID,orderID,billingID,paymentMethod,paymentAmount){
        try{
            const requestUrl="paymentrecordbyuserid/"+id
            const resetParams={
                "payment_id": paymentID,
                "order_id": orderID,
                "billing_id": billingID,
                "payment_method": paymentMethod,
                "payment_amount": paymentAmount,
            }           
            return await AuthRequestHelper.requestNoToken("put", requestUrl,  resetParams)
        }
        catch(error){
            // console.log(error)
            return false
        }
    }
}

export default new PaymentHelper();
import React from 'react'
import { IonModal, IonButton, IonContent, IonIcon, IonLabel, IonThumbnail, IonImg } from '@ionic/react'
import './Asset.css'

/**
 * 
 * @param {*} props
 * usage:
    <Modal 
        open={true}
        handleClose={() => set open to false}
    >
        <ChildrenComponent />
    </Modal>
 */
const Modal = props => {

    return (
        <IonModal 
            isOpen={props.open} 
            cssClass='zmodal' 
            swipeToClose={true}
            onDidDismiss={props.handleClose}
        >
            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'flex-end', right: '20px', top: '20px', zIndex: 999 }}>
                <IonThumbnail slot="end" style={{ width: '30px', height: '30px' }}  onClick={props.handleClose} >
                    <IonImg src={require('../images/Icon/Cross.svg')} />
                </IonThumbnail>
                {/*<IonIcon style={{ width: '30px', height: '30px' }} src={require('../images/Icon/Cross.svg')} onClick={props.handleClose} slot="end" />*/}
            </div>
          {props.children}
        </IonModal>)
}

export default Modal
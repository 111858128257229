import config from '../config/config'
import FetchWrapper from '../assests/FetchWrapper'

import init from '../config/init'
import { Plugins } from '@capacitor/core'
import { thunderstorm } from 'ionicons/icons';
const { Storage } = Plugins;
const storageKeyChart= init.storageKey.loginKey
const userAccountKey = init.storageKey.userAccDetails

const Fetch = new FetchWrapper(config.localAuth.apiServiceUrl)

class AuthenticationHelper {
    /*on successful login, return token */
    async login(username,password){
        try{
            const response= await Fetch.postNoCors('login',{
                username: username,
                password: password
            }, (err,result)=>{
                if(err) throw err
                return result
            })
            this.setToken(response.token);
            //set the token in the local storage as well
            this.putStoreToken(response.token);
            return true
        }
        catch (error){
            console.log("Authentication Error: "+error)
            return false
        }
    }
    login_byToken(token){
        try{
            this.setToken(token);
            //set the token in the local storage as well
            this.putStoreToken(token);
            return true
        }
        catch (error){
            console.log("Authentication Error: "+error)
            return false
        }
    }

    get token() {
        let token = sessionStorage.getItem('xtoken');
        console.log("Token: "+token)

        if (token === undefined || token === null || token.length === 0) {
            return null;
        }

        return token;
    }

    get uuid(){
        if (this.token === null) {
            return null;
        }
        return this.claims.sub
    }

    setToken(token) {
        sessionStorage.setItem('xtoken', token);
        return true;
    }
    

    /*when loggin out */
    resetToken() {
        sessionStorage.removeItem('xtoken');
    }

    /* check if the user is logged in, return true if user is logged in */
    hasAuthenticated(){
        return this.token?true:false
        // return this.calims?true:false
    }

    decodeToken(token) {
        if (token === null) {
            return null;
        }
        return JSON.parse(atob(token.split(/\./)[1]))
    }

    get claims() {
        return this.decodeToken(this.token);
    }

    //get the auth token from local storage
    async putStoreToken(token){
        await Storage.set({ key: storageKeyChart,value: 
            JSON.stringify({
                xtoken: token,
                setDate: new Date()
            })
        });    
        // console.log("stored token")
        return true;
    }
 
    async resetStoredToken(token){
        await Storage.remove({ key: storageKeyChart});    
        return true;
    }

    async getStoredToken(){
        let token =await Storage.get({ key: storageKeyChart});  
        if(!token.value || token.value ===null ) 
        {
            return false;
        }  
        let xtoken_obj=JSON.parse(token.value)
        return xtoken_obj
    }

    //get account details from local storage
    async putAccDetails(username){
        await Storage.set({ key: userAccountKey,value: 
            JSON.stringify({
                username: username
            })
        });    
        // console.log("stored token")
        return true;
    }
 
    async resetAccDetails(){
        await Storage.remove({ key: userAccountKey});    
        return true;
    }

    async getAccDetails(){
        let accDetails =await Storage.get({ key: userAccountKey});  
        if(!accDetails.value || accDetails.value ===null ) 
        {
            return false;
        }  
        let accDetails_obj=JSON.parse(accDetails.value)
        return accDetails_obj
    }

    async checkTokenExpiry()
    {
        // if(!this.token){
        let tokenFromLocalStorage= await this.getStoredToken();
        if(tokenFromLocalStorage)
        {
            //check the date
            let tokenRecordDate= tokenFromLocalStorage.setDate
            tokenRecordDate= new Date(tokenRecordDate)
            let currentDate = new Date()
            let timePassed = (currentDate.getTime() - tokenRecordDate.getTime())/1000
            // console.log(timePassed)

            if (timePassed < init.tokenRefreshTime )
            {
                // console.log("Found token in local storage, copying to session storage")
                this.setToken(tokenFromLocalStorage.xtoken)
                return true
            }
            else
            {
                // console.log("Timed out, deleting token")
                await this.resetStoredToken(); // reset the stored token
                return false
            }
        }
        return false
        // }
    }
    
}

export default new AuthenticationHelper();

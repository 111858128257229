import React,{useState,useEffect, useRef}  from 'react';
import { withRouter } from "react-router"
import AuthContainer from './AuthContainer'
import { 
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonSpinner,
    IonThumbnail,
    IonImg,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonRow,
    IonCol,
    IonText,
    IonLoading,
    IonDatetime
} from '@ionic/react'
import GH from '../../helpers/GeneralHelper'
import DateTimePicker from '../../assests/DateTimePicker'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import ChineseCalendarHelper from '../../helpers/ChineseCalendarHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import CPH from '../../helpers/ChartProfileHelper'
import withPreloader from '../withPreloader'
import moment from 'moment'
import lang from '../../lang/init'
import init from '../../config/init'

// const monthColumns = [
//     "1",
//     "2",
//     "3",
//     "4",
//     "5",
//     "6",
//     "7",
//     "8",
//     "9",
//     "10",
//     "11",
//     "12",
// ]

const Register = props => {

    const dict = lang(props.preferences.lang)['auth']['registerPage']

    return(
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={true}>
            <div className='logo-container'>
                <img className='square-logo' src={require('../../images/Square-logo.svg')} />
            </div>
                {/*<IonGrid className='logo-container ion-justify-content-center'>
                    <IonCol></IonCol>
                </IonGrid>*/}
                <AuthContainer type="register" >
                    <InputSection {...props} dict={dict} />
                    <IonRow>
                    <IonCol className='ion-text-end'>
                        <p onClick={() => props.history.push('/login')}>{dict['existingMember']} <span className='primary-text cursor-pointer'>{dict['login']}</span></p>
                    </IonCol>
                    </IonRow>
                </AuthContainer>
            </IonContent>
        </IonPage>
    )
}

const InputSection = props => {
    const [isLunarDate, updateIsLunarDate] = useState(false)
    const [username, updateUsername] = useState('')
    const [password, updatePassword] = useState('')
    const [confirmPass, updateConfirmPass] = useState('')
    const [nickname, updateNickname] = useState('')
    const [birthday, updateBirthday] = useState(ChineseCalendarHelper.formatDateDisplay())
    const [gender, updateGender] = useState(0)
    const [birthdayDisp, updateBirthdayDisp] = useState(`${isLunarDate ? '農曆':'國曆'}${moment().format("YYYY年MM月DD日 HH:mm")}`)
    const [invisible, updateInvisible] = useState(true)
    const [showLoading, updateLoading] = useState(false)
    const [feedback, updateFeedback] = useState('')
    const [isSolarTime, setSolarTime] = useState(false)
    const [isLeapMonth, updateIsLeapMonth] = useState(false)

    const [_username, _updateUsername] = useState('')
    const [_password, _updatePassword] = useState('')
    const [_confirmPass, _updateConfirmPass] = useState('')

    const dict = props.dict

    const processForm = async (e) => {
        e.preventDefault()
        updateLoading(true)
        // check if username is the format
        if(username === '')
        {
            // u.setCustomValidity(dict['forgotEmail'])
            _updateUsername(dict['forgotEmail'])
            updateLoading(false)
            // u.reportValidity()
            return
        }
        else if(!GH.validateEmail(username))
        {
            // console.log('username format wrong')
            _updateUsername(dict['emailFormat'])
            updateLoading(false)
            // u.setCustomValidity(dict['emailFormat'])
            // u.reportValidity()
            return
        }
        else
        {
            _updateUsername('')
            // u.setCustomValidity('')
            // u.reportValidity()
        }
        if(password === '')
        {
            _updatePassword(dict['forgotPass'])
            // p.setCustomValidity(dict['forgotPass'])
            // p.reportValidity()
            updateLoading(false)
            return
        }
        else
        {
            _updatePassword('')
            // p.setCustomValidity('')
            // p.reportValidity()
        }
        if(confirmPass === '')
        {
            _updateConfirmPass(dict['confirmPass'])
            // c.setCustomValidity(dict['confirmPass'])
            // c.reportValidity()
            updateLoading(false)
            return
        }
        else if(confirmPass !== password)
        {
            _updateConfirmPass(dict['passMismatch'])
            // c.setCustomValidity(dict['passMismatch'])
            // c.reportValidity()
            updateLoading(false)
            return
        }
        else
        {
            _updateConfirmPass('')
            // c.setCustomValidity('')
            // c.reportValidity()
        }
        // console.log(birthday)
        let dob = moment()

        if(birthday !== '' || birthday !== null || birthday !== undefined)
        {
            // dob = ChineseCalendarHelper.formatDateDisplay(birthday)
            // console.log('birthday is indicated')
            // let toConverTime=!isSolarTime
            let inputDate = birthday !== '' ? ChineseCalendarHelper.formatDateDisplay(birthday) : ChineseCalendarHelper.formatDateDisplay()
            // console.log(inputDate)
            dob = inputDate
            // console.log(dob)
            if(isLunarDate)
            {
                // console.log('entered lunar date')
                // console.log('init ', inputDate)
                const y = moment(inputDate).year()
                const m = moment(inputDate).month()+1
                const d = moment(inputDate).date()
                const h = moment(inputDate).hour()
                const mm = moment(inputDate).minute()
                const solar = ChineseCalendarHelper.lunarToSolarConvert(y,m,d, isLeapMonth)
                // console.log(`input y ${y} m ${m} d ${d}`)
                const newSolarDate = new Date(solar.cYear, solar.cMonth-1, solar.cDay, h, mm)
                // console.log(newSolarDate)
                inputDate = ChineseCalendarHelper.formatDateDisplay(newSolarDate)
                updateBirthday(inputDate)
                dob = moment(inputDate)
                // console.log(dob)
            }
            // const solar = ChineseCalendarHelper.lunarToSolarConvert(y,m,d, isLeapMonth)
            // // console.log(`input y ${y} m ${m} d ${d}`)
            // const newSolarDate = new Date(solar.cYear, solar.cMonth-1, solar.cDay, h, mm)
            // updateBirthday(newSolarDate)
        }
        
        const reg = {
            "username": username.trim(),
            "password": password.trim(), 
            "displayName": nickname.trim() !== '' ? nickname.trim() : dict['defaultNickname'].trim(),
            "email": username.trim(),
            "dob": dob
        }
        // console.log(reg)
        try
        {
            // console.log(`is lunar date ${isLunarDate}`)
            // console.log(reg)
            // console.log('should register?')
            let registerResults= await UserProfileHelper.registerUser(reg)
            if(!registerResults)
            {
                throw registerResults
            }
            if(registerResults === 'USERNAME_EXIST')
            {
                updateFeedback(dict['usernameExist'])
            }
            let toConverTime=!isSolarTime
            const charts = await CPH.addLocalChartList('', init.categories[0]['value'], birthday !== '' ? ChineseCalendarHelper.formatDateDisplay(birthday) : ChineseCalendarHelper.formatDateDisplay(), gender === 0 ? 'Female':'Male', init.defaultLatLng[0], init.defaultLatLng[1], nickname !== '' ? nickname:init.categories[0]['text'], init.categories[0]['value'], toConverTime)
            // console.log(charts)
            updateFeedback(dict['registered'])
            updateLoading(false)
            return 
        }
        catch(e)
        {
            updateFeedback(dict['unknownError'])
            updateLoading(false)
            
            // console.log(e)
        }
        updateLoading(false)
        // console.log(registerResults)
    }

    return (<React.Fragment>
        <form>
        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel id='username-label' position="floating">{dict['enterEmail']}<IonText color="danger">*</IonText></IonLabel>
                <IonInput required clearInput id='username-input' type='email' value={username} onIonChange={e => updateUsername(e.detail.value)}></IonInput>
            </IonItem>
            <IonText><small>{_username}</small></IonText>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel id='password-label' position="floating">{dict['enterPass']}<IonText color="danger">*</IonText></IonLabel>
                <IonInput required clearInput id='password-input' type={invisible ? 'password' : 'text'} value={password} onIonChange={e => updatePassword(e.detail.value)}></IonInput>
                <IonThumbnail className='eye-icon' slot="end" onClick={() => updateInvisible(!invisible)} >
                    <IonImg src={invisible ? require('../../images/Icon/Close-eye.svg') : require('../../images/Icon/Open-eye.svg')} />
                </IonThumbnail>
                {/*<IonIcon  src={invisible ? require('../../images/Icon/Close-eye.svg') : require('../../images/Icon/Open-eye.svg')} onClick={() => updateInvisible(!invisible)} slot="end"></IonIcon>*/}
            </IonItem>
            <IonText><small>{_password}</small></IonText>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel id='confirmPass-label' position="floating">{dict['enterConfirmPass']} <IonText color="danger">*</IonText></IonLabel>
                <IonInput required clearInput id='confirmPass-input' type={invisible ? 'password' : 'text'} value={confirmPass} onIonChange={e => updateConfirmPass(e.detail.value)}></IonInput>
            </IonItem>
            <IonText><small>{_confirmPass}</small></IonText>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
            <IonItem>
                <IonLabel id='nickname-label' position="floating">{dict['enterNickname']}</IonLabel>
                <IonInput clearInput id='nickname-input' value={nickname} onIonChange={e => updateNickname(e.detail.value)}></IonInput>
            </IonItem>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>
        <IonItem>
            <IonLabel position="floating">{dict['enterGender']}</IonLabel>

            <div style={{ display: 'flex', padding: '40px 0px 20px 0px', justifyContent: 'space-evenly', width: '100%' }}>
                {gender === 0 ?
                    <IonImg onClick={() => updateGender(0)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Female-Icon.svg')} />
                    :
                    <IonImg onClick={() => updateGender(0)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Female-Icon-Unselected.svg')} />
                }
                {gender === 1 ?
                    <IonImg onClick={() => updateGender(1)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Male-Icon.svg')} />
                    :
                    <IonImg onClick={() => updateGender(1)} style={{ maxWidth: '60px' }} src={require('../../images/Gender/Male-Icon-Unselected.svg')} />
                }
            </div>

        </IonItem>
        </IonCol>
        </IonRow>

        <IonRow>
        <IonCol>

        <IonItem>
            <IonLabel position="floating" >{`${dict['dob']}${dict['dispSolar']}`}</IonLabel>
            {<IonInput id='datepicker-wrapper' placeholder={dict['dobPlaceholder']} type="text" value={birthdayDisp} clearInput></IonInput>}
            <DateTimePicker isLunarDate={isLunarDate} updateIsLunarDate={updateIsLunarDate} updateBirthday={updateBirthday} updateBirthdayDisp={updateBirthdayDisp} birthday={birthday} isLeapMonth={isLeapMonth} updateIsLeapMonth={updateIsLeapMonth} />
        </IonItem>

        </IonCol>
        </IonRow>

        {/*<IonRow>
        <IonCol>
            <IonItem>
                <IonLabel id='birthday-label' position="floating">{dict['enterBirthday']}</IonLabel>
                <IonDatetime id='birthday-input' displayFormat="YYYY年MMM月DD日 HH:mm" monthShortNames={monthColumns} value={birthdayDisp} placeholder="例：1990年1月1日 10:00" onIonChange={e => {
                    // const val = moment.utc(e.detail.value).format("YYYY-MM-DD_HH-mm-ss[Z]")
                    const val = ChineseCalendarHelper.formatDateDisplay(e.detail.value)
                    updateBirthday(val)
                    updateBirthdayDisp(e.detail.value)
                }} clearInput></IonDatetime>
            </IonItem>
        </IonCol>
        </IonRow>*/}
        
        {/*<IonRow>
        <IonCol>
        <IonItem>
            <IonLabel>{dict['useSolar']}</IonLabel>
            <IonCheckbox color="zdark" checked={isSolarTime} onIonChange={e => setSolarTime(e.detail.checked)} />
        </IonItem>
        </IonCol>
        </IonRow>*/}


        <IonRow>
        <IonCol>
        <IonButton color="zprimary" expand="block" onClick={processForm}>{showLoading ? <IonSpinner name='lines' />: dict['registerSubmit']}</IonButton>
        </IonCol>
        </IonRow>

        </form>
        <IonRow className='alert success'>
        <IonCol>
            <IonText>{feedback}</IonText>
        </IonCol>
        </IonRow>
        <IonLoading
            backdropDismiss
            // cssClass='my-custom-class'
            isOpen={showLoading}
            message={dict['loading']}
        />
        </React.Fragment>)
}
export default withRouter(withPreloader(Register))
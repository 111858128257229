import AuthRequestHelper from "./AuthRequestHelper"
import AuthenticationHelper from './AuthenticationHelper'
import ChineseCalendarHelper from './ChineseCalendarHelper'

class UserProfileHelper{

    // If the credential error happened or there is an expired auth, then log user out directly
    async autoLogout()
    {
        console.log('logout to prevent hijack')
        AuthenticationHelper.resetToken()
        AuthenticationHelper.resetStoredToken()//also remove localstorage token
    }

    /*get profiles */
    async getAuthProfileById(id){
        try{
            const requestUrl="userauthinfo/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})     
        }
        catch(error){
            console.log(error)
            this.autoLogout()
            return {}
        }
    }

    async getRoleById(id){
        try{
            const requestUrl="userrole/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})            
        }
        catch(error){
            console.log(error)
            this.autoLogout()
            return {}
        }       
    }

    async getProfileById(id){
        try{
            const requestUrl="user/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})            
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    async updateProfileById(id, userObj){
        try{
            const requestUrl="user/"+id
            let newObj = {}

            if(userObj.hasOwnProperty('displayName')) newObj['display_name'] = userObj.displayName
            if(userObj.hasOwnProperty('email')) newObj['email'] = userObj.email
            if(userObj.hasOwnProperty('dob')) newObj['dob'] = userObj.dob

            return await AuthRequestHelper.request("patch",requestUrl, newObj)
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    //get the membership status, if 0, none paying member, else if 1, paying member
    async getMembershipState(id){
        try{
            const requestUrl="usermemberstat/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})            
        }
        catch(error){
            console.log(error)
            this.autoLogout()
            return {}
        }
    }


    /*Register profiles */
    async registerAuthProfile(username,password,dob){
        try{
            const requestUrl="registerauthprofile"
            const registerParams={
                "username": username,
                "password": password,
                "dob":ChineseCalendarHelper.formatDateTimeForMySQL(dob)
            }           
            return await AuthRequestHelper.requestNoToken("put",requestUrl,  registerParams)
        }
        catch(error){
            // console.log(error)
            console.log('an error happened')
            console.log(error.message)
            console.log(typeof error)
            if(error.message.includes('username already exists'))
            {
                throw Error(error)
            }
            return ""
        }

    }

    async registerUserProfile(uuid,displayName,email, dob){
        console.log("send request")
        try{
            const requestUrl="registeruserprofile"
            const registerParams={
                "uuid":uuid,
                "display_name": displayName,
                "email": email,
                "dob": ChineseCalendarHelper.formatDateTimeForMySQL(dob)
            }
            return await AuthRequestHelper.requestNoToken("put",requestUrl, registerParams)
               
        }
        catch(error){
            console.log(error)
            return ""
        }
    }

    /* PARAMETER FORMAT FOR REGISTERATION IS AS FOLLOWS
    {
        "username": <username>,
        "password": <password>, 
        "displayName": <display_name>,
        "email": <email>
    }
    */

    async registerUser(params){
        // console.log(params)
        try{
            //first try registering auth profile
            let authRegisterResponse = await this.registerAuthProfile(
                params.username,
                params.password
            )
            let uuid=authRegisterResponse.uuid

            //if you faild to create a profile, then do not proceed
            if (!authRegisterResponse){
                throw Error("Failed to create auth profile")
            }
            else{
                console.log("Created auth profile with uuid: "+uuid)
            }

            //then register a user profile with the created uuid
            let userRegisterResponse = await this.registerUserProfile(
                uuid,
                params.displayName,
                params.email,
                params.dob
            )
            console.log("Created user profile with uuid: "+userRegisterResponse.addedData.id)
            return userRegisterResponse.addedData.id
        }
        catch(error){
            // console.log(error)
            if(typeof error === 'object' && error.message.includes('username already exists'))
            {
                return 'USERNAME_EXIST'
            }
            return false
        }
    }


    async sendresetPasswordEmail(username){
        try{
            const requestUrl="sendresetpasswordEmail"
            const resetParams={
                "username": username
            }           
            return await AuthRequestHelper.requestNoToken("put", requestUrl,  resetParams)
        }
        catch(error){
            console.log(error)
            return false
        }
    }

    async resetPassword(username,password){
        try{
            const requestUrl="resetpassword"
            const resetParams={
                "username": username,
                "password" : password
            }           
            return await AuthRequestHelper.request("patch", requestUrl,  resetParams)
        }
        catch(error){
            console.log(error)
            return false
        }
    }


}

export default new UserProfileHelper();
import DiZhi from '../json/DiZhi.json'
import TianGan from '../json/TianGan.json'
import DecadePalace from '../json/DecadePalace.json'
import YearPalace from '../json/YearPalace.json'
import MainStars from '../json/MainStars.json'
import SupStars from '../json/SupStars.json'
import ExtraStars from '../json/ExtraStars.json'
import ZodiacStars from '../json/ZodiacStars.json'
import ChangShengTwelveStars from '../json/ChangShengTwelve.json'
import BoShiTwelveStars from '../json/BoShiTwelve.json'
import JieQi from '../json/JieQi.json'
import moment from 'moment'
import LunarSolarConverter from './LunarSolarConverter'
// const solarLunar = require('solarlunar')
const SunCalc = require('suncalc')
const countryJSON = require('../json/countriesLatLng.json')

const LSC = new LunarSolarConverter()

class ChineseCalendarHelper {
/*
return format
{
    lYear: 2015,
    lMonth: 8,
    lDay: 26,
    animal: '羊',
    monthCn: '八月',
    dayCn: '廿六',
    cYear: 2015,
    cMonth: 10,
    cDay: 8,
    gzYear: '乙未',
    gzMonth: '丙戌',
    gzDay: '丁巳',
    --isToday: false,
    isLeap: false,
    --nWeek: 4,
    --ncWeek: '星期四',
    --isTerm: true,
    --term: '寒露'
}
*/


  test(y = 1987, m = 9, day = 19)
  {
    let solar = LSC.solar
    const cm = ['正','一','二','三','四','五','六','七','八','九','十','冬','腊']
    const cn = ['十','一','二','三','四','五','六','七','八','九']
    const cn2 = ['初','十','廿','卅']
    // console.log(solar)
    solar.solarYear = y;
    solar.solarMonth = m;
    solar.solarDay = day;
    var lunar = LSC.SolarToLunar(solar);
    // console.log(`this year is a leap year ${lunar.isleap && lunar.lunarDay > 15 }`)
    const newLunarMonth = lunar.isleap && lunar.lunarDay > 15 ? lunar.lunarMonth+1:lunar.lunarMonth
    const gzYear = this.yearToChineseGZ(lunar.lunarYear)
    let gzMonth = this.monthToChineseGZ(lunar.lunarYear, newLunarMonth, solar.solarMonth, solar.solarDay)
    
    // console.log(`this year is leap year ${lunar.isleap}`)
    
    // console.log(lunar)
    // console.log(gzYear)
    // console.log(gzMonth)
    let dayCn = ''
    if(parseInt(lunar.lunarDay, 10) > 10)
    {
      const firstLetter = cn2[Math.floor(parseInt(lunar.lunarDay, 10)/10)]
      const secondLetter = cn[parseInt(lunar.lunarDay, 10)%10]
      dayCn = `${firstLetter}${secondLetter}`
    }
    else if(parseInt(lunar.lunarDay, 10) === 10)
    {
      dayCn = cn[0]
    }
    else // < 10
    {
      // console.log(lunar.lunarDay)
      // console.log(parseInt(lunar.lunarDay, 10)/10)
      const firstLetter = cn2[Math.floor(parseInt(lunar.lunarDay, 10)/10)]
      const secondLetter = cn[parseInt(lunar.lunarDay, 10)%10]
      dayCn = `${firstLetter}${secondLetter}`
    }
    // console.log(`this year is leap year ${lunar.isleap}`)

    return {
      lYear: lunar.lunarYear,
      lMonth: lunar.lunarMonth,
      lDay: lunar.lunarDay,
      // monthCn: `${cm[lunar.lunarMonth-1]}月`,
      monthCn: `${cm[lunar.lunarMonth]}月`,
      dayCn: dayCn,
      // animal: '羊',
      cYear: y,
      cMonth: m,
      cDay: day,
      gzYear: gzYear,
      gzMonth: gzMonth,
      // gzDay: '丁巳',
      // --isToday: false,
      isLeap: lunar.isleap,
      // --nWeek: 4,
      // --ncWeek: '星期四',
      // --isTerm: true,
      // --term: '寒露'
    }
  }

  testl2s(y = 1998, m = 5, d = 24, isLeap = true)
  {
    let lunar = LSC.lunar
    lunar.lunarYear = y
    lunar.lunarMonth = m
    lunar.lunarDay = d
    lunar.isleap = isLeap

    let solar = LSC.LunarToSolar(lunar)
    // console.log(solar)
    return solar
  }
  /**
   * Check if a certain year has a leap month. If it does, then return the month, if no then return 0
   * @param {int} y 
   */
  leapMonth(y)
  {
    const LSC2 = new LunarSolarConverter()
    let targetMonth = 0
    for(let i = 1; i < 13; i++)
    {
      let solar = LSC2.solar
      solar.solarYear = y
      solar.solarMonth = i
      solar.solarDay = 1
      console.log("leapmonth")
      let lunar = LSC2.SolarToLunar(solar)
      if(lunar.isleap)
      {
        targetMonth = lunar.lunarMonth
      }
    }
    return targetMonth
  }

  getYearGZ(date = new Date())
  {
    // const calendar = moment(date).format('YYYY-MM-DD HH:mm:ss')
    const y = moment(date).year()
    // const m = moment(date).month()+1
    // const day = moment(date).date()
    // const lunarCalendar = this.getLunarCalendar(y,m,day)
    console.log('getYearGZ')
    const lunarCalendar = this.yearToChineseGZ(y)
    const gz = [lunarCalendar[0], lunarCalendar[1]]
    return gz
  }

    getSunTime(input = '2020-09-19 10:30:00', country = 'US')
    {
        let inputTime = new Date()
        const lat = countryJSON[country]['latitude']
        const lng = countryJSON[country]['longitude']
        const times = SunCalc.getTimes(inputTime, lat, lng)
        return times
    }

    hourToChineseZodiac(hour, lang = 'tw') {
        if ((!hour && hour !== 0) || hour > 24) {
            return '';
        }
    
        return hour >= 23 ? '晚'+DiZhi[10][lang] :
            hour > 22 ? DiZhi[10][lang] :
            hour > 20 ? DiZhi[9][lang] :
            hour > 18 ? DiZhi[8][lang] :
            hour > 16 ? DiZhi[7][lang] :
            hour > 14 ? DiZhi[6][lang] :
            hour > 12 ? DiZhi[5][lang] :
            hour > 10 ? DiZhi[4][lang] :
            hour > 8 ? DiZhi[3][lang] :
            hour > 6 ? DiZhi[2][lang] :
            hour > 4 ? DiZhi[1][lang] :
            hour > 2 ? DiZhi[0][lang] :
            hour > 0 ? DiZhi[11][lang] :
            DiZhi[10][lang];
    }
    
    getZodiacHourStr(date = new Date(), lang = 'tw')
    {
        const d = new Date(date)
        const h = parseInt(d.getHours(), 10)
        return this.hourToChineseZodiac(h, lang)
    }
    
    // custom convert
    getSolarCalendar(y,m,day, isleap = false)
    {
      let lunar = LSC.lunar
      lunar.isleap = isleap
      lunar.lunarYear = y
      lunar.lunarMonth = m
      lunar.lunarDay = day
      let solar = LSC.LunarToSolar(lunar)
      return solar
    }

    // utilize the npm package to convert
    lunarToSolarConvert(y,m,day, isLeap = false)
    {
      let lunar = LSC.lunar
      lunar.lunarYear = y
      lunar.lunarMonth = m
      lunar.lunarDay = day
      lunar.isleap = isLeap
  
      // let solar = LSC.LunarToSolar(lunar)
      // console.log(solar)
      // return solar
      // const lunar2solarData = solarLunar.lunar2solar(y, m, day, isLeap)
      // return lunar2solarData
      const solar = LSC.LunarToSolar(lunar)
      
      const lunar2solarData = {
        cYear: solar.solarYear, 
        cMonth: solar.solarMonth, 
        cDay: solar.solarDay
      }
      return lunar2solarData
    }

    getLunarCalendar(y,m,day)
    {
      console.log(`entered y: ${y} m:${m} d:${day}`)
        // let d = new Date(y,m,d)
        // console.log(d)
        // const y = parseInt(d.getFullYear(), 10)
        // const m = parseInt(d.getMonth() + 1, 10)
        // const day = parseInt(d.getDate(), 10)

        // console.log(`${y}/${m}/${day}`)
        // const convert = solarLunar.solar2lunar(y, m, day)
        // console.log(convert)
        // return convert
        let solar = LSC.solar
        const cm = ['正','一','二','三','四','五','六','七','八','九','十','冬','腊']
        const cn = ['十','一','二','三','四','五','六','七','八','九']
        const cn2 = ['初','十','廿','卅']
        console.log("solar", JSON.stringify(solar))
        solar.solarYear = y;
        solar.solarMonth = m;
        solar.solarDay = day;
        var lunar = LSC.SolarToLunar(solar);
        console.log("lunar converted ", JSON.stringify(lunar))
        // console.log(`lunar month is ${lunar.lunarMonth}`)

        // console.log(`this year is a leap year ${lunar.isleap && lunar.lunarDay > 15 }`)
        const newLunarMonth = lunar.isleap && lunar.lunarDay > 15 ? lunar.lunarMonth+1:lunar.lunarMonth
        console.log("get lunar calender")
        const gzYear = this.yearToChineseGZ(lunar.lunarYear)
        // console.log("checkpoint 1")
        let gzMonth = this.monthToChineseGZ(lunar.lunarYear, newLunarMonth, solar.solarMonth, solar.solarDay)
        // console.log("checkpoint 2")
        // const gzYear = this.yearToChineseGZ(lunar.lunarYear)
        // const gzMonth = this.monthToChineseGZ(lunar.lunarYear, lunar.lunarMonth, solar.solarMonth, solar.solarDay)
        // console.log(lunar)
        // console.log(gzYear)
        // console.log(gzMonth)
        let dayCn = ''
        // console.log("checkpoint 3")

        if(parseInt(lunar.lunarDay, 10) > 10)
        {
          // console.log("checkpoint 4")

          const firstLetter = cn2[Math.floor(parseInt(lunar.lunarDay, 10)/10)]
          const secondLetter = cn[parseInt(lunar.lunarDay, 10)%10]
          dayCn = `${firstLetter}${secondLetter}`
          // console.log("checkpoint 5")
          
        }
        else if(parseInt(lunar.lunarDay, 10) === 10)
        {
          dayCn = `${cn2[0]}${cn[0]}`
        }
        else
        {
          const firstLetter = cn2[0]
          const secondLetter = cn[parseInt(lunar.lunarDay, 10)%10]
          dayCn = `${firstLetter}${secondLetter}`
        }
        // console.log("checkpoint 6")

        // console.log(`calculdate lunar `, lunar)
        return {
          lYear: lunar.lunarYear,
          lMonth: lunar.lunarMonth,
          lDay: lunar.lunarDay,
          monthCn: `${cm[lunar.lunarMonth]}月`,
          dayCn: dayCn,
          // animal: '羊',
          cYear: y,
          cMonth: m,
          cDay: day,
          gzYear: gzYear,
          gzMonth: gzMonth,
          // gzDay: '丁巳',
          // --isToday: false,
          isLeap: lunar.isleap,
          // --nWeek: 4,
          // --ncWeek: '星期四',
          // --isTerm: true,
          // --term: '寒露'
        }


    }

    formatSolarTime(dob, inputSolarTime)
    {
      console.log("formatSolarTime")
      // console.log(dob)
      // console.log(inputSolarTime)
      const dy = moment(dob).year()
      const dm = moment(dob).month() + 1
      const dd = moment(dob).date()
      const getLunarLeap = this.getLunarCalendar(dy, dm, dd)
      const sy = moment(inputSolarTime).year()
      const sm = moment(inputSolarTime).month() + 1
      const sd = moment(inputSolarTime).date()
      const sh = moment(inputSolarTime).hours()
      const smm = moment(inputSolarTime).minutes()
      // console.log(`get year ${sy} month ${sm} day ${sd} and leap ${getLunarLeap.isLeap}`)
      const r = this.getSolarCalendar(sy, sm, sd, getLunarLeap.isLeap)
      // console.log(r)
      const date = new Date(r.solarYear, r.solarMonth - 1, r.solarDay, sh, smm)
      // console.log(date)
      const format = moment(date).format('YYYY-MM-DD HH:mm')
      return format
      // `${r.solarYear}-${r.solarMonth}-${r.solarDay} ${sh}:${smm}`
    }

    formatDateTimeForMySQL(date = new Date())
    {
      return date
      // console.log("Format: ")
      // console.log(date)
      // let b = date.split(/\D/);
      // let d = new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
      // //let d = new Date(date)
      // // console.log(d)
      // const y = parseInt(d.getFullYear(), 10)
      // const m = parseInt(d.getMonth() + 1, 10)
      // const day = parseInt(d.getDate(), 10)
      // const h = parseInt(d.getHours(), 10)
      // const mm = parseInt(d.getMinutes(), 10)
      // const format = `${y}-${m}-${day} ${h}:${mm}:00`
      // console.log('test format')
      // console.log(format)

      // return format
    }

    formatDateDisplay(date = new Date())
    {
        // let d = new Date(date)
        // console.log(d)
        // const format = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
        const format = moment(date).format('YYYY-MM-DD HH:mm:ss')
        // const y = parseInt(d.getFullYear(), 10)
        // const m = parseInt(d.getMonth() + 1, 10)
        // const day = parseInt(d.getDate(), 10)
        // const h = parseInt(d.getHours(), 10)
        // const mm = parseInt(d.getMinutes(), 10)
        // const format = `${y}-${m}-${day} ${h}:${mm}`
        return format
    }

    formatDateForChart(date = new Date())
    {
      const format = moment(date).format('YYYY-MM-DD HH:mm')
      return format
    }

    formatDateWithoutTime(date = new Date())
    {
      const format = moment(date).format('YYYY-MM-DD')
      return format
    }

    plusOneYear(date = new Date())
    {
      // console.log(`input date is ${date}`)
      // Date.prototype.addDays= function(d){
      //   this.setDate(this.getDate() + d);
      //   return this;
      // }
      // console.log(date)
      let d = new Date(date)
      // console.log(d)
      // const newDate = d.addDays(364)
      const newDate = this.plusDays(d, 364)
      // console.log(newDate)
      const format = this.formatDateWithoutTime(newDate)
      return format
    }

    plusDays(date = new Date(), d = 0) {
      // console.log(`input date ${date}`)
      // console.log(date)
      Date.prototype.addDays= function(d){
        this.setDate(this.getDate() + d);
        return this;
      }
      let target = new Date(date)
      // console.log(target)
      const newDate = target.addDays(d)
      // console.log(newDate)
      return newDate
    }

    formatLunarCalendarDisplay(date = new Date())
    {
        // let d = new Date(date)
        let d = moment(date).format('YYYY-MM-DD HH:mm:ss')
        // console.log(d)
        // const y = parseInt(d.getFullYear(), 10)
        let y = moment(d).year()
        // const m = parseInt(d.getMonth() + 1, 10)
        let m = moment(d).month()+1
        // const day = parseInt(d.getDate(), 10)
        let day = moment(d).date()
        // const h = parseInt(d.getHours(), 10)
        let h = moment(d).hour()
        // const mm = parseInt(d.getMinutes(), 10)
        let mm = moment(d).minute()
        const lunarTime = this.hourToChineseZodiac(h)
        // console.log(lunarTime)
        console.log("formatLunarCalendarDisplay")

        const lc = this.getLunarCalendar(y,m,day)
        // console.log(lc)
        return `${lc.gzYear}年${LSC.lunar.isleap ? '閏':''}${lc.monthCn}${lc.dayCn} ${lunarTime}時`
    }
/*
从已知的公历年份计算干支纪年:年份数减3,除以10,所得余数与天干的对应关系为甲1、乙2、丙3、丁4、戊5、已6、庚7,辛8、壬9、癸0；
年份数减3,除以12的余数与地支的对应关系为子1、丑2、寅3、卯4、辰5,巳6,午7、未8、申9、西10戌11、亥0(公元前的年份则用“58减去年份数除以60的余数'后计算)。
如2011年的年干为:(2011-3)=10,其余数为8,即“辛”;其年支为:(2011-3)=12,其余数为4,即“卯”所以2011年为“辛卯年”。
*/
    yearToChineseGZ(y)
    {
      console.log('the input year is ', y)
      const tg = TianGan[y%10]['tw']
      let ny = y - 3
      let dzIdx = ny%12 - 3 >= 0 ? ny%12 - 3 : 12 + ny%12 - 3
      const dz = DiZhi[dzIdx]['tw']
      return `${tg}${dz}`
    }
/*
月干支口诀

甲已之年丙作首，乙庚之岁戊为头，丙辛之岁寻庚起，

丁壬壬寅顺行流，更有戊癸何方觅，甲寅之上好追求。

月干支计算方式

计算月干支之前，我们首先把天干标成数值，即为甲等于1，乙等于2，以此类推，癸等于10，有了这种数字对应关系，在已知年干的情况下就可以计算月干了：

月干等于年干乘以二加上月份数，比如丙子年的三月，因为丙等于3，所以3*2+3=9，再三月固定地支为辰，所以这年三月的月干支是：：壬辰。

这种计算方式有一个要注意的地方，就是月份并非简单直接按月份取值，因为历法关系，这里要考虑到二十四节气，这个月的节之前的日子不算在这个月，而是算在上一个月，比如这年三月初七的清明节，那么三月初七以前的六天通通按二月计算，其他月份也按节的此例推理，初七本身的干支则要考虑过节的具体时辰，总之就是节前按上月计，节后按本月计。

四柱八字的干支推算方法我们可以查看：年干支计算方法，月干支计方法，日干支推算方法，时干支推算方法。

四柱八字的干支推算方法我们可以查看：年干支计算方法，月干支计方法，日干支推算方法，时干支推算方法。
*/
    monthToChineseGZ(y, m, sm, sd)
    {
      // console.log(`the input date ${sm}/${sd}`)
      // console.log(`chinese tiangan ${this.yearToChineseGZ(y)[0]}`)
      console.log("monthToChineseGZ")
      const yTG = this.returnTianGanGivenText(this.yearToChineseGZ(y)[0], 'year')
      // console.log(`year tiangan ${yTG}`)
      const yTGConvert = yTG - 3 > 0 ? yTG - 3 : 10 + yTG - 3
      // console.log(`year convert is ${yTGConvert}`)
      let tgIdx = (yTGConvert * 2 + m + 3) % 10
      // console.log(`tiangan idx ${tgIdx}`)
      // let tgIdx = (((y-3)%10) * 2 + m) % 10 + 3 < 10 ? (((y-3)%10) * 2 + m) % 10 + 3 : 10 - (((y-3)%10) * 2 + m) % 10 + 3
      let dzIdx = m - 1 >= 0 ? m-1 : 12 + m - 1
      let monthDzIdx = sm-1 >= 0 ? sm-1:12+sm-1
      const jqRange = JieQi[monthDzIdx]['date']
      // const jq = JieQi[monthDzIdx]
      // console.log(jq)
      // console.log(`the target dz is ${jq['dz']}`)
      // console.log(jqRange)
      // console.log(`exam month ${sm} exam day ${sd}`)
      const targetMonthDz = JieQi[monthDzIdx]['dz']
      // console.log(`target month dz is ${targetMonthDz}`)
      let newDzIdx = this.returnDiZhiObj(targetMonthDz, 'pos')
      // console.log(`old dz is ${dzIdx} and new dz is ${newDzIdx}`)
      let dzDiff = newDzIdx - dzIdx
      let newTgIdx = tgIdx + dzDiff >= 0 ? tgIdx + dzDiff : tgIdx + dzDiff + 10

      if(sd < jqRange[1])
      {
        // console.log(`the solar day is smaller than the designated date, don't jump`)
        // tgIdx = tgIdx - 1 >= 0 ? tgIdx - 1 : tgIdx + 1 + 10
        // dzIdx = dzIdx - 1 >= 0 ? dzIdx - 1 : dzIdx + 1 + 12
        tgIdx = newTgIdx - 1 >= 0 ? newTgIdx - 1 : newTgIdx - 1 + 10
        dzIdx = newDzIdx - 1 >= 0 ? newDzIdx - 1 : newDzIdx - 1 + 12
      }
      else
      {
        // console.log(`jump`)
        tgIdx = newTgIdx
        dzIdx = newDzIdx
      }
      tgIdx = tgIdx%10
      dzIdx = dzIdx%12
      // tgIdx = tgIdx + tgJump < 10 ? tgIdx + tgJump : tgIdx + tgJump - 10
      // dzIdx = jqIdx + dzJump >= 0 ? jqIdx + dzJump : 12+jqIdx+dzJump
      // console.log(`tgIdx index is ${tgIdx}`)
      const tg = TianGan[tgIdx]['tw']
      // console.log(tg)
      const dz = DiZhi[dzIdx]['tw']
      // const dz = JieQi[dzIdx]['dz']
      return `${tg}${dz}`
    }

    leapMonthToChineseGZ(y, m, d, sm, sd, leapMonth = 0)
    {
      // console.log(`the input date ${sm}/${sd}`)
      // console.log('has leap month')
      // console.log(`chinese tiangan ${this.yearToChineseGZ(y)[0]}`)
      // console.log("leapMonthToChineseGZ")
      const yTG = this.returnTianGanGivenText(this.yearToChineseGZ(y)[0], 'year')
      // console.log(`year tiangan ${yTG}`)
      const yTGConvert = yTG - 3 > 0 ? yTG - 3 : 10 + yTG - 3
      // console.log(`year convert is ${yTGConvert}`)
      let tgIdx = (yTGConvert * 2 + m + 3) % 10
      // console.log(`tiangan idx ${tgIdx}`)
      // let tgIdx = (((y-3)%10) * 2 + m) % 10 + 3 < 10 ? (((y-3)%10) * 2 + m) % 10 + 3 : 10 - (((y-3)%10) * 2 + m) % 10 + 3
      let dzIdx = m - 1 >= 0 ? m-1 : 12 + m - 1
      let monthDzIdx = sm-1 >= 0 ? sm-1:12+sm-1
      const jqRange = JieQi[monthDzIdx]['date']
      // const jq = JieQi[monthDzIdx]
      // console.log(jq)
      // console.log(`the target dz is ${jq['dz']}`)
      // console.log(jqRange)
      // console.log(`exam month ${sm} exam day ${sd}`)
      const targetMonthDz = JieQi[monthDzIdx]['dz']
      // console.log(`target month dz is ${targetMonthDz}`)
      let newDzIdx = this.returnDiZhiObj(targetMonthDz, 'pos')
      // console.log(`old dz is ${dzIdx} and new dz is ${newDzIdx}`)
      let dzDiff = newDzIdx - dzIdx
      let newTgIdx = tgIdx + dzDiff

      if(sd < jqRange[1])
      {
        // console.log(`the solar day is smaller than the designated date, don't jump`)
        // tgIdx = tgIdx - 1 >= 0 ? tgIdx - 1 : tgIdx + 1 + 10
        // dzIdx = dzIdx - 1 >= 0 ? dzIdx - 1 : dzIdx + 1 + 12
        tgIdx = newTgIdx - 1 >= 0 ? newTgIdx - 1 : newTgIdx - 1 + 10
        dzIdx = newDzIdx - 1 >= 0 ? newDzIdx - 1 : newDzIdx - 1 + 12
      }
      else
      {
          tgIdx = newTgIdx
          dzIdx = newDzIdx
        // }
      }
      tgIdx = tgIdx%10
      dzIdx = dzIdx%12
      // tgIdx = tgIdx + tgJump < 10 ? tgIdx + tgJump : tgIdx + tgJump - 10
      // dzIdx = jqIdx + dzJump >= 0 ? jqIdx + dzJump : 12+jqIdx+dzJump
      // console.log(`dz index is ${dzIdx}`)
      const tg = TianGan[tgIdx]['tw']
      const dz = DiZhi[dzIdx]['tw']
      // const dz = JieQi[dzIdx]['dz']
      return `${tg}${dz}`
    }

    determineDecadeMovement(dob = '2020-09-19 10:30:00', gender = 'Male')
    {
      const morph = this.getTianGanFromBirthYear(dob)
      const yinYang = morph % 2 === 0 ? 1:0 // odd is Yang, even is Yin
      const genderNum = gender === 'Male' ? 1:0
      let decadeMove = 0 // even is clockwise, odd is counterclockwise
      if( genderNum !== yinYang )
      {
        // console.log('逆行') counterclockwise
        decadeMove = 1
      }
      return decadeMove
    }

    getTianGanFromBirthYear(date = new Date())
    {
      console.log('getTianGanFromBirthYear')
        // let calendarD = moment(date)
        // let d = this.getLunarCalendar(calendarD.getFullYear(), calendarD.getMonth() + 1, calendarD.getDate())
        let calendarD = moment(date)
        let d = this.getLunarCalendar(calendarD.year(), calendarD.month() + 1, calendarD.date())
        const y = parseInt(d.lYear, 10)
        let lastDigit = y % 10

        return lastDigit
    }

    convertDiZhiToId(input = '寅')
    {
        const dz = DiZhi.find(element => element['tw'] === input)
        // console.log(dz)
        return dz['id']
    }

    returnTianGanText(input = 'jia')
    {
        const tg = TianGan.find(element => element['id'] === input)
        return tg['tw']
    }

    returnTianGanId(input = '甲', enumerate = false)
    {
        const tg = TianGan.find(element => element['tw'] === input)
        if(enumerate) return tg['year']
        return tg['id']
    }

    returnTianGanObjGivenNo(no = 0, type = null)
    {
        const tg = TianGan.find(element => element['year'] === no)
        if(type !== null) return tg[type]
        return tg
    }

    returnTianGanObjGivenId(input = 'jia', type = null)
    {
        const tg = TianGan.find(element => element['id'] === input)
        if(type !== null) return tg[type]
        return tg
    }

    returnTianGanGivenText(input = '甲', type = null)
    {
      const tg = TianGan.find(element => element['tw'] === input)
      if(type !== null) return tg[type]
      return tg
    }

    returnDiZhiText(input = 'zi')
    {
        const dz = DiZhi.find(element => element['id'] === input)
        return dz['tw']
    }

    returnDiZhiObj(input = '子', type = null)
    {
      if(typeof input === 'number')
      {
        const dz = DiZhi.find(element => element['pos'] === input)
        if(type !== null) return dz[type]
        return dz
      }
      const dz = DiZhi.find(element => element['tw'] === input)
      if(type !== null) return dz[type]
      return dz
    }

    returnDiZhiObjGivenId(input = '', type = null)
    {
      if(typeof input === 'number')
      {
        const dz = DiZhi.find(element => element['pos'] === input)
        if(type !== null) return dz[type]
        return dz
      }
      const dz = DiZhi.find(element => element['id'] === input)
      if(type !== null) return dz[type]
      return dz
    }

    getXiaoXianStartPosition(date = new Date())
    {
      // console.log("getXiaoXianStartPosition", date)
        // let d = new Date(date)
        let d = moment(date)
        // console.log("moment parsed date",d)
        const y = parseInt(d.year(), 10)
        const m = parseInt(d.month()+1, 10)
        const day = parseInt(d.date(), 10)
        const h = parseInt(d.hours(), 10)
        /*
        const y = parseInt(d.getFullYear(), 10)
        const m = parseInt(d.getMonth() + 1, 10)
        const day = parseInt(d.getDate(), 10)
        const h = parseInt(d.getHours(), 10)
        */
        const lc = this.getLunarCalendar(y,m,day)
        const dzId = this.convertDiZhiToId(lc['gzYear'][1])
        switch(dzId)
        {
            case 'chou':
            case 'si':
            case 'you':
                return 5
            case 'yin':
            case 'wu':
            case 'xu':
                return 2
            case 'shen':
            case 'zi':
            case 'chen':
                return 8
            case 'hai':
            case 'mao':
            case 'wei':
                return 11

        }
    }

    getDecadeYearsArr = (beginYear = beginYear, lifeHousePos = lifeHousePos, decadeMove = 0) => {
        let newArr = [], beginNo = beginYear, finalArr = []
        for(let i = 0 ; i < 12; i++)
        {
          const newNo = beginNo + 9
          newArr.push(`${beginNo}/${newNo}`)
          beginNo += 10
        }
        // console.log(newArr)
        if(decadeMove === 0)
        {
          for(let j = 0 ; j < newArr.length ; j++)
          {
            if(j + lifeHousePos < newArr.length)
            {
              const newPos = j+lifeHousePos
              finalArr[newPos] = newArr[j]
              // return
            }
            else
            {
              const newPos = j + lifeHousePos - newArr.length
              finalArr[newPos] = newArr[j]
            }
            // return
          }
        }
        else
        {
          for(let j = 0 ; j < newArr.length ; j++)
          {
            if(lifeHousePos - j >= 0)
            {
              const newPos = lifeHousePos - j
              finalArr[newPos] = newArr[j]
              // return
            }
            else
            {
              const newPos = lifeHousePos + newArr.length - j
              finalArr[newPos] = newArr[j]
            }
            // return
          }
        }
        // console.log(finalArr)
        return finalArr
      }

      getXiaoXianYearsArr = (dob, gender = 1) => { // 1 means male
        console.log("dob passed to xiaoxian function", dob)
        let startPos = this.getXiaoXianStartPosition(dob)
        // console.log(`the start position is ${startPos}`)
    
        let finalArr = []
        // console.log(`xiao xian ganzhi ${startPos}`)
        const oldest = 104
        let ageArr = ["", "", "", "", "", "", "", "", "", "", "", ""]
        for(let i = 1 ; i < oldest ; i++)
        {
          const arrPos = (i - 1) %12
          ageArr[arrPos] = ageArr[arrPos] + i + ' '
        }
        if(gender === 1) // clockwise
        {
          for(let j = 0 ; j < ageArr.length ; j++)
          {
            if(j + startPos < ageArr.length)
            {
              const newPos = j+startPos
              finalArr[newPos] = ageArr[j]
              // return
            }
            else
            {
              const newPos = j + startPos - ageArr.length
              finalArr[newPos] = ageArr[j]
            }
            // return
          }
        }
        else // Female, counter clockwise
        {
          for(let j = 0 ; j < ageArr.length ; j++)
          {
            if(startPos - j >= 0)
            {
              const newPos = startPos - j
              finalArr[newPos] = ageArr[j]
              // return
            }
            else
            {
              const newPos = startPos + ageArr.length - j
              finalArr[newPos] = ageArr[j]
            }
            // return
          }
        }
        return finalArr
        // console.log(finalArr)
    }

    getXiaoXianPositionGivenYear(age, xiaoXianArr)
    {
      try
      {
        let targetPos = 0
        xiaoXianArr.forEach((xiaoXian, idx) => {
          let newArr = xiaoXian.split(' ')
          newArr.forEach(newAge => {

            if(parseInt(newAge - 1) === age)
            {
              targetPos = idx
            }
          })
        })
        return targetPos
      }
      catch(e)
      {
        // console.log(e)
        return false
      }
    }

    // retuen a new array of the decade palaces. relative to the natal chart's life house, so the natal chart life house position is needed
    getOffsetDecadePalace(lifeHousePos, offset = 0, decadeMove = 0, natalChartArr)
    {
        let newArr = [...DecadePalace], finalArr = [], beginPos = decadeMove === 0 ? (lifeHousePos + offset < 12 ? lifeHousePos + offset : lifeHousePos + offset - 12) : (lifeHousePos - offset >= 0 ? lifeHousePos - offset : lifeHousePos+12 - offset )
        // console.log(`begin Pos is ${beginPos}`)
        for(let j = 0 ; j < newArr.length ; j++)
        {
          if(j + beginPos < newArr.length)
          {
            const newPos = j+beginPos
            finalArr[newPos] = newArr[j]
            finalArr[newPos]['natalData'] = natalChartArr[newPos]
            // return
          }
          else
          {
            const newPos = j + beginPos - newArr.length
            finalArr[newPos] = newArr[j]
            finalArr[newPos]['natalData'] = natalChartArr[newPos]
          }
          // return
        }
        return finalArr
    }

    // return the new array of the Decade year range (e.g., from 2-11) given the beginning year and the decade selected
    getOffsetDecadeYearRange(beginYear, selectedDecade = 0)
    {
        let targetBegin = beginYear + (selectedDecade * 10), finalArr = []
        for(let i = 0 ; i < 10 ; i++)
        {
            finalArr.push(targetBegin + i)
        }
        return finalArr
    }

    // return the new array of the TaiSui palaces given the begin position.
    getOffsetTaiSuiPalace(beginPos)
    {
        let finalArr = new Array(YearPalace.length)
        // console.log(finalArr)
        // console.log(`year palace length ${YearPalace.length}`)
        let posCursor = beginPos
        for(let i = 0 ; i < YearPalace.length ; i++)
        {
            // console.log(`i is ${i}`)
            const y = YearPalace[i]
            const newPos = posCursor + i < YearPalace.length ? posCursor + i : posCursor + i - YearPalace.length
            // console.log(`the new position is ${newPos}`)
            finalArr[newPos] = y
        }
        return finalArr
    }

    // return the number of the five element
    parseElements(input = 'water')
    {
        switch(input)
        {
            case 'water':return 2
            case 'wood':return 3
            case 'gold':return 4
            case 'soil':return 5
            case 'fire':return 6
        }
    }

    layTwelvePalaces(inputArr = [], beginPos = 0, clockwise = true) // begin position is Yin house
    {
      let newArr = [], endPos = inputArr.length
      for(let i = 0; i < inputArr.length; i++)
      {
        if(clockwise)
        {
          let newPos = beginPos + i < inputArr.length ? beginPos + i : endPos - (beginPos+i)
          newArr[newPos] = inputArr[i]
        }
        else
        {
          let newPos = beginPos - i >= 0 ? beginPos - i : (endPos+beginPos) - i
          newArr[newPos] = inputArr[i]
        }
      }
      return newArr
    }

    // for Hong Luan and Tian Xi, beginning pos is 1 (Mao)
    calcTaiSuiStars(beginPos = 1, zodiacSign = 'mao', clockwise = false)
    {
      
    }

    returnStarObj(starKey = '', lang = null)
    {
       if(MainStars.hasOwnProperty(starKey))
       {
         if(lang === null) return MainStars[starKey]
         return MainStars[starKey][lang]
       }
       if(SupStars.hasOwnProperty(starKey))
       {
        if(lang === null) return SupStars[starKey]
         return SupStars[starKey][lang]
       }
       if(ExtraStars.hasOwnProperty(starKey))
       {
        if(lang === null) return ExtraStars[starKey]
        return ExtraStars[starKey][lang]
       }
       if(ZodiacStars.hasOwnProperty(starKey))
       {
        if(lang === null) return ZodiacStars[starKey]
        return ZodiacStars[starKey][lang]
       }
       if(ChangShengTwelveStars.hasOwnProperty(starKey))
       {
        if(lang === null) return ChangShengTwelveStars[starKey]
        return ChangShengTwelveStars[starKey][lang]
       }
       if(BoShiTwelveStars.hasOwnProperty(starKey))
       {
        if(lang === null) return BoShiTwelveStars[starKey]
        return BoShiTwelveStars[starKey][lang]
       }
    }

    triangleStrokeArray(pos = 0)
    {
      switch(pos)
      {
        case 0:
          return [[0, 1], [0.25, 0], [1, 0.75], [0, 1], [1, 0]]
        case 1:
          return [[0, 0.75], [0.75, 0], [1, 1], [0, 0.75], [1, 0.25]]
        case 2:
          return [[0, 0.25], [1, 0], [0.75, 1], [0, 0.25], [1, 0.75]]
        case 3:
          return [[0, 0], [1, 0.25], [0.25, 1], [0, 0], [1, 1]]
        case 4:
          return [[0.25, 0], [1, 0.75], [0, 1], [0.25, 0], [0.75, 1]]
        case 5:
          return [[0.75, 0], [1, 1], [0, 0.75], [0.75, 0], [0.25, 1]]
        case 6:
          return [[1, 0], [0.75, 1], [0, 0.25], [1, 0], [0, 1]]
        case 7:
          return [[1, 0.25], [0.25, 1], [0, 0], [1, 0.25], [0, 0.75]]
        case 8:
          return [[1, 0.75], [0, 1], [0.25, 0], [1, 0.75], [0, 0.25]]
        case 9:
          return [[1, 1], [0, 0.75], [0.75, 0], [1, 1], [0, 0]]
        case 10:
          return [[0.75, 1], [0, 0.25], [1, 0], [0.75, 1], [0.25, 0]]
        case 11:
          return [[0.25, 1], [0, 0], [1, 0.25], [0.25, 1], [0.75, 0]]
      }
    }

    getDecadePos(lifeHousePos, offset, decadeMove)
    {
      const beginPos = decadeMove === 0 ? (lifeHousePos + offset < 12 ? lifeHousePos + offset : lifeHousePos + offset - 12) : (lifeHousePos - offset >= 0 ? lifeHousePos - offset : lifeHousePos+12 - offset )
      return beginPos
    }

    // get the first year of lunar calendar to calculate the correct fortune age
    getBeginYear(inputSolarTime = new Date())
    {
      console.log('getBeginYear')
      // console.log("get begin year called")
      const y = moment(inputSolarTime).year()
      // const m = moment(inputSolarTime).month()
      let m = moment(inputSolarTime).month()+1
      const day = moment(inputSolarTime).date()
      let newLunarDate = this.getLunarCalendar(y, m, day)
      // console.log(newLunarDate)
      // console.log(`the year ${newLunarDate.lYear}`)
      // let newDate = moment(props.profile.date_of_birth).year() || moment(new Date()).year()
      return newLunarDate.lYear
    }
    // return true if membership has expired
    checkMembershipExpiry(endDate = moment(new Date()))
    {
      const today = moment.utc()
      let expired = today.isAfter(endDate) // today is later than the end date, member has expired

      return expired
    }

}

export default new ChineseCalendarHelper()



import React from 'react'
import { IonLabel, IonSelect, IonSelectOption } from '@ionic/react'

const DropDown = props => {
    return (
        <React.Fragment>
            <IonSelect onIonChange={e => props.handleSelect(e)} value={props.value || ''} placeholder={props.placeholder || ''}  >
                <Options options={props.options} />
            </IonSelect>
        </React.Fragment>)
}

const Options = props => {
    if(props.options.length <= 0) return null
    let optionsArr = []
    props.options.forEach((option, idx) => {
        optionsArr.push(
            <IonSelectOption key={`drop-down-option-${idx}`} value={option.value}>{option.text}</IonSelectOption>)
    })
    return optionsArr
}

export default DropDown
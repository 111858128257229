import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'
import Header from '../../assests/Header'
import { 
    IonContent,
    IonPage,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonRow,
    IonCol,
    IonSpinner
} from '@ionic/react'
import lang from '../../lang/init'
import LoginFirst from './LoginFirst'

import ChartProfileHelper from '../../helpers/ChartProfileHelper'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'


const BackupData = props => {
    const [uploadFeedback, updateUploadFeedback] = useState('')
    const [downloadFeedback, updateDownloadFeedback] = useState('')
    const [loading, updateLoading] = useState(false)
    
    const dict = lang(props.preferences.lang)['backup']

    const uploadData = async () => 
    {
        updateLoading(true)
        try
        {
            const sync = await ChartProfileHelper.pushChartListData(AuthenticationHelper.uuid)
            updateUploadFeedback(dict['uploadSuccess'])
            // console.log(sync)
            updateLoading(false)
        }
        catch(e)
        {
            updateUploadFeedback(dict['uploadError'])
            updateLoading(false)
        }
        setTimeout(clearFeedback, 3000)
    }
    const clearFeedback = () => {
        updateUploadFeedback('')
        updateDownloadFeedback('')
    }
    useEffect(() => {
        clearFeedback()
    }, [])

    const downloadData = async () => {
        updateLoading(true)
        try
        {
            const download = await ChartProfileHelper.pullChartListData(AuthenticationHelper.uuid)
            updateDownloadFeedback(dict['downloadSuccess'])
            updateLoading(false)
            // console.log(download)
        }
        catch(e)
        {
            console.log(e)
            updateDownloadFeedback(dict['downloadError'])
            updateLoading(false)
        }
        setTimeout(clearFeedback, 3000)
    }

    return(
        <IonPage>
            <Header title={dict['title']} 
                withSearchBar={false} 
                leftComponent={<IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>} 
            />
            <IonContent>   
                <div className="container">
                    {!props.isLoggedIn ?
                        <LoginFirst />
                        :
                        <React.Fragment>
                            <IonRow>
                            <IonCol>
                            <IonButton color="zdark" expand="block" onClick={() => uploadData()}>{loading ? <IonSpinner name='lines' /> :dict['upload']}</IonButton>
                            <p>{uploadFeedback}</p>
                            </IonCol>
                            </IonRow>
        
                            <IonRow>
                            <IonCol>
                            <IonButton color="zdark" expand="block" onClick={() => downloadData()}>{loading ? <IonSpinner name='lines' /> :dict['download']}</IonButton>
                            <p>{downloadFeedback}</p>
                            </IonCol>
                            </IonRow>
                        </React.Fragment>
                    }

                </div>
            </IonContent>
        </IonPage>
    )
}

export default withRouter(withPreloader(BackupData))
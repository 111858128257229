import React from 'react';
import './AuthContainer.css';
import { IonRow, IonCol } from '@ionic/react'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'
import lang from '../../lang/init'

const AuthContainer = props => {
    const dict = lang(props.preferences.lang)['auth']
    
    switch(props.type)
    {
        case 'forgotPass':
            return (
                <IonRow className='auth-title-container'>
                    <IonCol>
                    <span className="placeholder ion-font-title">{dict.forgotPass}</span>
                    {props.children}
                    </IonCol>
                </IonRow>)
        case 'register':
            return (
            <IonRow className='auth-title-container'>
                <IonCol>
                <span className="placeholder ion-font-title">{dict.register}</span>
                {props.children}
                </IonCol>
            </IonRow>)
        case 'login':
        default: return (
            <IonRow className='auth-title-container'>
                <IonCol>
                <span className="placeholder ion-font-title">{dict.login}</span>
                {props.children}
                </IonCol>
            </IonRow>)
    }
    /*
    return(
        props.type==="login"?
        <div className="authContainer">
            <span className="placeholder ion-font-title">登入</span>
            {props.children}
        </div>
        :
        <div className="authContainer">
            <span className="placeholder ion-font-title">免費註冊會員</span>
            {props.children}
        </div>
    )
    */
}

export default withRouter(withPreloader(AuthContainer))